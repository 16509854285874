import BookingProgress from '../components/booking-progress'
import TwoColumnLayout from '../layouts/columns'
import { useTranslation } from 'react-i18next'
import { BookingContext } from '../contexts/booking'
import { useContext, useEffect, useMemo } from 'react'
import { usePaymentResult } from '../hooks/payments'
import { Navigate } from 'react-router-dom'
import { RecentBookingContext } from '../contexts/recent-booking'
import { Brand } from '../contexts/brand'
import { useBrand } from '../hooks/brand'

function getBaseUrl (brand: Brand, locale: string): string {
  switch (brand) {
    case 'inselhuepfen':
      switch (locale) {
        case 'de':
          return 'https://www.inselhuepfen.com/success'
        default:
          return 'https://www.inselhuepfen.com/en/success'
      }
    case 'boatbiketours':
      switch (locale) {
        case 'de':
          return 'https://www.boatbiketours.de/danke/'
        case 'nl':
          return 'https://www.boatbiketours.nl/bedankt-voor-uw-boeking/'
        default:
          return 'https://www.boatbiketours.com/thank-you/'
      }
  }
}

export default function BookingConfirmed (): JSX.Element {
  const { t, i18n } = useTranslation()
  const { booking } = useContext(BookingContext)
  const { status } = usePaymentResult()
  const { removeRecentBooking } = useContext(RecentBookingContext)
  const brand = useBrand()

  const nextUrl = useMemo(() => {
    const locale = booking.locale ?? i18n.resolvedLanguage ?? 'en'
    const url = getBaseUrl(brand, locale)
    const params = new URLSearchParams({
      bookingNumber: booking.number,
      fullName: booking.name,
      emailAddress: booking.email,
      tourName: booking.tour_name,
      amount: booking.amount.toString(),
      departureDate: booking.departure_date.toISOString().substring(0, 10),
      value: booking.amount.toString(),
      tourId: brand === 'inselhuepfen' ? booking.tour_external_id : booking.tour_id,
      onRequest: booking.on_request.toString(),
      orderId: booking.number
    })
    return `${url}?${params.toString()}`
  }, [booking.amount, booking.departure_date, booking.email, booking.locale, booking.name, booking.number, booking.on_request, booking.tour_external_id, booking.tour_id, booking.tour_name, brand, i18n.resolvedLanguage])

  const shouldContinue = useMemo(() => status === 'succeeded' || !booking.can_pay, [booking.can_pay, status])

  useEffect(() => {
    if (shouldContinue) {
      // Clear storage state
      removeRecentBooking()
      window.location.href = nextUrl
    }
  }, [booking.can_pay, nextUrl, removeRecentBooking, shouldContinue, status])

  if (shouldContinue) {
    return (
      <>
        <BookingProgress currentStep={6} />
        <TwoColumnLayout>
          <div>
            <h2
              className='text-brand-primary font-bold my-8 text-lg'
            >{t('booking_confirmed_title', 'Thank you for booking with us!')}
            </h2>
            <p
              className='my-3'
            >{t('booking_confirmed_text', 'Your booking is confirmed.')}
            </p>
          </div>
        </TwoColumnLayout>
      </>
    )
  } else {
    return (<Navigate to={`/bookings/${booking.id}/payment`} />)
  }
}

import { useFormikContext } from 'formik'
import { SerializedPassengerInformation } from '../types/passenger-information'
import { useContext, useEffect, useMemo, useRef } from 'react'
import { ProductsContext } from '../contexts/products'
import { Product } from '../services/departures'
import { gtmAddProduct } from '../utils/gtm'
import { TourContext } from '../contexts/tour'
import { Tour } from '../services/tours'

function counter<T> (ids: T[]): Map<T, number> {
  const map = new Map<T, number>()
  for (const id of ids) {
    map.set(id, (map.get(id) ?? 0) + 1)
  }
  return map
}

function gtmProductChange (oldIds: string[], newIds: string[], category: string, tour: Tour, productMap: Map<string, Product>): void {
  const oldCount = counter(oldIds)
  const newCount = counter(newIds)
  const allIds = new Set([...oldIds, ...newIds])
  for (const id of allIds) {
    const previous = oldCount.get(id) ?? 0
    const next = newCount.get(id) ?? 0
    if (previous !== next) {
      const product = productMap.get(id)
      if (product != null) {
        gtmAddProduct(product, tour, category, next - previous)
      }
    }
  }
}

export default function PassengerProductTagManager (): undefined {
  const { values } = useFormikContext<SerializedPassengerInformation>()
  const { productMap } = useContext(ProductsContext)
  const { tour: { tours: [tour] } } = useContext(TourContext)

  const bikes = useMemo(() => {
    return values.passengers.map(passenger => passenger.bike).filter(bike => bike != null && bike !== '').sort()
  }, [values])

  const previousBikes = useRef(bikes)

  useEffect(() => {
    const previous = previousBikes.current
    if (previous.join(',') !== bikes.join(',')) {
      gtmProductChange(previous, bikes, 'Bike', tour, productMap)
      previousBikes.current = bikes
    }
  }, [bikes, productMap, tour])

  const diets = useMemo(() => {
    return values.passengers.map(passenger => passenger.diet).filter(diet => diet != null && diet !== '').sort()
  }, [values])

  const previousDiets = useRef(diets)

  useEffect(() => {
    const previous = previousDiets.current
    if (previous.join(',') !== diets.join(',')) {
      gtmProductChange(previous, diets, 'Diet', tour, productMap)
      previousDiets.current = diets
    }
  }, [diets, productMap, tour])

  const others = useMemo(() => {
    return values.passengers.flatMap(passenger => passenger.other).filter(other => other != null && other !== '').sort()
  }, [values])

  const previousOthers = useRef(others)

  useEffect(() => {
    const previous = previousOthers.current
    if (previous.join(',') !== others.join(',')) {
      gtmProductChange(previous, others, 'Other', tour, productMap)
      previousOthers.current = others
    }
  }, [others, productMap, tour])

  return undefined
}

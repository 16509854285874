import { createBrowserRouter, Navigate } from 'react-router-dom'
import Page from './layouts/page'
import Home from './pages/home'
import ErrorPage from './layouts/error-page'
import SelectDeparture from './pages/select-departure'
import TourPage from './pages/tour-page'
import SelectShip from './pages/select-ship'
import DeparturePage from './pages/departure-page'
import SelectCabins from './pages/select-cabins'
import PassengerInformation from './pages/passenger-information'
import BookingOverviewPage from './pages/booking-overview'
import BookingPage from './pages/booking-page'
import BookingPayment from './pages/booking-payment'
import BookingConfirmed from './pages/booking-confirmed'
import FramePage from './pages/frame-page'
import PaymentPage from './pages/payment-page'
import PaymentCompleted from './pages/payment-completed'
import InitiatePayment from './pages/initiate-payment'
import { Brand } from './contexts/brand'

const tourRoutes = {
  path: 'tours/:tour',
  element: <TourPage />,
  children: [
    {
      path: '',
      element: <SelectDeparture />
    },
    {
      path: 'date/:date',
      element: <SelectShip />
    }
  ]
}
const departureRoutes = {
  path: 'departures/:departure',
  element: <DeparturePage />,
  children: [
    {
      path: '',
      element: <Navigate to='cabins' />
    },
    {
      path: 'cabins',
      element: <SelectCabins />
    },
    {
      path: 'passengers',
      element: <PassengerInformation />
    },
    {
      path: 'confirmation',
      element: <BookingOverviewPage />
    }
  ]
}
const bookingRoutes = {
  path: 'bookings/:booking',
  element: <BookingPage />,
  children: [
    {
      path: '',
      element: <Navigate to='payment' />
    },
    {
      path: 'payment',
      element: <BookingPayment />
    },
    {
      path: 'confirmed',
      element: <BookingConfirmed />
    }
  ]
}
const paymentRoutes = {
  path: 'payments/:payment',
  element: <PaymentPage />,
  children: [
    {
      path: '',
      element: <InitiatePayment />
    },
    {
      path: 'completed',
      element: <PaymentCompleted />
    }
  ]
}
const homeRoute = {
  path: '',
  element: <Home />
}

const defaultBrand: Brand = location.hostname.split('.').includes('inselhuepfen') ? 'inselhuepfen' : 'boatbiketours'

const mainRoutes = {
  element: <Page brand={defaultBrand} />,
  errorElement: <ErrorPage />,
  children: [
    tourRoutes,
    departureRoutes,
    bookingRoutes,
    paymentRoutes,
    homeRoute
  ]
}

const ihRoutes = {
  path: 'inselhuepfen',
  element: <Page brand='inselhuepfen' />,
  errorElement: <ErrorPage />,
  children: [
    tourRoutes,
    departureRoutes,
    bookingRoutes,
    paymentRoutes,
    homeRoute
  ]
}

const frameRoutes = {
  element: <Page />,
  errorElement: <ErrorPage />,
  children: [
    {
      path: 'tour/:tour',
      element: <TourPage />,
      children: [
        { path: '', element: <SelectDeparture /> }
      ]
    },
    tourRoutes,
    departureRoutes
  ]
}

const router = createBrowserRouter([
  {
    path: 'frame',
    element: <FramePage />,
    children: [
      frameRoutes
    ]
  },
  ihRoutes,
  mainRoutes
])

export default router

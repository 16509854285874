import { createContext, PropsWithChildren, useMemo } from 'react'
import { Cabin } from '../services/departures'

export interface CabinsContextType {
  cabins: Cabin[]
  cabinMap: Record<Cabin['id'], Cabin>
  cabinTypeIds: Array<Cabin['id']>
}

const initialContext: CabinsContextType = {
  cabins: [],
  cabinMap: {},
  cabinTypeIds: []
}

export const CabinsContext = createContext(initialContext)

export default function CabinsProvider ({ cabins, children }: PropsWithChildren<Pick<CabinsContextType, 'cabins'>>): JSX.Element {
  const cabinMap: Record<string, Cabin> = useMemo(() => cabins.reduce<Record<string, Cabin>>((obj, cabin) => {
    obj[cabin.id] = cabin
    return obj
  }, {}), [cabins])

  const cabinTypeIds = useMemo(() => cabins.map(cabin => cabin.id), [cabins])

  return <CabinsContext.Provider value={{ cabins, cabinMap, cabinTypeIds }}>{children}</CabinsContext.Provider>
}

import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

export function useStorageState<T> (key: string): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] {
  const [value, setValue] = useState((): undefined | T => {
    const saved = localStorage.getItem(key)
    return saved === null ? undefined : JSON.parse(saved)
  })

  useEffect(() => {
    if (value !== undefined) {
      localStorage.setItem(key, JSON.stringify(value))
    }
  }, [key, value])

  const wrappedSet = useCallback((newValue: (T | undefined) | ((prevState: (T | undefined)) => T | undefined)) => {
    if (newValue !== undefined && typeof newValue !== 'function') {
      localStorage.setItem(key, JSON.stringify(newValue))
    }

    setValue(newValue)
  }, [key])

  const remove = useCallback(() => {
    localStorage.removeItem(key)
    setValue(undefined)
  }, [key])

  return [value, wrappedSet, remove]
}

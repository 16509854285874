import { Cabin, Product } from '../services/departures'
import { Tour } from '../services/tours'
import { priceMultiplier } from './products'

declare global {
  interface Window {
    dataLayer?: unknown[]
  }
}

type GTMItemIdOrItemName = {
  item_name: string
  item_id?: string | undefined
} | {
  item_id: string
  item_name?: string | undefined
}

export type GTMCartItem = GTMItemIdOrItemName & {
  item_category?: string
  quantity: number

  price?: number
  currency?: string
}

export function loadGtm (gtmId: string): void {
  if (gtmId === '') {
    return
  }
  const w = window
  w.dataLayer = w.dataLayer ?? []
  w.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  })

  if (document.getElementById('gtm.js') == null) {
    const gtmJs = document.createElement('script')
    gtmJs.id = 'gtm.js'
    gtmJs.async = true
    gtmJs.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`
    document.head.appendChild(gtmJs)
  }
}

export function gtmEvent (event: {}): void {
  const w = window
  w.dataLayer = w.dataLayer ?? []
  if (typeof event === 'object' && 'ecommerce' in event) {
    w.dataLayer.push({ ecommerce: null })
  }
  if (import.meta.env.DEV) {
    console.dir(event)
  }
  w.dataLayer.push(event)
}

export function gtmAddProduct (product: Product, tour: Tour, category: string, amount: number = 1): void {
  const price = priceMultiplier(product.price_calculation, tour.duration) * (product.unit_price ?? 0)
  gtmEvent({
    event: amount < 0 ? 'remove_from_cart' : 'add_to_cart',
    ecommerce: {
      items: [{
        item_name: product.name,
        item_id: product.id,
        item_category: category,
        quantity: Math.abs(amount),
        price,
        currency: 'EUR'
      }]
    }
  })
}

export function gtmAddCabin (cabin: Cabin, amount: number = 1, passengers: number = 2): void {
  const price = (passengers < 2 ? cabin.surcharge_price : (passengers > 2 ? cabin.discount_price : cabin.unit_price)) ?? cabin.unit_price
  gtmEvent({
    event: amount < 0 ? 'remove_from_cart' : 'add_to_cart',
    ecommerce: {
      items: [{
        item_name: cabin.name,
        item_id: `${cabin.id}:${passengers}`,
        item_category: 'Cabin',
        quantity: Math.abs(amount),
        price: price * passengers,
        currency: 'EUR'
      }]
    }
  })
}

export function gtmRemoveCabin (cabin: Cabin, amount: number = 1, passengers: number = 2): void {
  gtmAddCabin(cabin, -amount, passengers)
}

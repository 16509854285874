declare global {
  interface Window {
    Trengo?: {
      extraOffsetX?: string
      extraOffsetY?: string
      key?: string
      on_ready?: () => void
      Api?: {
        Widget: {
          open: (channel: string) => void
        }
      }
    }
  }
}

let trengoLoaded = false
const trengoKeys: Record<string, string> = {
  de: '2yQHNV4MSz0pTVmfjDFb',
  en: 'V6buD5fFpNHRy84',
  nl: 'P6XFaiLvjV8HSsEH5FGc'
}

export function loadTrengo (locale: string, extraOffsetY: string = '0'): void {
  if (trengoLoaded) {
    return
  }
  trengoLoaded = true

  window.Trengo = window.Trengo ?? {}
  window.Trengo.extraOffsetX = '0'
  window.Trengo.extraOffsetY = extraOffsetY
  window.Trengo.key = trengoKeys[locale] ?? trengoKeys.en
  window.Trengo.on_ready = () => {
    console.log('Trengo ready')
  }

  if (document.getElementById('trengo.js') == null) {
    const trengoJs = document.createElement('script')
    trengoJs.id = 'trengo.js'
    trengoJs.async = true
    trengoJs.src = 'https://static.widget.trengo.eu/embed.js'
    document.head.appendChild(trengoJs)
  }
}

import { useContext, useId, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, FormikErrors, FormikTouched, FormikValues, useFormikContext } from 'formik'
import { ChevronDown } from 'react-feather'
import {
  CountrySelect, DateInput,
  SelectInput, StateSelect,
  TextAreaInput,
  TextLikeInput
} from './inputs'
import { SelectableCabin } from '../hooks/cabin-selection'
import { ProductsContext } from '../contexts/products'
import { useCurrencyFormatter } from '../hooks/formatter'
import { DepartureContext } from '../contexts/departure'
import InformationIcon from './information-icon'
import dayjs from '../libs/dayjs'
import { priceMultiplier } from '../utils/products'
import { SerializedPassengerInformation } from '../types/passenger-information'
import classNames from 'classnames'

interface PassengerFormProps {
  selectableCabins: SelectableCabin[]
  index: number
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
  main?: boolean
}

export function PassengerForm ({
  selectableCabins,
  index,
  main = false
}: PassengerFormProps): JSX.Element {
  const { t } = useTranslation()
  const currencyFormatter = useCurrencyFormatter()
  const { departure } = useContext(DepartureContext)
  const idPrefix = useId()
  const { products: { bikes, diets, other: otherProducts } } = useContext(ProductsContext)
  const passengerProducts = useMemo(() => (otherProducts ?? []).filter(product => product.passenger_product), [otherProducts])

  const genderOptions = useMemo(() => [
    ['', t('gender_none', '')],
    ['Male', t('gender_male', 'Male')],
    ['Female', t('gender_female', 'Female')]
  ], [t])

  const cabinOptions = useMemo(() => {
    const options = selectableCabins.map(cabin => [cabin.key, cabin.name])
    options.unshift(['', ''])
    return options
  }, [selectableCabins])

  const bicycleOptions = useMemo(() => {
    const options = bikes.map(bike => [
      bike.id,
      `${bike.name} (${currencyFormatter.format(bike.unit_price * priceMultiplier(bike.price_calculation, departure.duration))})`
    ]).sort((a, b) => a[1].localeCompare(b[1]))
    options.unshift(['', ''])
    return options
  }, [bikes, currencyFormatter, departure.duration])

  const dietOptions = useMemo(() => {
    const options = diets.map(diet => [
      diet.id,
      `${diet.base_name ?? diet.name} (${currencyFormatter.format(diet.unit_price * priceMultiplier(diet.price_calculation, departure.duration))})`
    ]).sort((a, b) => a[1].localeCompare(b[1]))
    options.unshift(['', t('no_diet', 'No diet')])
    return options
  }, [currencyFormatter, departure.duration, diets, t])

  const maxBirthDate = useMemo(() => dayjs().format('YYYY-MM-DD'), [])
  const { values } = useFormikContext<SerializedPassengerInformation>()

  const showStateField = useMemo(() => ['US', 'CA'].includes(values.main.country), [values.main.country])

  return (
    <div className='flex flex-col mb-6'>
      <>
        <div
          className='bg-brand-light text-brand-primary flex gap-4 px-6 py-4 text-left text-base font-bold'
        >
          <div className='flex-1'>
            {main ? t('personal_information_main', 'Personal information - Main booker') : t('personal_information_additional', 'Personal information - Additional passenger {{ index }}', { index })}
          </div>
          <div>
            <ChevronDown />
          </div>
        </div>
        <div>
          <section className='bg-brand-well grid grid-cols-1 md:grid-cols-2 p-6 gap-6'>
            <Field
              id={`${idPrefix}-firstName`}
              label={t('field:first_name', 'First Name')}
              name={`passengers[${index}].firstName`}
              component={TextLikeInput} data-lpignore='true'
            />
            <Field
              id={`${idPrefix}-lastName`}
              label={t('field:last_name', 'Last Name')}
              name={`passengers[${index}].lastName`}
              component={TextLikeInput} data-lpignore='true'
            />
            {main && (
              <>
                <Field
                  id={`${idPrefix}-streetAddress`}
                  label={t('field:street_address', 'Street Address')}
                  name='main.streetAddress' component={TextLikeInput}
                />
                <div className='grid grid-cols-2 gap-4 md:col-start-2 md:row-start-2'>
                  <Field
                    id={`${idPrefix}-zipCode`}
                    label={t('field:zip_code', 'Zip code')}
                    name='main.zipCode' component={TextLikeInput}
                  />
                  <Field
                    id={`${idPrefix}-city`} label={t('field:city', 'City')}
                    name='main.city' component={TextLikeInput}
                  />
                </div>
                <Field
                  id={`${idPrefix}-country`}
                  label={t('field:country', 'Country')} name='main.country'
                  component={CountrySelect}
                />
                {showStateField && (
                  <Field
                    id={`${idPrefix}-state`}
                    label={t('field:state', 'State/Province')} name='main.state'
                    country={values.main.country}
                    component={StateSelect}
                  />
                )}
                <Field
                  id={`${idPrefix}-emailAddress`}
                  label={t('field:email_address', 'Email Address')}
                  name='main.emailAddress' component={TextLikeInput}
                  type='email'
                  className='md:col-start-2'
                />
              </>
            )}
            <Field
              id={`${idPrefix}-dateOfBirth`}
              label={t('field:date_of_birth', 'Date of Birth')}
              name={`passengers[${index}].dateOfBirth`} type='date'
              component={DateInput}
              className='md:row-start-4'
              max={maxBirthDate}
            />
            <div className='grid grid-cols-2 gap-4'>
              <Field
                id={`${idPrefix}-gender`}
                label={t('field:gender', 'Gender')}
                name={`passengers[${index}].gender`}
                component={SelectInput} options={genderOptions}
              />
              <Field
                id={`${idPrefix}-bodyHeight`}
                label={<span className='h-4 inline-flex items-center'>{t('field:body_height', 'Body Height (cm)')}<InformationIcon description={t('field:body_height_tooltip', '')} /></span>}
                name={`passengers[${index}].bodyHeight`}
                inputMode='numeric'
                component={TextLikeInput} type='number'
                min={100} max={299}
              />
            </div>
            {main && (
              <Field
                id={`${idPrefix}-phoneNumber`}
                label={t('field:phone_number', 'Phone number')}
                name='main.phoneNumber' component={TextLikeInput}
                type='tel' data-lpignore='true'
              />
            )}
            <Field
              id={`${idPrefix}-cabin`}
              label={t('field:cabin', 'Cabin')}
              name={`passengers[${index}].cabin`} component={SelectInput}
              options={cabinOptions} data-lpignore='true'
            />
            {main && (
              <>
                <Field
                  id={`${idPrefix}-customerRemarks`}
                  className={classNames('md:col-start-2 md:row-start-5', { 'md:row-span-2': !showStateField, 'md:row-span-3': showStateField })}
                  label={t('field:customer_remarks', 'Customer Remarks')}
                  name='main.customerRemarks' component={TextAreaInput}
                />
                <Field
                  id={`${idPrefix}-groupName`} className='md:col-span-2'
                  label={t('field:group_name', 'Group name')}
                  name='main.groupName' component={TextLikeInput}
                />
              </>
            )}
            <Field
              id={`${idPrefix}-bicycle`}
              label={t('field:bicycle', 'Bicycle')}
              name={`passengers[${index}].bike`}
              component={SelectInput}
              options={bicycleOptions}
            />
            <Field
              id={`${idPrefix}-diet`}
              label={t('field:diet', 'Special diet')}
              name={`passengers[${index}].diet`}
              component={SelectInput}
              options={dietOptions}
            />
          </section>
          {passengerProducts.length > 0 && (

            <table className='bg-brand-well-light w-full'>
              <thead>
                <tr className='bg-brand-well-light text-brand-primary text-base/4'>
                  <th className='p-6 text-left'>{t('product_name', 'Product name')}</th>
                  <th className='p-6 w-8'>{t('price', 'Price')}</th>
                </tr>
              </thead>
              <tbody>
                {passengerProducts.map(product => (
                  <tr className='bg-brand-well-light border-t border-t-secondary-50 text-base/4' key={product.id}>
                    <td className='p-6'>
                      <label className='flex gap-4' htmlFor={`${idPrefix}-product-${product.id}`}>
                        <Field
                          id={`${idPrefix}-product-${product.id}`}
                          name={`passengers[${index}].other`}
                          disabled={product.mandatory}
                          value={product.id}
                          type='checkbox'
                          className='text-brand-primary focus:ring-brand-primary disabled:cursor-not-allowed'
                        />
                        <span className='inline-flex items-center'>
                          {product.name}
                          {product.description !== undefined && <InformationIcon description={product.description} />}
                        </span>
                      </label>
                    </td>
                    <td className='p-6 w-8'>{currencyFormatter.format(product.unit_price * priceMultiplier(product.price_calculation, departure.duration))}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </>
    </div>
  )
}

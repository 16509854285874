import { PropsWithChildren, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export default function SummaryBlock ({
  label,
  value,
  className,
  children
}: PropsWithChildren<{
  className?: string
  label?: ReactNode
  value?: ReactNode
}>): JSX.Element {
  return (
    <div
      className={twMerge('flex gap-8 justify-between py-4 px-8 border-b last:border-b-0', className)}
    >{children ?? (
      <>
        <strong>{label}</strong>
        <strong className='text-brand-primary text-right'>{value}</strong>
      </>
    )}
    </div>
  )
}

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo
} from 'react'
import { useStorageState } from '../hooks/storage-state'

interface VoucherCodeContextType {
  voucherCode: undefined | string
  setVoucherCode: Dispatch<SetStateAction<undefined | string>>
}

const initialState: VoucherCodeContextType = {
  voucherCode: undefined,
  setVoucherCode: (): never => {
    throw new Error('Not implemented')
  }
}
export const VoucherCodeContext = createContext(initialState)

export default function VoucherCodeProvider ({ children }: PropsWithChildren<{}>): JSX.Element {
  const [voucherCode, setVoucherCode] = useStorageState<string>('voucher')

  const value = useMemo(() => ({ voucherCode, setVoucherCode }), [setVoucherCode, voucherCode])
  return <VoucherCodeContext.Provider value={value}>{children}</VoucherCodeContext.Provider>
}

import { BaseTranslation } from '../services/translations'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import {
  CabinTranslationsQuery, ProductTranslationsQuery,
  ShipTranslationsQuery
} from '../queries/translations'
import {
  Cabin,
  Product,
  ProductsResponse,
  SgrProduct
} from '../services/departures'

function useCurrentLocaleTranslations<T extends BaseTranslation> (translations: T[]): T[] {
  const { i18n } = useTranslation()
  const locale = useMemo(() => i18n.resolvedLanguage, [i18n.resolvedLanguage])
  return useMemo(() => translations.filter(translation => translation.language === locale), [locale, translations])
}

export function useShipTranslations (ship: string): BaseTranslation | undefined {
  const { data } = useQuery(ShipTranslationsQuery())
  const translations = useCurrentLocaleTranslations(data ?? [])

  return useMemo(() => {
    return translations.find(
      translation => translation.id === ship
    )
  }, [translations, ship])
}

export function useCabinTranslations (shipId: string): Map<string, BaseTranslation> {
  const { data } = useQuery(CabinTranslationsQuery(shipId))
  const translations = useCurrentLocaleTranslations(data ?? [])

  return useMemo(() => translations.reduce((map, translation) => {
    map.set(translation.id, translation)
    return map
  }, new Map<string, BaseTranslation>()), [translations])
}

function uniqueUrls (urls: Array<string | null | undefined>): string[] {
  const filtered = urls.filter((url): url is string => url != null)
  return [...new Set(filtered)]
}

export function useTranslatedCabins (cabins: Cabin[] | undefined, translations: ReturnType<typeof useCabinTranslations>): typeof cabins {
  return useMemo(() => {
    if (cabins === undefined) {
      return undefined
    }

    return cabins.map(cabin => {
      const cabinId = cabin.id.split('-')[0]
      const translation = translations.get(cabinId)
      if (translation === undefined) {
        return cabin
      }
      return {
        ...cabin,
        name: translation.name ?? cabin.name,
        description: translation.description ?? undefined,
        image_url: translation.image_url ?? translation.image_url1 ?? undefined,
        image_urls: uniqueUrls([translation.image_url, translation.image_url1, translation.image_url2, translation.image_url3])
      }
    })
  }, [cabins, translations])
}

export function useProductTranslations (): Map<string, BaseTranslation> {
  const { data } = useQuery(ProductTranslationsQuery())
  const translations = useCurrentLocaleTranslations(data ?? [])

  return useMemo(() => translations.reduce((map, translation) => {
    map.set(translation.id, translation)
    return map
  }, new Map<string, BaseTranslation>()), [translations])
}

export function useTranslatedProducts (products: ProductsResponse | undefined, translations: ReturnType<typeof useProductTranslations>): typeof products {
  return useMemo(() => {
    if (products === undefined) {
      return undefined
    }

    const enrichProduct = function<T extends Product | SgrProduct>(product: T): T {
      const translation = translations.get(product.id)
      if (translation === undefined) {
        return product
      }
      return {
        ...product,
        name: translation.name ?? product.name,
        description: translation.description ?? product.description
      }
    }

    return {
      bikes: products.bikes.map(enrichProduct),
      diets: products.diets.map(enrichProduct),
      other: products.other.map(enrichProduct),
      sgr: enrichProduct(products.sgr),
      insurance_policies: products.insurance_policies
    }
  }, [products, translations])
}

import { CabinSelectionContext } from '../contexts/cabin-selection'
import { useCallback, useContext, useDebugValue, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CabinsContext } from '../contexts/cabins'
import { Cabin } from '../services/departures'
import { CabinSelection } from '../types/cabin-selection'

interface CabinSelectionHook {
  passengers: number[]
  setCount: (count: number) => void
  setPassengers: (index: number, count: number) => void
}

export interface SelectableCabin {
  key: string
  name: string
  spaces: number
  cabinTypeId: string
  index: number
}

export function useCabinSelection (cabinTypeId: string, defaultPassengers: number = 2): CabinSelectionHook {
  const { cabinSelection, setCabinTypeSelection } = useContext(CabinSelectionContext)

  const passengers = useMemo(() => cabinSelection[cabinTypeId] ?? [], [cabinTypeId, cabinSelection])

  const setCount = useCallback((count: number) => {
    if (passengers.length > count) {
      setCabinTypeSelection(cabinTypeId, passengers.slice(0, count))
    } else {
      setCabinTypeSelection(cabinTypeId, [...passengers, ...Array(count - passengers.length).fill(defaultPassengers)])
    }
  }, [cabinTypeId, defaultPassengers, passengers, setCabinTypeSelection])

  const setPassengers = useCallback((index: number, count: number) => {
    const newPassengers = [...passengers]
    newPassengers[index] = count
    setCabinTypeSelection(cabinTypeId, newPassengers)
  }, [cabinTypeId, passengers, setCabinTypeSelection])

  useDebugValue(passengers)

  return useMemo(() => ({
    passengers,
    setCount,
    setPassengers
  }), [passengers, setCount, setPassengers])
}

export function useFullCabinSelection (cabinTypeIds: string[]): CabinSelection {
  const [params] = useSearchParams()
  const { cabinSelection, setCabinSelection } = useContext(CabinSelectionContext)

  useEffect(() => {
    const cabins = params.get('cabins')
    if (cabins !== null) {
      const data = new URLSearchParams(cabins)
      const allPassengers: Record<string, number[]> = {}
      for (const [id, passengers] of data.entries()) {
        allPassengers[id] = passengers.split(',').map(value => parseInt(value, 10)).filter(Boolean)
      }
      setCabinSelection(allPassengers)
    }
  }, [params, setCabinSelection])

  return useMemo(() => Object.fromEntries(Object.entries(cabinSelection).filter(([key]) => cabinTypeIds.includes(key))), [cabinSelection, cabinTypeIds])
}

export function useNumberOfPassengers (cabinSelection: CabinSelection): number {
  return useMemo(() => Object.values(cabinSelection).flat().reduce((total, amount) => total + amount, 0), [cabinSelection])
}

export function useSelectableCabins (): SelectableCabin[] {
  const { cabins } = useContext(CabinsContext)
  const cabinsById = useMemo(() => cabins.reduce((map, cabin) => {
    map.set(cabin.id, cabin)
    return map
  }, new Map<string, Cabin>()), [cabins])
  const cabinTypeIds = useMemo(() => Array.from(cabinsById.keys()), [cabinsById])
  const cabinSelection = useFullCabinSelection(cabinTypeIds)

  return useMemo((): SelectableCabin[] => {
    return Object.entries(cabinSelection).flatMap(([cabinTypeId, amounts]) => amounts.map((spaces, index) => ({
      index,
      cabinTypeId,
      key: `${cabinTypeId}:${index}`,
      name: amounts.length > 1 ? `${cabinsById.get(cabinTypeId)?.name ?? 'Unknown room'} ${index + 1}` : cabinsById.get(cabinTypeId)?.name ?? 'Unknown room',
      spaces
    }))).sort((a, b) => a.name.localeCompare(b.name))
  }, [cabinSelection, cabinsById])
}

export function useCabinPricing ({ cabinSelection }: { cabinSelection: CabinSelection }): {
  selectedCabins: Array<{ cabin: Cabin, amount: number, unit_price: number }>
  totalPassengers: number
  totalCabinPrice: number
} {
  const { cabinMap } = useContext(CabinsContext)

  const selectedCabins = useMemo(() => {
    return Object.entries(cabinSelection).flatMap(([id, amounts]) => {
      return amounts.map(amount => ({
        cabin: cabinMap[id],
        amount,
        unit_price: (amount < 2 ? cabinMap[id].surcharge_price : (amount > 2 ? cabinMap[id].discount_price : cabinMap[id].unit_price)) ?? cabinMap[id].unit_price
      }))
    }).sort((a, b) => {
      return a.cabin.name.localeCompare(b.cabin.name)
    })
  }, [cabinMap, cabinSelection])
  const totalCabinPrice = useMemo(() => {
    return selectedCabins.reduce((total, selectedCabin) => total + selectedCabin.unit_price * selectedCabin.amount, 0)
  }, [selectedCabins])

  const totalPassengers = useMemo(() => {
    return selectedCabins.reduce((total, selectedCabin) => total + selectedCabin.amount, 0)
  }, [selectedCabins])

  return {
    selectedCabins,
    totalPassengers,
    totalCabinPrice
  }
}

import { SerializedPassengerInformation } from '../../types/passenger-information'
import SummaryBlock from './block'
import { Disclosure } from '@headlessui/react'
import { ChevronDown, ChevronRight } from 'react-feather'
import { useCurrencyFormatter } from '../../hooks/formatter'
import { usePassengerProducts } from '../../hooks/products'
import { useContext } from 'react'
import { DepartureContext } from '../../contexts/departure'
import { TourContext } from '../../contexts/tour'
import { priceMultiplier } from '../../utils/products'

interface ProductsBlockProps {
  passengerInformation: SerializedPassengerInformation
  label: string
  bikes?: boolean
  diets?: boolean
  other?: boolean
}

export default function ProductsBlock ({ passengerInformation, label, bikes = false, diets = false, other = false }: ProductsBlockProps): JSX.Element | undefined {
  const formatter = useCurrencyFormatter()
  const { departure } = useContext(DepartureContext)
  const { tour: { tours: [tour] } } = useContext(TourContext)
  const days = departure?.duration ?? tour.duration
  const { passengerProductEntries, passengerProductsTotalPrice } = usePassengerProducts({
    passengerInformation,
    bikes,
    diets,
    other,
    days
  })

  if (passengerProductEntries.length === 0) {
    return undefined
  }

  return (
    <SummaryBlock className='flex-col gap-2'>
      <Disclosure>
        <Disclosure.Button className='flex gap-8 justify-between text-left'>
          {({ open }) => (
            <>
              <span className='inline-flex gap-1'>
                {open ? <ChevronDown className='text-brand-primary' /> : <ChevronRight className='text-brand-primary' />}
                <strong>{label}</strong>
              </span>
              <strong className='text-brand-primary'>{formatter.format(passengerProductsTotalPrice)}</strong>
            </>
          )}
        </Disclosure.Button>
        <Disclosure.Panel>
          <ul className='flex flex-col gap-4 my-4'>
            {passengerProductEntries.map(([product, count]) => (
              <li key={product.id} className='flex gap-8 justify-between'>
                <span>{product.base_name ?? product.name}</span>
                <span className='text-brand-primary whitespace-nowrap'>{count}&times;&nbsp;{formatter.format(product.unit_price * priceMultiplier(product.price_calculation, days))}</span>
              </li>
            ))}
          </ul>
        </Disclosure.Panel>
      </Disclosure>
    </SummaryBlock>
  )
}

import { CabinsContext } from '../../contexts/cabins'
import { useCabinPricing, useFullCabinSelection } from '../../hooks/cabin-selection'
import { useContext } from 'react'
import { useCurrencyFormatter } from '../../hooks/formatter'
import OverviewBlock from './block'
import { useTranslation } from 'react-i18next'

export default function CabinSection (): JSX.Element {
  const { t } = useTranslation()
  const { cabinTypeIds = [] } = useContext(CabinsContext)
  const cabinSelection = useFullCabinSelection(cabinTypeIds)
  const { selectedCabins, totalCabinPrice } = useCabinPricing({ cabinSelection })
  const formatter = useCurrencyFormatter()
  return (
    <OverviewBlock className='flex-col gap-4'>
      <div className='flex justify-between gap-8'>
        <strong>{t('summary:cabins', 'Cabins')}</strong>
        <strong>{formatter.format(totalCabinPrice)}</strong>
      </div>
      {selectedCabins.map(({ cabin, amount, unit_price: unitPrice }, index) => (
        <div className='flex justify-between gap-8 border-t pt-4' key={`${cabin.id}-${index}`}>
          <span>{amount}&times; {cabin.name}</span>
          <span>{formatter.format(unitPrice)}</span>
        </div>
      ))}
    </OverviewBlock>
  )
}

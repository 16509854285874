import { useEffect } from 'react'

export function AutoSaveValues<T> ({ values, save }: {
  values: T
  save: (values: T) => void
}): JSX.Element | undefined {
  // Turning the auto-save in a separate component eases using the values and allows conditional hook execution
  useEffect(() => {
    save(values)
  }, [save, values])

  return undefined
}

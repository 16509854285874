import { Payment, PaymentIntent } from '../services/payments'
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult
} from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'
import { PaymentQuery } from '../queries/payments'
import { Stripe, StripeElements } from '@stripe/stripe-js'

export function usePayment (paymentId: string): UseQueryResult<Payment | null> {
  return useQuery(PaymentQuery(paymentId))
}

export function usePaymentResult (): {
  paymentIntent: string | null
  clientSecret: string | null
  status: string | null
} {
  const [searchParams] = useSearchParams()

  return useMemo(() => {
    return {
      paymentIntent: searchParams.get('payment_intent'),
      clientSecret: searchParams.get('payment_intent_client_secret'),
      status: searchParams.get('redirect_status')
    }
  }, [searchParams])
}

interface PaymentIntentMutationParams {
  stripe: Stripe
  elements: StripeElements
  billingDetails: {
    name: string
    email: string
  }
  redirectUri: string
  getIntent: (paymentMethodId: string) => Promise<PaymentIntent>
}

export function usePaymentIntent (): UseMutationResult<void, Error, PaymentIntentMutationParams> {
  return useMutation(async ({ stripe, elements, redirectUri, billingDetails, getIntent }: PaymentIntentMutationParams) => {
    const { error: submitError } = await elements.submit()
    if (submitError != null) {
      throw new Error(submitError.message)
    }

    const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: billingDetails
      }
    })
    if (paymentMethodError != null || paymentMethod == null) {
      throw new Error(paymentMethodError.message ?? 'Unable to create payment method')
    }

    const { client_secret: clientSecret } = await getIntent(paymentMethod.id)

    const returnUrl = new URL(redirectUri, window.location.href)
    const { error } = await stripe.confirmPayment({
      clientSecret,
      confirmParams: {
        return_url: returnUrl.toString()
      }
    })

    if (error != null) {
      throw new Error(error.message)
    }
  })
}

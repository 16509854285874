import { Departure, Product } from '../services/departures'
import { SerializedPassengerInformation } from '../types/passenger-information'

export function priceMultiplier (calculation: Product['price_calculation'], days: number): number {
  switch (calculation) {
    case 'per_day':
      return days
    case 'per_night':
      return days - 1
    case null:
      return 1
  }
}

export function hasSGR (departure: Departure | undefined, passengers?: SerializedPassengerInformation): boolean {
  return passengers == null || departure?.multi_market === false || !['CA', 'US'].includes(passengers.main.country ?? '-')
}

export function hasVAT (departure: Departure | undefined, passengers?: SerializedPassengerInformation): boolean {
  return passengers == null || departure?.multi_market === false || !['CA', 'US'].includes(passengers.main.country ?? '-')
}

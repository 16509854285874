import { RouterProvider } from 'react-router-dom'
import router from './router'
import { useEffect } from 'react'
import { UnsupportedBrowser } from './pages/unsupported-browser'

function App (): JSX.Element {
  useEffect(() => {
    if (window !== window.top) {
      document.documentElement.dataset.frame = 'true'
    }

    return () => {
      delete document.documentElement.dataset.frame
    }
  }, [])

  if (typeof [].toSorted !== 'function') {
    return <UnsupportedBrowser />
  }

  return (
    <RouterProvider router={router} />
  )
}
export default App

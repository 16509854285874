import Select from './select'
import dayjs from '../libs/dayjs'
import { useCallback, useMemo } from 'react'
import { DeparturePrice } from '../services/departures'
import { useTranslation } from 'react-i18next'
import { useCurrencyFormatter } from '../hooks/formatter'

interface MonthSelectProps {
  months: Array<{ date: dayjs.Dayjs, price?: DeparturePrice }>
  value: dayjs.Dayjs
  onMonthChange?: (month: dayjs.Dayjs) => void
}

export default function MonthSelect ({ months, value, onMonthChange }: MonthSelectProps): JSX.Element {
  const { t } = useTranslation()
  const formatter = useCurrencyFormatter()
  const options = useMemo(() => {
    return months.map(({ date: month, price }) => ({
      value: month.startOf('month').toISOString().substring(0, 10),
      name: price !== undefined
        ? (
          <>
            <span>{month.format('MMMM YYYY')}</span>
            <span className='text-brand-subtitle ui-selected:text-white ui-active:ui-not-selected:text-brand-subtitle ui-active:ui-selected:text-white ml-2'>{t('price_starting_from', { price: formatter.format(price.minimum_price) })}</span>
          </>)
        : month.format('MMMM YYYY'),
      month
    }))
  }, [formatter, months, t])

  const currentOption = useMemo(() => {
    const formatted = value.startOf('month').toISOString().substring(0, 10)
    return options.find(option => option.value === formatted)
  }, [options, value])

  const onChange = useCallback((newValue: typeof options[0]) => {
    onMonthChange?.(newValue.month)
  }, [onMonthChange])

  return <Select options={options} value={currentOption} onChange={onChange} label={t('departure_date', 'Departure date')} />
}

import {
  useCabinPricing,
  useFullCabinSelection
} from '../../hooks/cabin-selection'
import { useAllVouchers, useDiscounts } from '../../hooks/vouchers'
import { useTranslation } from 'react-i18next'
import { useCurrencyFormatter } from '../../hooks/formatter'
import OverviewBlock from './block'
import { useContext } from 'react'
import { CabinsContext } from '../../contexts/cabins'
import { VoucherContext } from '../../contexts/voucher'
import { DepartureContext } from '../../contexts/departure'

export default function VoucherSection (): JSX.Element {
  const { departure } = useContext(DepartureContext)
  const { cabinTypeIds = [] } = useContext(CabinsContext)
  const cabinSelection = useFullCabinSelection(cabinTypeIds)
  const { totalCabinPrice, totalPassengers } = useCabinPricing({ cabinSelection })
  const { voucher } = useContext(VoucherContext)
  const vouchers = useAllVouchers(departure, voucher)
  const { discount, discountLines } = useDiscounts(totalCabinPrice, totalPassengers, vouchers)
  const { t } = useTranslation()
  const formatter = useCurrencyFormatter()

  if (discountLines.length === 0) {
    return (<></>)
  }

  return (
    <OverviewBlock className='flex-col gap-2'>
      <div className='flex justify-between gap-8'>
        <strong>{t('discount', 'Discount')}</strong>
        <strong className='text-brand-discount text-right'>{formatter.format(-discount)}</strong>
      </div>
      {
        discountLines.map(({ voucher, amount }) => (
          <div key={voucher.code}>
            <div className='flex gap-8 justify-between border-t pt-4'>
              <div>{voucher === departure?.voucher ? (voucher.discount_type === 'Discount Percentage' ? `${voucher.percentage}%` : t('price_per_person', { price: `${formatter.format(voucher.amount)}` })) : t('current_voucher_code', 'Code: {{ code}}', { code: voucher?.code })}</div>
              <div className='text-brand-discount text-right'>{formatter.format(-amount)}</div>
            </div>
            {voucher.description !== null && <p><i>{voucher.description}</i></p>}
          </div>
        ))
      }
    </OverviewBlock>
  )
}

import { PropsWithChildren, ReactNode, useLayoutEffect, useRef } from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'
import { Tooltip } from './tooltip'

function ErrorTooltip ({ label, anchor = 'bottom' }: {
  label: string | Exclude<ReactNode, null | undefined | boolean>
  anchor?: 'top' | 'bottom'
}): JSX.Element {
  const labelRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    const errorLabel = labelRef.current
    if (errorLabel !== null) {
      const rect = errorLabel.getBoundingClientRect()
      const viewportWidth = window.innerWidth
      const viewportHeight = window.innerHeight - (viewportWidth >= 1024 ? 0 : 62)
      if (
        rect.top < 0 || rect.bottom >= viewportHeight ||
        rect.left < 0 || rect.right >= viewportWidth
      ) {
        const parent = errorLabel.parentElement?.parentElement
        if (parent != null) {
          const parentRect = parent.getBoundingClientRect()
          const minLeft = Math.min(parentRect.left, rect.left)
          const minTop = Math.min(parentRect.top, rect.top)
          const maxRight = Math.max(parentRect.right, rect.right)
          const maxBottom = Math.max(parentRect.bottom, rect.bottom)
          const totalWidth = Math.max(parentRect.right, rect.right) - Math.min(parentRect.left, rect.left)
          const totalHeight = Math.min(parentRect.bottom, rect.bottom) - Math.min(parentRect.top, rect.top)
          if (totalHeight <= viewportHeight && totalWidth <= viewportWidth) {
            // Scroll parent element into view
            const offset = { behavior: 'smooth' as const, left: 0, top: 0 }
            if (minLeft < 0) {
              offset.left = minLeft
            } else if (maxRight > viewportWidth) {
              offset.left = maxRight - viewportWidth
            }
            if (minTop < 0) {
              offset.top = minTop
            } else if (maxBottom > viewportHeight) {
              offset.top = maxBottom - viewportHeight
            }
            window.scrollBy(offset)
          } else {
            errorLabel.scrollIntoView()
          }
        } else {
          errorLabel.scrollIntoView()
        }
      }
    }
  }, [])
  return <Tooltip anchor={anchor} childRef={labelRef}>{label}</Tooltip>
}

export function WithError ({
  anchor = 'bottom',
  error,
  clearError,
  className,
  children
}: PropsWithChildren<{
  error: Exclude<ReactNode, null | undefined | boolean> | string | null
  anchor?: 'top' | 'bottom'
  clearError?: () => void
  className?: string
}>): JSX.Element {
  return (
    <div
      className={twMerge(classNames('relative inline-block frame:-outline-offset-4 outline-brand-primary', className, { outline: error !== null }))}
      onClick={() => clearError?.()}
      onFocus={() => clearError?.()}
    >
      {children}
      {error !== null && (<ErrorTooltip anchor={anchor} label={error} />)}
    </div>
  )
}

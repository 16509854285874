import BoatBikeIcon from '../icons/boatbike'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import InselhuepfenIcon from '../icons/inselhuepfen'

function LoaderLine (): JSX.Element {
  return (
    <div className='overflow-hidden brand-inselhuepfen:pt-6'>
      <div
        className='max-w-xl w-full px-4 mx-auto border-b border-dashed border-b-brand-primary text-brand-primary pb-4 brand-inselhuepfen:border-wavy-lines brand-inselhuepfen:pb-0'
      >
        <div className='animate-left-right flex justify-center'>
          <InselhuepfenIcon
            className='fill-current transition-transform -scale-x-150 scale-y-150 -translate-y-4 hidden brand-inselhuepfen:block'
            size={1.5}
          />
          <BoatBikeIcon
            className='-scale-x-100 transition-transform text-[#C0101D] brand-inselhuepfen:hidden'
          />
        </div>
      </div>
    </div>
  )
}

function LoaderCircle (): JSX.Element {
  return (
    <div className='grid place-items-center'>
      <div className='rounded-full w-12 h-12 row-start-1 col-start-1 text-brand-primary animate-[spin_3s_linear_infinite]'>
        <svg viewBox='0 0 100 100'>
          <circle r='48' fill='none' stroke='currentColor' cx='50%' cy='50%' strokeWidth='3' strokeLinecap='round' strokeDasharray='226, 226' />
        </svg>
      </div>
      <div className='text-[#C0101D] w-12 h-12 row-start-1 col-start-1 grid place-items-center'>
        <BoatBikeIcon />
      </div>
    </div>
  )
}

export default function Loader ({ description, type = 'line' }: {
  description?: ReactNode
  type?: 'line' | 'circle'
}): JSX.Element {
  const { t } = useTranslation()
  const text = (description !== undefined && description !== '') ? description : t('loading', 'Loading…')
  return (
    <div className='mt-32 text-brand-primary'>
      {type === 'circle' ? <LoaderCircle /> : <LoaderLine />}
      <div className='p-2 text-sm text-center'>{text}</div>
    </div>
  )
}

import { Query } from '../types/queries'
import DepartureService, { Cabin, Departure, ProductsResponse } from '../services/departures'
import dayjs from '../libs/dayjs'

export const DepartureQueryKeys = {
  all: () => ['departures'] as const,
  list: (tourId: string, start: string, end: string) => ['departures', tourId, start, end] as const,
  get: (departureId: string) => ['departures', departureId] as const,
  cabins: (departureId: string) => ['departures', departureId, 'cabins'] as const,
  products: (departureId: string) => ['departures', departureId, 'products'] as const
}

const dateRangeForMonth = (date: dayjs.Dayjs): [string, string] => {
  const start = date.startOf('month')
  const end = start.add(1, 'month')
  return [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')]
}

async function fetchDepartures ({ queryKey: [,tourId, start, end] }: { queryKey: ReturnType<typeof DepartureQueryKeys.list> }): Promise<Departure[]> {
  if (tourId === '') {
    return []
  }
  const departureService = new DepartureService()
  return await departureService.tourDepartures(tourId, start, end)
}

async function fetchDeparture ({ queryKey: [,departureId] }: { queryKey: ReturnType<typeof DepartureQueryKeys.get> }): Promise<Departure> {
  const departureService = new DepartureService()
  return await departureService.getDeparture(departureId ?? '')
}

async function fetchDepartureCabins ({ queryKey: [, departureId] }: { queryKey: ReturnType<typeof DepartureQueryKeys.cabins> }): Promise<Cabin[]> {
  const departureService = new DepartureService()
  return await departureService.cabinAvailability(departureId)
}

async function fetchDepartureProducts ({ queryKey: [, departureId] }: { queryKey: ReturnType<typeof DepartureQueryKeys.products> }): Promise<ProductsResponse> {
  if (departureId === '') {
    return {
      bikes: [],
      diets: [],
      other: [],
      sgr: {
        id: 'sgr',
        name: 'SGR',
        unit_price: 0
      },
      insurance_policies: []
    }
  }
  const departureService = new DepartureService()
  return await departureService.departureProducts(departureId ?? '')
}

const DepartureQuery = (departureId: string | undefined): Query<typeof DepartureQueryKeys.get, typeof fetchDeparture> => ({
  queryKey: DepartureQueryKeys.get(departureId ?? ''),
  queryFn: fetchDeparture,
  retry: false,
  enabled: departureId !== undefined
})

const DeparturesQuery = (tourId: string | undefined, month: dayjs.Dayjs): Query<typeof DepartureQueryKeys.list, typeof fetchDepartures> => {
  const [start, end] = dateRangeForMonth(month)
  return {
    queryKey: DepartureQueryKeys.list(tourId ?? '', start, end),
    queryFn: fetchDepartures,
    retry: false,
    enabled: tourId !== undefined
  }
}

const DepartureCabinsQuery = (departureId: string | undefined): Query<typeof DepartureQueryKeys.cabins, typeof fetchDepartureCabins> => ({
  queryKey: DepartureQueryKeys.cabins(departureId ?? ''),
  queryFn: fetchDepartureCabins,
  retry: false,
  enabled: departureId !== undefined
})

const DepartureProductsQuery = (departureId: string | undefined): Query<typeof DepartureQueryKeys.products, typeof fetchDepartureProducts> => ({
  queryKey: DepartureQueryKeys.products(departureId ?? ''),
  queryFn: fetchDepartureProducts,
  retry: false,
  enabled: departureId !== undefined
})

export const DepartureQueries = {
  list: DeparturesQuery,
  get: DepartureQuery,
  cabins: DepartureCabinsQuery,
  products: DepartureProductsQuery
}

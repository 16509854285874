import {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useMemo,
  useState
} from 'react'

type BackActionType = undefined | (() => void)

interface NavigationContextType {
  backAction: BackActionType
  setBackAction: Dispatch<SetStateAction<BackActionType>>
}

const initialState: NavigationContextType = {
  backAction: undefined,
  setBackAction: (): void => {}
}
export const NavigationContext = createContext(initialState)

export default function NavigationProvider ({ children }: PropsWithChildren<{}>): ReactNode {
  const [backAction, setBackAction] = useState<BackActionType>()

  const value = useMemo(() => ({
    backAction, setBackAction
  }), [backAction])

  return (
    <NavigationContext.Provider
      value={value}
    >{children}
    </NavigationContext.Provider>
  )
}

import {
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query'
import { useEffect } from 'react'

export function usePreloadQuery (query: UseQueryOptions<any, any, any, any>): void {
  const client = useQueryClient()
  useEffect(() => {
    void client.prefetchQuery(query)
  }, [client, query])
}

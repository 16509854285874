import Service from './base'
import dayjs from 'dayjs'
import { z } from 'zod'

const departureTypeSchema = z.enum(['available', 'unavailable', 'on_request', 'discount'])
export type DepartureType = z.infer<typeof departureTypeSchema>
const productTypeSchema = z.enum(['Bike', 'Diet', 'Additional Product', 'Local Cost'])
export type ProductType = z.infer<typeof productTypeSchema>

const baseVoucherSchema = z.object({
  code: z.string(),
  description: z.string().nullable()
})

const percentageVoucherSchema = baseVoucherSchema.extend({
  percentage: z.coerce.number(),
  discount_type: z.literal('Discount Percentage')
})

const amountVoucherSchema = baseVoucherSchema.extend({
  amount: z.coerce.number(),
  discount_type: z.literal('Discount Amount')
})

const voucherSchema = z.discriminatedUnion('discount_type', [
  percentageVoucherSchema,
  amountVoucherSchema
])

export type PercentageVoucher = z.infer<typeof percentageVoucherSchema>
export type AmountVoucher = z.infer<typeof amountVoucherSchema>
export type Voucher = z.infer<typeof voucherSchema>

const DepartureAvailabilitySchema = z.object({
  minimum_passengers: z.number(),
  available_passengers: z.number(),
  maximum_passengers: z.number(),
  available_cabins: z.number(),
  total_cabins: z.number(),
  minimum_price: z.coerce.number(),
  maximum_price: z.coerce.number()
})

export type DepartureAvailability = z.infer<typeof DepartureAvailabilitySchema>

const DepartureSchema = z.object({
  id: z.string(),
  tour_id: z.string(),
  tour_name: z.string(),
  ship_id: z.string(),
  ship_name: z.string(),
  program_id: z.string(),
  program_name: z.string(),
  date: z.string(),
  end_date: z.string(),
  on_request: z.boolean(),
  duration: z.number(),
  port_of_departure_name: z.string().optional(),
  port_of_arrival_name: z.string().optional(),
  voucher: voucherSchema.optional().nullable(),
  multi_market: z.boolean().optional(),
  availability: DepartureAvailabilitySchema.optional().nullable()
})

export type Departure = z.infer<typeof DepartureSchema>

const DeparturePriceSchema = z.object({
  year: z.number(),
  month: z.number(),
  minimum_price: z.coerce.number(),
  maximum_price: z.coerce.number()
})

export type DeparturePrice = z.infer<typeof DeparturePriceSchema>

const CabinSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  image_url: z.string().optional(),
  image_urls: z.array(z.string()).optional(),
  minimum_occupancy: z.number(),
  maximum_occupancy: z.number(),
  unit_price: z.coerce.number(),
  discount_price: z.coerce.number().nullable(),
  surcharge_price: z.coerce.number().nullable(),
  count: z.number(),
  available_count: z.number()
})

export type Cabin = z.infer<typeof CabinSchema>

const productSchema = z.object({
  unit_price: z.coerce.number(),
  price_calculation: z.enum(['per_day', 'per_night']).nullable(),
  price_per_day: z.boolean(),
  id: z.string().length(18),
  name: z.string(),
  description: z.string().optional(),
  product_type: z.nullable(productTypeSchema),
  mandatory: z.boolean(),
  passenger_product: z.boolean(),
  base_name: z.optional(z.string()),
  local_cost: z.boolean()
})
export type Product = z.infer<typeof productSchema>

const sgrProductSchema = z.object({
  unit_price: z.coerce.number(),
  id: z.string().length(18),
  name: z.string(),
  description: z.string().optional()
})

export type SgrProduct = z.infer<typeof sgrProductSchema>

const insurancePolicySchema = z.object({
  id: z.string().length(18),
  insurance_type: z.enum(['cancellation', 'travel']),
  market: z.enum(['default', 'north_america']),
  fixed_cost: z.coerce.number(),
  fee_percentage: z.coerce.number(),
  tax_percentage: z.coerce.number()
})

export type InsurancePolicy = z.infer<typeof insurancePolicySchema>

const productsResponseSchema = z.object({
  bikes: z.array(productSchema),
  diets: z.array(productSchema),
  other: z.array(productSchema),
  sgr: sgrProductSchema,
  insurance_policies: z.array(insurancePolicySchema)
})
export type ProductsResponse = z.infer<typeof productsResponseSchema>

export default class DepartureService extends Service {
  async getDeparture (departureId: string): Promise<Departure> {
    const departure = await this.get(`/api/v1/departures/${departureId}`)
    return DepartureSchema.parse(departure)
  }

  async tourDepartures (tourId: string, startDate: dayjs.Dayjs | string, endDate: dayjs.Dayjs | string): Promise<Departure[]> {
    const departures = await this.get(`/api/v1/tours/${tourId}/departures`, {
      start: typeof startDate === 'string' ? startDate : startDate.format('YYYY-MM-DD'),
      end: typeof endDate === 'string' ? endDate : endDate.format('YYYY-MM-DD')
    })
    return z.array(DepartureSchema).parse(departures)
  }

  async tourPrices (tourId: string): Promise<DeparturePrice[]> {
    const prices = await this.get(`/api/v1/tours/${tourId}/prices`)
    return z.array(DeparturePriceSchema).parse(prices)
  }

  async cabinAvailability (departureId: string): Promise<Cabin[]> {
    const cabins = await this.get(`/api/v1/departures/${departureId}/cabins`)
    return z.array(CabinSchema).parse(cabins)
  }

  async departureProducts (departureId: string): Promise<ProductsResponse> {
    const products = await this.get(`/api/v1/departures/${departureId}/products`)
    return productsResponseSchema.parse(products)
  }

  async voucher (departureId: string, voucherCode: string): Promise<Voucher> {
    const voucher = await this.get(`/api/v1/departures/${departureId}/voucher`, { code: voucherCode })
    return voucherSchema.parse(voucher)
  }
}

import { PaymentContext } from '../contexts/payment'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Payment from '../components/payment'
import { Navigate } from 'react-router-dom'
import { PaymentService } from '../services/payments'
import Container from '../components/container'
import PaymentDetails from '../components/payment-details'

export default function InitiatePayment (): JSX.Element {
  const { payment } = useContext(PaymentContext)
  const { t } = useTranslation()

  const redirectUri = useMemo(() => `/payments/${payment.id}/completed`, [payment.id])

  const getIntent = useCallback(async (paymentMethodId: string) => {
    const paymentService = new PaymentService()
    return await paymentService.createPayment(payment.id, paymentMethodId)
  }, [payment.id])

  if (payment.status !== 'Open') {
    return (<Navigate to={`${redirectUri}?redirect_status=succeeded`} />)
  }

  return (
    <Container>
      <div className='pb-8'>
        <h2 className='text-brand-primary text-lg font-bold my-8'>{t('payment:payment_title', 'Fulfill your outstanding payment')}</h2>
        <p className='my-3'>{t('payment:payment_description', 'Please proceed with the payment for your booking.')}</p>
        <PaymentDetails payment={payment} />
        <Payment redirectUri={redirectUri} market={payment.market} amount={Math.round(payment.amount * 100)} getIntent={getIntent} billingDetails={{ name: payment.full_name, email: payment.email }} />
      </div>
    </Container>
  )
}

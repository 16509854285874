import { ProductsResponse } from '../../services/departures'
import { useCurrencyFormatter } from '../../hooks/formatter'
import OverviewBlock from './block'
import { useTranslation } from 'react-i18next'

export default function SGRSection ({ numberOfPassengers, sgrProduct }: { numberOfPassengers: number, sgrProduct: ProductsResponse['sgr'] }): JSX.Element {
  const { t } = useTranslation()
  const formatter = useCurrencyFormatter()
  return (
    <OverviewBlock className='bg-brand-well-dark text-sm'>
      <strong>{numberOfPassengers}&times; {sgrProduct.name}</strong>
      <span>{t('price_per_person', '{{ price }}\u00A0p.p.', { price: formatter.format(sgrProduct.unit_price) })}</span>
    </OverviewBlock>
  )
}

import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AmountVoucher, Departure, PercentageVoucher, Voucher } from '../services/departures'
import { useMemo } from 'react'
import { VoucherQueries } from '../queries/vouchers'

export function useVoucher (departureId: string, voucherCode: string): UseQueryResult<Voucher | null> {
  return useQuery(VoucherQueries.get(departureId, voucherCode))
}

export function useAllVouchers (departure?: Departure, voucher?: Voucher | null): Voucher[] {
  return useMemo(() => {
    if (departure?.voucher != null && voucher != null) {
      return [departure.voucher, voucher]
    }
    if (departure?.voucher != null) {
      return [departure.voucher]
    }
    if (voucher != null) {
      return [voucher]
    }
    return []
  }, [departure?.voucher, voucher])
}

function isPercentageVoucher (voucher: Voucher): voucher is PercentageVoucher {
  return voucher.discount_type === 'Discount Percentage'
}

function isAmountVoucher (voucher: Voucher): voucher is AmountVoucher {
  return voucher.discount_type === 'Discount Amount'
}

export function useDiscounts (totalCabinPrice: number, totalPassengers: number, vouchers: Voucher[]): { discount: number, discountLines: Array<{ amount: number, voucher: Voucher }> } {
  return useMemo(() => {
    const percentageDiscounts = vouchers.filter(isPercentageVoucher)
    const amountDiscounts = vouchers.filter(isAmountVoucher)

    const [percentageDiscountLines] = percentageDiscounts.reduce(([lines, total], voucher) => {
      const amount = Math.round(voucher.percentage * totalCabinPrice) / 100
      const line = {
        amount,
        voucher
      }
      return [[...lines, line], total - amount]
    }, [[] as Array<{ voucher: PercentageVoucher, amount: number }>, totalCabinPrice])

    const amountDiscountLines = amountDiscounts.map(voucher => {
      const amount = Math.round(voucher.amount * totalPassengers)
      return {
        amount,
        voucher
      }
    })

    const discountLines: Array<{ voucher: Voucher, amount: number }> = [...percentageDiscountLines, ...amountDiscountLines]
    const totalDiscount = discountLines.reduce((total, { amount }) => total + amount, 0)
    return { discount: totalDiscount, discountLines }
  }, [totalCabinPrice, totalPassengers, vouchers])
}

import { useFormikContext } from 'formik'
import { useContext, useEffect, useRef } from 'react'
import { Tour } from '../services/tours'
import { Product } from '../services/departures'
import { gtmAddProduct } from '../utils/gtm'
import { TourContext } from '../contexts/tour'
import { ProductsContext } from '../contexts/products'

function gtmProductChange (oldCount: Record<string, number>, newCount: Record<string, number>, tour: Tour, productMap: Map<string, Product>): void {
  const allIds = new Set([...Object.keys(oldCount), ...Object.keys(newCount)])
  for (const id of allIds) {
    const previous = oldCount[id] ?? 0
    const next = newCount[id] ?? 0
    if (previous !== next) {
      const product = productMap.get(id)
      if (product != null) {
        gtmAddProduct(product, tour, 'Other', next - previous)
      }
    }
  }
}

export default function AdditionalProductsTagManager (): undefined {
  const { tour: { tours: [tour] } } = useContext(TourContext)
  const { productMap } = useContext(ProductsContext)
  const { values: { products } } = useFormikContext<{ products: Record<string, number> }>()

  const previousProducts = useRef(products)

  useEffect(() => {
    gtmProductChange(previousProducts.current, products, tour, productMap)
    previousProducts.current = products
  }, [productMap, products, tour])

  return undefined
}

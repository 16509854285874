import dayjs from '../libs/dayjs'
import useCalendar, { useWeekdays } from '../hooks/calendar'
import {
  ComponentProps,
  Fragment,
  PropsWithChildren,
  useCallback,
  useMemo
} from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { DepartureState, DepartureStates } from '../types/departures'

export interface CalendarProps {
  value?: dayjs.Dayjs
  onChange?: (value: dayjs.Dayjs) => void
  departureStates: DepartureStates
  month: dayjs.Dayjs
  min?: dayjs.Dayjs
  max?: dayjs.Dayjs
  selectionRange?: readonly [dayjs.Dayjs, dayjs.Dayjs]
  onMonthChange?: (month: dayjs.Dayjs) => void
  onNextMonth?: () => void
  onPreviousMonth?: () => void
  className?: string
  showNext?: boolean
  showPrevious?: boolean
}

interface CalendarDayProps {
  date: dayjs.Dayjs
  month: dayjs.Dayjs
  state: DepartureState | null
  selected?: boolean
}

function CalendarDay ({ date, month, state, selected = false, children, ...buttonProps }: PropsWithChildren<CalendarDayProps & ComponentProps<'button'>>): JSX.Element {
  return (
    <button
      className={twMerge(classNames('size-9 text-xs row-span-1 col-span-1 grid border-2 border-white focus-visible:outline accent-success outline-success-600 place-items-center rounded-full bg-white select-none cursor-default',
        '@sm/calendar:size-10 @sm/calendar:my-0 @sm/calendar:text-sm',
        '@md/calendar:size-11 @md/calendar:my-0 @md/calendar:text-sm',
        '@lg/calendar:size-14 @lg/calendar:my-0.5 @lg/calendar:text-base', {
          invisible: !date.isSame(month, 'month'),
          'border-brand-available bg-brand-available hover:bg-brand-available-hover hover:border-brand-available-hover text-white cursor-pointer available': state?.type === 'available',
          'border-brand-unavailable bg-brand-unavailable text-white opacity-60 cursor-not-allowed unavailable': state?.type === 'unavailable',
          'cursor-pointer on-request': state?.type === 'on_request',
          'ring ring-success-700/60 selected': selected
        }, {
          'border-brand-discount bg-brand-discount hover:bg-brand-discount-hover hover:border-brand-discount-hover text-white discounted': (state?.discount ?? false) && state?.type !== 'unavailable'
        }, {
          'border-brand-primary border-dashed hover:border-brand-primary ': state?.type === 'on_request'
        }))}
      {...buttonProps}
    >{children}
    </button>
  )
}

export default function Calendar ({ value, onChange, departureStates, min, max, month, selectionRange, onMonthChange, onPreviousMonth, onNextMonth, className, showNext = true, showPrevious = true }: CalendarProps): JSX.Element {
  /*
    This component renders a calendar in a CSS grid.
    The button for each day is positioned on the grid,
    because we can then overlay the selection range on top of the buttons.
    The selection range is rendered with `pointer-events: none` to allow buttons below to be clicked.
   */
  const monthWeeks = useCalendar(month)
  const weekdays = useWeekdays()

  const selections = useMemo(() => {
    if (selectionRange === undefined) {
      return []
    }
    const [start, end] = selectionRange
    const dayBeforeEnd = end.subtract(1, 'day')
    return monthWeeks.flatMap((week, weekIndex) => {
      const range = week.flatMap((day, index) => {
        if (!day.isSame(month, 'month')) {
          return []
        }
        if (day.isBefore(start, 'day') || day.isAfter(end, 'day') || day.isSame(end, 'day')) {
          return []
        }
        return [{
          index: index + 1,
          starts: day.isSame(start, 'day'),
          ends: day.isSame(dayBeforeEnd, 'day')
        }]
      })
      const first = range.at(0)
      const last = range.at(-1)
      if (first !== undefined && last !== undefined) {
        return [{
          row: weekIndex,
          range,
          starts: first.starts,
          ends: last.ends,
          start: first.index,
          end: last.index
        }]
      }
      return []
    })
  }, [month, monthWeeks, selectionRange])

  const oldPreviousMonth = useCallback(() => {
    onMonthChange?.(month.subtract(1, 'month'))
  }, [month, onMonthChange])
  const gotoPreviousMonth = onPreviousMonth ?? oldPreviousMonth

  const oldNextMonth = useCallback(() => {
    onMonthChange?.(month.add(1, 'month'))
  }, [month, onMonthChange])
  const gotoNextMonth = onNextMonth ?? oldNextMonth

  const canGoPrevious = useMemo(() => min === undefined || month.isAfter(min, 'month'), [min, month])
  const canGoNext = useMemo(() => max === undefined || month.isBefore(max, 'month'), [max, month])

  return (
    <div
      className={classNames('@container/calendar calendar grid grid-cols-7 content-start gap-3 gap-y-3.5 p-6 rounded select-none', className)}
    >
      <div className='col-span-7 w-full flex justify-center gap-4 text-brand-primary font-medium text-base'>
        {showPrevious
          ? (
            <button
              onClick={gotoPreviousMonth} className='flex-1 flex justify-end disabled:text-secondary' type='button'
              disabled={!canGoPrevious}
            ><ChevronLeft />
            </button>
            )
          : <div className='flex-1' />}
        <span>{month.format('MMMM YYYY')}</span>
        {showNext
          ? (
            <button
              onClick={gotoNextMonth} className='flex-1 flex justify-start disabled:text-secondary' type='button'
              disabled={!canGoNext}
            ><ChevronRight />
            </button>
            )
          : <div className='flex-1' />}
      </div>
      {weekdays.map(day => (
        <div key={day} className='text-center'>{day}</div>
      ))}
      {monthWeeks.map((weekDays, row) => (
        <Fragment key={row}>
          {weekDays.map((day, col) => (
            <CalendarDay
              key={day.format('YYYY-MM-DD')}
              date={day}
              month={month}
              onClick={() => onChange?.(day)}
              selected={value == null ? false : value.isSame(day, 'day')}
              state={departureStates?.get(day.format('YYYY-MM-DD')) ?? null}
              style={{ gridRowStart: row + 3, gridColumnStart: 1 + col }}
            >
              {day.format('D')}
            </CalendarDay>
          ))}
        </Fragment>
      ))}
      {selections.map(({ row, starts, ends, start, end }) => (
        <div
          key={`${row}-${start}-${end}`} style={{
            gridRowStart: row + 3,
            gridColumnStart: start,
            gridColumnEnd: end + 1,
            gridRowEnd: 'span 1'
          }} className={twMerge(classNames('border-brand-available rounded-l rounded-r -m-1.5 -mr-2 border bg-brand-available pointer-events-none opacity-[0.15]', { 'rounded-l-3xl': starts, 'rounded-r-3xl': ends }))}
        />))}
    </div>
  )
}

import classNames from 'classnames'
import HomeIcon from '../icons/home'
import BoatBikeIcon from '../icons/boatbike'
import { Fragment, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useOptionalContext } from '../hooks/optional-context'
import { TourContext } from '../contexts/tour'
import { DepartureContext } from '../contexts/departure'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Disclosure } from '@headlessui/react'
import { ChevronDown, ChevronRight } from 'react-feather'
import { twMerge } from 'tailwind-merge'
import Container from './container'
import { WEBSITES } from '../utils/website'
import HomeInselhuepfenIcon from '../icons/home-ih'
import { useBrand, useBrandBasePath } from '../hooks/brand'
import InselhuepfenIcon from '../icons/inselhuepfen'

const STEPS = [
  'back_to_home',
  'departure_date',
  'ship',
  'cabins',
  'personal_information',
  'price_overview',
  'payment'
] as const

type StepType = typeof STEPS[number]

function StepButton ({ step, index, complete, active, action }: { index: number, step: string, complete: boolean, active: boolean, action: () => void }): JSX.Element {
  return (
    <div className={classNames('transition-all flex flex-col items-center justify-center w-16 text-sm', {
      'text-brand-primary': active || complete,
      'text-secondary': !complete && !active
    })}
    >
      <button
        className={classNames('w-8 h-8 flex items-center justify-center rounded-full', {
          'bg-brand-primary text-white': complete,
          'bg-brand-light': active,
          'bg-brand-well': !complete && !active
        })}
        onClick={action}
        disabled={!complete}
      >{index === 0 ? <div><HomeIcon className='brand-inselhuepfen:hidden' /><HomeInselhuepfenIcon className='hidden brand-inselhuepfen:block' /></div> : index}
      </button>
      <div className='mt-3 text-center'>{step}</div>
    </div>
  )
}

function StepLines ({ active, bike }: { active: boolean, bike: boolean }): JSX.Element {
  return (
    <div className='flex-grow relative'>
      <div className={classNames('absolute transition-all -right-4 -left-4 top-4 border-b border-dashed border-b-brand-primary brand-inselhuepfen:border-wavy-lines brand-inselhuepfen:h-4 brand-inselhuepfen:-mt-3', {
        'opacity-100': active,
        'opacity-0': !active
      })}
      />
      {bike && (
        <div className='absolute -right-4 -left-4 -top-1 flex justify-center text-brand-primary'>
          <InselhuepfenIcon className='hidden brand-inselhuepfen:block fill-current -translate-y-1' />
          <BoatBikeIcon className='brand-inselhuepfen:hidden' />
        </div>
      )}
    </div>
  )
}

function MobileStepButton ({ step, index, complete, active, action }: { index: number, step: string, complete: boolean, active: boolean, action: () => void }): JSX.Element {
  const handleClick = useCallback(() => {
    if (complete) {
      action()
    }
  }, [action, complete])
  return (
    <button
      disabled={!complete && !active}
      key={step} className={twMerge(classNames('flex px-4 sm:px-10 bg-white py-4 text-brand-primary items-center text-center w-full border-b border-b-secondary', {
        'text-secondary': !complete && !active
      }))} type='button' onClick={handleClick}
    >
      <div className={classNames('font-medium rounded-full w-8 h-8 grid place-items-center', {
        'bg-brand-primary text-white': complete,
        'bg-brand-light': active,
        'bg-brand-well': !complete && !active
      })}
      >{index}
      </div>
      <div className='flex-1 font-medium'>{step}</div>
      <div>
        <ChevronRight />
      </div>
    </button>
  )
}

export default function BookingProgress ({ currentStep = 3 }: { currentStep?: number }): JSX.Element {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { tour = undefined } = useOptionalContext(TourContext) ?? {}
  const { departure = undefined } = useOptionalContext(DepartureContext) ?? {}
  const params = useParams()
  const [searchParams] = useSearchParams()
  const brand = useBrand()
  const basePath = useBrandBasePath()
  const lang = useMemo(() => (i18n.resolvedLanguage ?? 'en') as ('en' | 'de' | 'nl'), [i18n.resolvedLanguage])

  const callbacks: Record<StepType, () => void> = {
    back_to_home: useCallback(() => {
      if (import.meta.env.DEV) {
        navigate(basePath + '/')
      } else {
        window.location.href = WEBSITES[brand][lang]
      }
    }, [basePath, lang, navigate, brand]),
    departure_date: useCallback(() => {
      if (departure !== undefined) {
        navigate(`${basePath}/tours/${departure.tour_id}?date=${departure.date}`)
      } else if (tour !== undefined) {
        if (params.date !== undefined) {
          navigate(`${basePath}/tours/${tour.id}?date=${params.date}`)
        } else {
          navigate(`${basePath}/tours/${tour.id}`)
        }
      }
    }, [basePath, departure, navigate, params.date, tour]),
    ship: useCallback(() => {
      if (departure !== undefined) {
        navigate(`${basePath}/tours/${departure.tour_id}/date/${departure.date}`)
      } else if (tour !== undefined) {
        navigate(`${basePath}/tours/${tour.id}`)
      }
    }, [basePath, departure, navigate, tour]),
    cabins: useCallback(() => {
      if (departure !== undefined) {
        navigate(`${basePath}/departures/${departure.id}/cabins`)
      }
    }, [basePath, departure, navigate]),
    personal_information: useCallback(() => {
      if (departure !== undefined) {
        const cabins = searchParams.get('cabins')
        if (cabins !== null) {
          const newParams = new URLSearchParams({ cabins })
          navigate(`${basePath}/departures/${departure.id}/passengers?${newParams.toString()}`)
        }
        navigate(`${basePath}/departures/${departure.id}/passengers`)
      }
    }, [basePath, departure, navigate, searchParams]),
    price_overview: () => {},
    payment: () => {}
  }

  return (
    <nav className='frame:hidden'>
      <div className='grid md:hidden relative mb-4'>
        <Disclosure>
          <Disclosure.Button className='flex bg-brand-well px-4 sm:px-10 py-4 text-brand-primary items-center'>
            <div className='bg-brand-light text-brand-primary font-medium rounded-full w-8 h-8 grid place-items-center'>{currentStep}</div>
            <div className='flex-1 font-medium'>{t(STEPS[currentStep], { ns: 'progress' })}</div>
            <div>
              <ChevronDown />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className='absolute top-full bg-white w-full z-10 shadow-xl'>
            {STEPS.map((step, index) => (
              index > 0 && (
                <MobileStepButton action={callbacks[step]} complete={currentStep > index} active={currentStep === index} key={step} step={t(step, { ns: 'progress' })} index={index} />
              )
            ))}
          </Disclosure.Panel>
        </Disclosure>
      </div>
      <Container>
        <div className='hidden md:flex justify-between my-8 items-start'>
          {STEPS.map((step, index) => (
            <Fragment key={step}>
              {index > 0 && <StepLines active={currentStep >= index} bike={currentStep === index} key={`${step}-line`} />}
              <StepButton action={callbacks[step]} complete={currentStep > index} active={currentStep === index} key={step} step={t(step, { ns: 'progress' })} index={index} />
            </Fragment>))}
        </div>
      </Container>
    </nav>
  )
}

import USregions from 'iso3166-2-db/regions/US/dispute/UN/en.json'
import CAregions from 'iso3166-2-db/regions/CA/dispute/UN/en.json'

export const STATES = {
  US: USregions,
  CA: CAregions
}

export function isValidState (state: string, country: string): boolean {
  if (country !== 'US' && country !== 'CA') {
    return false
  }
  return STATES[country].map(state => state.iso).includes(state)
}

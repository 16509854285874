import { Payment, PaymentService } from '../services/payments'
import { Query, QueryContext } from '../types/queries'
import { Market } from '../types/booking'
import { getStripe } from '../utils/stripe'
import { PaymentIntent } from '@stripe/stripe-js'

export const PaymentQueryKeys = {
  payment: (paymentId: string) => ['payments', paymentId] as const,
  createBookingPayment: (bookingId: string) => ['bookings', bookingId, 'payments'] as const,
  createPayment: (paymentId: string) => ['payments', paymentId, 'payments'] as const,
  paymentIntent: (market: Market, paymentIntentId: string, clientSecret: string) => ['payment_intents', market, paymentIntentId, clientSecret] as const
}

export async function fetchPayment ({ queryKey: [, paymentId] }: QueryContext<typeof PaymentQueryKeys.payment>): Promise<Payment | null> {
  const paymentsService = new PaymentService()
  try {
    return await paymentsService.getPayment(paymentId)
  } catch (e: any) {
    if (e.response?.status === 404) {
      return null
    }
    throw e
  }
}

export async function fetchPaymentIntent ({ queryKey: [,market, paymentIntentId, clientSecret] }: QueryContext<typeof PaymentQueryKeys.paymentIntent>): Promise<PaymentIntent | null> {
  if (paymentIntentId === '' || clientSecret === '') {
    return null
  }
  const stripe = await getStripe(market)
  if (stripe === null) {
    return null
  }
  const result = await stripe.retrievePaymentIntent(clientSecret)
  return result.paymentIntent ?? null
}

export const PaymentQuery = (paymentId: string): Query<typeof PaymentQueryKeys.payment, typeof fetchPayment> => ({
  queryKey: PaymentQueryKeys.payment(paymentId),
  queryFn: fetchPayment,
  retry: false
})

export const PaymentIntentQuery = (market: Market, paymentIntentId: string | null, clientSecret: string | null): Query<typeof PaymentQueryKeys.paymentIntent, typeof fetchPaymentIntent> => ({
  queryKey: PaymentQueryKeys.paymentIntent(market, paymentIntentId ?? '', clientSecret ?? ''),
  queryFn: fetchPaymentIntent,
  retry: false
})

import { PropsWithChildren, RefObject, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { usePopper } from 'react-popper'
import { twMerge } from 'tailwind-merge'

export function Tooltip ({ anchor = 'top', className, childRef, children }: PropsWithChildren<{
  anchor?: 'top' | 'bottom'
  className?: string
  childRef?: RefObject<HTMLDivElement>
}>): JSX.Element {
  return (
    <div className={classNames('absolute left-[50%] -translate-x-1/2 z-10', {
      'bottom-full': anchor === 'top',
      'top-full': anchor === 'bottom'
    }, className)}
    >
      <div
        className={classNames('block w-4 h-4 bg-brand-primary absolute left-1/2 -translate-x-1/2 rotate-45 -z-10', {
          'top-2': anchor === 'bottom',
          'bottom-2': anchor === 'top'
        })}
      />
      <div
        className={classNames('my-4 py-2 px-4 rounded text-white bg-brand-primary')}
        ref={childRef}
      >{children}
      </div>
    </div>
  )
}

export function PopperTooltip ({ className, referenceElement, resizeRef, children }: PropsWithChildren<{
  className?: string
  referenceElement: RefObject<HTMLElement>
  resizeRef?: RefObject<HTMLElement>
}>): JSX.Element {
  const childRef = useRef<HTMLDivElement>(null)
  const { styles, attributes, forceUpdate } = usePopper(referenceElement.current, childRef.current, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'arrow',
        options: {
          padding: 8,
          element: '[data-popper-arrow]'
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      },
      {
        name: 'flip',
        options: {
          rootBoundary: 'document'
        }
      }
    ]
  })

  useEffect(() => {
    const update = (): void => forceUpdate?.()
    const controller = new AbortController()
    window.addEventListener('focus', update, { signal: controller.signal })
    window.addEventListener('resize', update, { signal: controller.signal })

    return () => controller.abort()
  }, [forceUpdate])

  useEffect(() => {
    if (resizeRef === undefined || resizeRef.current === null) return () => {}

    const observer = new ResizeObserver(() => {
      forceUpdate?.()
    })
    observer.observe(resizeRef.current)

    return () => observer.disconnect()
  }, [forceUpdate, resizeRef])

  return (
    <div
      style={styles.popper}
      ref={childRef}
      {...attributes.popper}
      className={twMerge(classNames('group z-10 bg-brand-primary text-white px-4 py-2 rounded text-base max-w-md md:max-w-xl', className))}
    >
      <div
        className={classNames([
          'border-8 border-x-transparent border-y-brand-primary',
          'group-[[data-popper-placement="top"]]:border-b-0 group-[[data-popper-placement="top"]]:-bottom-2',
          'group-[[data-popper-placement="bottom"]]:border-t-0 group-[[data-popper-placement="bottom"]]:-top-2'
        ])} data-popper-arrow style={styles.arrow}
      />
      {children}
    </div>
  )
}

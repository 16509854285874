import { useCabinPricing } from '../../hooks/cabin-selection'
import { useAllVouchers, useDiscounts } from '../../hooks/vouchers'
import { Departure, Voucher } from '../../services/departures'
import { CabinSelection } from '../../types/cabin-selection'
import SummaryBlock from './block'
import { useTranslation } from 'react-i18next'
import { useCurrencyFormatter } from '../../hooks/formatter'
import { Disclosure } from '@headlessui/react'
import { ChevronDown, ChevronRight } from 'react-feather'

export default function VoucherBlock ({ departure, cabinSelection, voucher }: { departure?: Departure, cabinSelection: CabinSelection, voucher?: Voucher | null }): JSX.Element {
  const { totalCabinPrice, totalPassengers } = useCabinPricing({ cabinSelection })
  const vouchers = useAllVouchers(departure, voucher)
  const { discount, discountLines } = useDiscounts(totalCabinPrice, totalPassengers, vouchers)
  const { t } = useTranslation()
  const formatter = useCurrencyFormatter()

  return (
    <SummaryBlock className='flex-col gap-2'>
      <Disclosure>
        <Disclosure.Button className='flex gap-8 justify-between'>
          {({ open }) => (
            <>
              <span className='inline-flex gap-1'>
                {open ? <ChevronDown className='text-brand-primary' /> : <ChevronRight className='text-brand-primary' />}
                <strong>{t('discount', 'Discount')}</strong>
              </span>
              <strong className='text-brand-discount text-right'>{formatter.format(-discount)}</strong>
            </>
          )}
        </Disclosure.Button>
        <Disclosure.Panel>
          <ul className='flex flex-col gap-4 my-4'>
            {discountLines.map(({ voucher, amount }) => (
              <li key={voucher.code} className='flex-row'>
                <div className='flex gap-8 justify-between'>
                  <div>{voucher === departure?.voucher ? (voucher.discount_type === 'Discount Percentage' ? `${voucher.percentage}%` : t('price_per_person', { price: `${formatter.format(voucher.amount)}` })) : t('current_voucher_code', 'Code: {{ code}}', { code: voucher?.code })}</div>
                  <div className='text-brand-discount text-right'>{formatter.format(-amount)}</div>
                </div>
                {voucher.description != null && <p><i>{voucher.description}</i></p>}
              </li>
            ))}
          </ul>
        </Disclosure.Panel>
      </Disclosure>
    </SummaryBlock>
  )
}

export function VoucherIndicationBlock ({ voucher }: { voucher: Voucher }): JSX.Element | undefined {
  const { t } = useTranslation()
  const formatter = useCurrencyFormatter()

  if (voucher == null) {
    return undefined
  }
  return <SummaryBlock label={t('discount', 'Discount')} value={<span className='text-brand-discount'>{(voucher.discount_type === 'Discount Percentage' ? `${voucher.percentage}%` : t('price_per_person', { price: `${formatter.format(-voucher.amount)}` }))}</span>} />
}

import { Product } from '../services/departures'
import { useCallback, useContext, useDebugValue, useMemo } from 'react'
import { ProductsContext } from '../contexts/products'
import { SerializedPassengerInformation } from '../types/passenger-information'
import { priceMultiplier } from '../utils/products'
import { PassengersContext } from '../contexts/passengers'
import { DepartureContext } from '../contexts/departure'
import { CabinsContext } from '../contexts/cabins'
import { useCabinPricing, useFullCabinSelection } from './cabin-selection'
import { VoucherContext } from '../contexts/voucher'
import { useAllVouchers, useDiscounts } from './vouchers'

interface BaseProductsHookOptions {
  localCost?: boolean
  days: number
}

interface PassengerProductsHookOptions extends BaseProductsHookOptions {
  passengerInformation: Omit<SerializedPassengerInformation, 'main'>
  bikes?: boolean
  diets?: boolean
  other?: boolean
}

interface AdditionProductsHookOptions extends BaseProductsHookOptions {
  additionalProducts: Record<string, number | string>
}

type PriceTotalOptions = Partial<AdditionProductsHookOptions> & Partial<PassengerProductsHookOptions> & {
  cabins?: boolean
  discount?: boolean
}

interface PriceTotalResponse {
  numberOfPassengers: number
  passengerProductsTotalPrice: number
  additionalProductsTotalPrice: number
  totalCabinPrice: number
  localCosts: number
  totalPrice: number
}

interface PassengerProductsHookReturn {
  passengerProductIds: string[]
  groupedPassengerProducts: Map<Product, number>
  passengerProductEntries: Array<[Product, number]>
  passengerProductsTotalPrice: number
  passengerProductsLocalCosts: number
}

interface AdditionalProductsHookReturn {
  additionalProductEntries: Array<[Product, number]>
  additionalProductsTotalPrice: number
  additionalProductsLocalCosts: number
}

export function usePassengerProducts ({ passengerInformation, bikes = true, diets = true, other = true, localCost, days }: PassengerProductsHookOptions): PassengerProductsHookReturn {
  const { productMap } = useContext(ProductsContext)

  const localCostIds = useMemo(() => Array.from(productMap.values()).filter(product => product.local_cost).map(product => product.id), [productMap])
  const productFilter = useCallback((productId: string) => productId !== undefined && (localCost === undefined || localCostIds.includes(productId) === localCost), [localCost, localCostIds])

  const passengerProductIds = useMemo(() => {
    let ids: string[] = []
    if (bikes) {
      const bikeIds = passengerInformation.passengers.map(passenger => passenger.bike).filter(productFilter)
      ids = ids.concat(bikeIds)
    }

    if (diets) {
      const dietIds = passengerInformation.passengers.map(passenger => passenger.diet).filter(productFilter)
      ids = ids.concat(dietIds)
    }

    if (other) {
      const otherIds = passengerInformation.passengers.flatMap(passenger => passenger.other).filter(productFilter)
      ids = ids.concat(otherIds)
    }

    return ids
  }, [bikes, diets, other, passengerInformation.passengers, productFilter])

  const groupedPassengerProducts = useMemo(() => {
    const map = new Map<Product, number>()

    passengerProductIds.forEach(id => {
      const product = productMap.get(id)

      if (product !== undefined) {
        map.set(product, (map.get(product) ?? 0) + 1)
      }
    })

    return map
  }, [passengerProductIds, productMap])

  const passengerProductEntries = useMemo(() => {
    return Array.from(groupedPassengerProducts.entries()).sort(([productA], [productB]) => {
      return (productA.base_name ?? productA.name).localeCompare(productB.base_name ?? productB.name)
    })
  }, [groupedPassengerProducts])

  const passengerProductsLocalCosts = useMemo(() => {
    return Array.from(groupedPassengerProducts.entries())
      .filter(([product]) => product.local_cost)
      .map(([product, count]) => product.unit_price * count * priceMultiplier(product.price_calculation, days))
      .reduce((a, b) => a + b, 0)
  }, [days, groupedPassengerProducts])

  const passengerProductsTotalPrice = useMemo(() => {
    return Array.from(groupedPassengerProducts.entries())
      .map(([product, count]) => product.unit_price * count * priceMultiplier(product.price_calculation, days))
      .reduce((a, b) => a + b, 0)
  }, [days, groupedPassengerProducts])

  return {
    passengerProductIds,
    groupedPassengerProducts,
    passengerProductEntries,
    passengerProductsTotalPrice,
    passengerProductsLocalCosts
  }
}

export function useAdditionalProducts ({ additionalProducts, days, localCost }: AdditionProductsHookOptions): AdditionalProductsHookReturn {
  const { productMap } = useContext(ProductsContext)

  const productFilter = useCallback(([product, amount]: [Product | undefined, number]) => product !== undefined && amount > 0 && (localCost === undefined || product.local_cost === localCost), [localCost])

  const allProductEntries: Array<[Product | undefined, number]> = useMemo(() => Object.entries(additionalProducts).map(([product, amount]) => {
    return [productMap.get(product), typeof amount === 'string' ? parseInt(amount, 10) : amount]
  }), [additionalProducts, productMap])

  const additionalProductEntries = useMemo(() => allProductEntries.filter(productFilter) as Array<[Product, number]>, [allProductEntries, productFilter])

  const additionalProductsLocalCosts = useMemo(() => {
    return additionalProductEntries.filter(([product]) => product.local_cost).map(([product, amount]) => product.unit_price * amount * priceMultiplier(product.price_calculation, days)).reduce((a, b) => a + b, 0)
  }, [additionalProductEntries, days])

  const additionalProductsTotalPrice = useMemo(() => {
    return additionalProductEntries.map(([product, amount]) => product.unit_price * amount * priceMultiplier(product.price_calculation, days)).reduce((a, b) => a + b, 0)
  }, [additionalProductEntries, days])

  return {
    additionalProductEntries,
    additionalProductsLocalCosts,
    additionalProductsTotalPrice
  }
}

export function usePriceTotal ({ cabins = false, discount: calculateDiscount = false, additionalProducts, passengerInformation, bikes, diets, other, days, localCost }: PriceTotalOptions): PriceTotalResponse {
  const { validated } = useContext(PassengersContext)
  const { departure } = useContext(DepartureContext)

  const { cabinTypeIds = [] } = useContext(CabinsContext)
  const cabinSelection = useFullCabinSelection(cabinTypeIds)

  const { totalCabinPrice, totalPassengers: numberOfPassengers } = useCabinPricing({ cabinSelection })

  const { passengerProductsTotalPrice, passengerProductsLocalCosts } = usePassengerProducts({
    passengerInformation: passengerInformation ?? validated ?? { passengers: [] }, bikes, diets, other, localCost, days: days ?? departure.duration
  })

  const { additionalProductsTotalPrice, additionalProductsLocalCosts } = useAdditionalProducts({
    additionalProducts: additionalProducts ?? {}, days: days ?? departure.duration, localCost
  })

  const { voucher } = useContext(VoucherContext)
  const vouchers = useAllVouchers(departure, voucher)
  const { discount } = useDiscounts(totalCabinPrice, numberOfPassengers, vouchers)

  const cabinPrice = cabins ? totalCabinPrice : 0
  const discountAmount = calculateDiscount ? discount : 0

  useDebugValue({ voucher, discount, totalCabinPrice, additionalProductsTotalPrice, passengerProductsTotalPrice, numberOfPassengers })
  // useMemo to return the same object reference if nothing changed
  return useMemo(() => ({
    numberOfPassengers,
    totalCabinPrice: cabinPrice,
    passengerProductsTotalPrice,
    additionalProductsTotalPrice,
    localCosts: additionalProductsLocalCosts + passengerProductsLocalCosts,
    discountAmount,
    totalPrice: passengerProductsTotalPrice + additionalProductsTotalPrice + cabinPrice - discountAmount
  }), [additionalProductsLocalCosts, additionalProductsTotalPrice, cabinPrice, discountAmount, numberOfPassengers, passengerProductsLocalCosts, passengerProductsTotalPrice])
}

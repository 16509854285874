import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ErrorBoundary from './error-boundary'
import './i18n'
import CabinSelectionProvider from './contexts/cabin-selection'
import PassengersProvider from './contexts/passengers'
import VoucherCodeProvider from './contexts/voucher-code'
import AdditionalProductsProvider from './contexts/additional-products'
import RecentBookingProvider from './contexts/recent-booking'
import { loadGtm } from './utils/gtm'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <CabinSelectionProvider>
          <VoucherCodeProvider>
            <PassengersProvider>
              <AdditionalProductsProvider>
                <RecentBookingProvider>
                  <App />
                </RecentBookingProvider>
              </AdditionalProductsProvider>
            </PassengersProvider>
          </VoucherCodeProvider>
        </CabinSelectionProvider>
        <ReactQueryDevtools initialIsOpen={false} toggleButtonProps={{ style: { paddingBottom: '2rem' } }} />
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>
)

const gtmId = import.meta.env.VITE_GTM
if (gtmId != null && gtmId !== '') {
  loadGtm(gtmId)
}

import Header from '../components/header'
import { PropsWithChildren, useMemo } from 'react'
import { useRouteError } from 'react-router-dom'

interface RouterError {
  internal: boolean
  status: number
  data: string
}

export default function ErrorPage (props: PropsWithChildren<{}>): JSX.Element {
  const error = useRouteError()

  const errorMessage = useMemo(() => {
    if (typeof error !== 'object' || error == null) {
      return null
    }

    if ((error as RouterError).internal) {
      switch ((error as RouterError).status) {
        case 404:
          return 'Page not found'
        default:
          return (error as RouterError).data
      }
    }

    if (error instanceof Error) {
      return error.message
    }

    return 'Unknown error'
  }, [error])

  return (
    <div className='flex flex-col h-full'>
      <Header />
      <main className='mx-auto container max-w-[1152px] pt-8'>
        <h1 className='text-3xl'>Error</h1>
        <p className='my-4 text-xl'>{errorMessage}</p>
      </main>
    </div>
  )
}

import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { PopperTooltip } from './tooltip'
import { PropsWithChildren, RefObject, useRef } from 'react'
import classNames from 'classnames'

function LegendItem ({ description, className, children, resizeRef }: PropsWithChildren<{ className?: string, description?: string, resizeRef?: RefObject<HTMLElement> }>): JSX.Element {
  const itemRef = useRef<HTMLDivElement>(null)
  return (
    <div className={twMerge(classNames('item inline-flex items-center gap-2', className))} ref={itemRef}>
      {children}
      {description !== undefined && description !== '' && <PopperTooltip referenceElement={itemRef} resizeRef={resizeRef} className='tooltip'>{description}</PopperTooltip>}
    </div>
  )
}

export default function CalendarLegend ({ className }: { className?: string }): JSX.Element {
  const { t } = useTranslation()
  const legendRef = useRef<HTMLDivElement>(null)
  return (
    <div className={twMerge('inline-flex items-center mt-8 lg:mb-24 text-sm gap-8 flex-wrap', className)} ref={legendRef}>
      <LegendItem className='available hidden group-has-[.single_.available]:inline-flex @dc:group-has-[.double_.available]:inline-flex'>
        <div className='inline-block w-4 h-4 rounded-full bg-brand-available' />
        {t('available', 'Available')}
      </LegendItem>
      <LegendItem className='unavailable hidden group-has-[.single_.unavailable]:inline-flex @dc:group-has-[.double_.unavailable]:inline-flex'>
        <div
          className='inline-block w-4 h-4 rounded-full bg-brand-unavailable opacity-60'
        />
        {t('unavailable', 'Unavailable')}
      </LegendItem>
      <LegendItem className='on-request hidden group-has-[.single_.on-request]:inline-flex @dc:group-has-[.double_.on-request]:inline-flex' description={t('on_request_tooltip', 'Tooltip about on-request')} resizeRef={legendRef}>
        <div
          className='inline-block w-4 h-4 rounded-full border-brand-primary border-dashed border'
        />
        <span className='decoration-dotted underline'>{t('on_request', 'On request')}</span>
      </LegendItem>
      <LegendItem className='discounted hidden group-has-[.single_.discounted]:inline-flex @dc:group-has-[.double_.discounted]:inline-flex' description={t('discount_tooltip', 'Tooltip about discount')} resizeRef={legendRef}>
        <div className='inline-block w-4 h-4 rounded-full bg-brand-discount' />
        <span className='decoration-dotted underline'>{t('discount', 'Discount')}</span>
      </LegendItem>
    </div>
  )
}

import BookingProgress from '../components/booking-progress'
import PriceOverview from '../components/price-overview'
import { useCreateBooking } from '../hooks/booking'
import { createRef, useCallback, useContext, useMemo, useState } from 'react'
import * as yup from 'yup'
import { PassengersContext } from '../contexts/passengers'
import { DepartureContext } from '../contexts/departure'
import TwoColumnLayout from '../layouts/columns'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Terms from '../components/terms'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Market, TermsData } from '../types/booking'
import { VoucherContext } from '../contexts/voucher'
import AdditionalProducts from '../components/additional-products'
import { ProductsContext } from '../contexts/products'
import {
  useFullCabinSelection,
  useNumberOfPassengers
} from '../hooks/cabin-selection'
import { CabinsContext } from '../contexts/cabins'
import { AutoSaveValues } from '../components/auto-save-values'
import { AdditionalProductsContext } from '../contexts/additional-products'
import Loader from '../components/loader'
import Sidebar from '../components/sidebar'
import { usePurchaseEvent } from '../hooks/tag-manager'
import AdditionalProductsTagManager
  from '../components/additional-products-tag-manager'
import { RecentBookingContext } from '../contexts/recent-booking'
import { useBrand, useBrandBasePath } from '../hooks/brand'
import InsuranceProducts from '../components/insurance-products'
import { OverviewValues } from '../types/overview'
import { InsurancePolicy, ProductsResponse } from '../services/departures'
import AutoScrollToError from '../components/auto-scroll-to-error'
import { InsuranceOptions } from '../types/insurance'

const RECENT_BOOKING_INTERVAL = 1000 * 60 * 60 * 4 // 4 hours

function generateUUID (): string {
  if (typeof crypto.randomUUID === 'function') {
    return crypto.randomUUID()
  }
  const blobURL = URL.createObjectURL(new Blob(['dummy']))
  URL.revokeObjectURL(blobURL)
  return blobURL.substring(blobURL.length - 36)
}

function useOverviewSchema ({ products, market }: { products: ProductsResponse, market: Market }): yup.Schema {
  const { t } = useTranslation()
  const validInsurancePolicies = useMemo(() => products.insurance_policies.filter(policy => policy.market === market), [market, products.insurance_policies])
  return useMemo(() => {
    const termsError = t('terms:accept_message', 'Please accept the terms for your travel.')
    const insuranceTermsError = t('insurance:accept_message', 'Please accept the terms for your insurance.')
    const baseTerms = market === 'north_america' ? ['general_north_america', 'payment_north_america'] as const : ['general', 'consumer', 'payment'] as const
    const insuranceTypes = validInsurancePolicies.map((policy): InsurancePolicy['insurance_type'] => policy.insurance_type)
    const baseTermEntries: Array<[string, yup.Schema]> = baseTerms.map(term => [term, yup.boolean().required(termsError).oneOf([true], termsError)])
    const insuranceTermEntries: Array<[string, yup.Schema]> = insuranceTypes.map(type => [
      type,
      yup.boolean().required(insuranceTermsError).test('terms', insuranceTermsError, (value, testContext) => {
        console.log({
          insuranceValue: testContext.from?.[1]?.value?.insurance?.[type]
        })
        if (testContext.from?.[1]?.value?.insurance?.[type] === true) {
          return value
        }
        return true
      })
    ])
    console.log({ insuranceTypes, baseTermEntries })
    return yup.object({
      insurance: yup.object(Object.fromEntries(insuranceTypes.map(type => [type, yup.boolean()]))),
      terms: yup.object(Object.fromEntries(baseTermEntries.concat(insuranceTermEntries)))
    })
  }, [market, t, validInsurancePolicies])
}

export default function BookingOverviewPage (): JSX.Element {
  const termsRef = createRef<HTMLElement>()
  const formRef = createRef<HTMLFormElement>()
  const [searchParams] = useSearchParams()
  const [idempotencyKey] = useState(() => generateUUID())
  const { departure } = useContext(DepartureContext)
  const { validated } = useContext(PassengersContext)
  const { voucher } = useContext(VoucherContext)
  const { createBooking, isLoading } = useCreateBooking()
  const { products } = useContext(ProductsContext)
  const { cabinTypeIds = [] } = useContext(CabinsContext)
  const { recentBooking, setRecentBooking } = useContext(RecentBookingContext)
  const { additionalProducts = {}, setAdditionalProducts } = useContext(AdditionalProductsContext)
  const cabinSelection = useFullCabinSelection(cabinTypeIds)
  const numberOfPassengers = useNumberOfPassengers(cabinSelection)
  const availableProducts = useMemo(() => products.other.filter(product => !product.passenger_product), [products.other])
  const navigate = useNavigate()
  const initialProducts = Object.fromEntries(availableProducts.map(product => [
    product.id,
    Math.max(product.mandatory ? 1 : 0, additionalProducts[product.id] ?? 0)
  ]))
  const initialTerms: OverviewValues['terms'] = { general: false, consumer: false, payment: false, cancellation: false, travel: false }
  const initialValues: OverviewValues = { terms: initialTerms, products: initialProducts, insurance: { cancellation: false, travel: false } }
  const [error, setError] = useState<unknown>(null)
  const { t, i18n } = useTranslation()
  const purchaseEvent = usePurchaseEvent()
  const basePath = useBrandBasePath()
  const brand = useBrand()
  const market: Market = useMemo(() => {
    if (departure.multi_market === false) {
      return 'default' as const
    }
    return ['CA', 'US'].includes(validated?.main.country ?? '-') ? 'north_america' as const : 'default' as const
  }, [departure.multi_market, validated?.main.country])
  const schema = useOverviewSchema({ products, market })

  const recentBookingMatches = useMemo(() => {
    return recentBooking !== undefined && recentBooking.departureId === departure.id && recentBooking.bookedAt > (new Date()).getTime() - RECENT_BOOKING_INTERVAL
  }, [departure.id, recentBooking])

  const handleNext = useCallback(({ insurance, terms, products }: { insurance: InsuranceOptions, terms: TermsData, products: Record<string, string | number> }) => {
    if (validated !== undefined) {
      const productList = Object.entries(products).map(([id, amount]) => [id, typeof amount === 'string' ? parseInt(amount, 10) : amount] satisfies [string, number]).filter(([, amount]) => amount > 0)
      setError(null)
      void createBooking({ departure, passengerInformation: validated, insurance, terms, voucherCode: voucher?.code, products: productList, locale: i18n.resolvedLanguage ?? 'en', brand, idempotencyKey }, {
        onSuccess: ({ id }) => {
          setRecentBooking({
            id,
            departureId: departure.id,
            bookedAt: (new Date()).getTime()
          })
          purchaseEvent({
            id,
            voucher: voucher?.code,
            cabinSelection,
            passengerInformation: validated,
            products: productList
          })
          navigate(`${basePath}/bookings/${id}/payment`)
        },
        onError: error => {
          console.error(error)
          setError(error)
        }
      })
    }
  }, [basePath, brand, cabinSelection, createBooking, departure, i18n.resolvedLanguage, idempotencyKey, navigate, purchaseEvent, setRecentBooking, validated, voucher?.code])

  const handleBack = useCallback(() => {
    navigate(`${basePath}/departures/${departure.id}/passengers?${searchParams.toString()}`)
  }, [basePath, departure.id, navigate, searchParams])

  const handleSubmit = useCallback((values: OverviewValues) => {
    handleNext(values)
  }, [handleNext])

  const handleNextButton = useCallback(() => {
    if (recentBookingMatches) {
      if (recentBooking !== undefined) {
        navigate(`${basePath}/bookings/${recentBooking.id}/payment`)
      }
    } else {
      formRef.current?.requestSubmit()
    }
  }, [basePath, formRef, navigate, recentBooking, recentBookingMatches])

  return (
    <>
      <BookingProgress currentStep={5} />
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema}>
        {({ values: { products, insurance } }) => (
          <Form ref={formRef}>
            <AdditionalProductsTagManager />
            <AutoSaveValues
              values={products}
              save={setAdditionalProducts}
            />
            <AutoScrollToError />
            <TwoColumnLayout>
              <div>
                {!recentBookingMatches && (
                  <>
                    <AdditionalProducts numberOfPassengers={numberOfPassengers} />
                    <InsuranceProducts market={market} additionalProducts={products} />
                  </>
                )}
                <PriceOverview additionalProducts={products} insurance={insurance} market={market} />
                {!recentBookingMatches && <Terms market={market} ref={termsRef} />}
              </div>
              <Sidebar
                hideSummary
                bookingSummaryProps={{ additionalProducts: products, passengerInformation: validated }}
                onBack={recentBookingMatches ? undefined : handleBack}
                onNext={handleNextButton}
                nextDisabled={isLoading}
                nextLabel={recentBookingMatches ? t('back_to_payment', 'Back to payment') : t('book_now', 'Book now')}
                nextProps={{ tone: 'main' }}
              >
                {isLoading && <Loader description={t('creating_your_booking', 'Creating your booking')} />}
                {error !== null && (<span className='text-red-500'>{t('something_went_wrong', 'Something went wrong')}</span>)}
              </Sidebar>
            </TwoColumnLayout>
          </Form>)}
      </Formik>
    </>
  )
}

import { useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'

export default function AutoScrollToError (): JSX.Element | undefined {
  const previousSubmitting = useRef(false)
  const { isSubmitting } = useFormikContext()

  useEffect(() => {
    if (previousSubmitting.current && !isSubmitting) {
      const errorElements: HTMLElement[] = Array.from(document.querySelectorAll('.has-error'))
      const firstErrorField = errorElements.sort((a, b) => a.offsetTop - b.offsetTop)[0]
      if (firstErrorField !== undefined) {
        firstErrorField.scrollIntoView({ behavior: 'smooth' })
      }
    }

    previousSubmitting.current = isSubmitting
  }, [isSubmitting])

  return undefined
}

import common from './common.json' assert { type: 'json' }
import field from './field.json' assert { type: 'json' }
import insurance from './insurance.json' assert { type: 'json' }
import payment from './payment.json' assert { type: 'json' }
import progress from './progress.json' assert { type: 'json' }
import questions from './questions.json' assert { type: 'json' }
import summary from './summary.json' assert { type: 'json' }
import terms from './terms.json' assert { type: 'json' }
import widget from './widget.json' assert { type: 'json' }

export default {
  common,
  field,
  insurance,
  payment,
  progress,
  questions,
  summary,
  terms,
  widget
}

import {
  Elements,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import Button from './button'
import { FormEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from './loader'
import { PaymentIntent } from '../services/payments'
import { usePaymentIntent } from '../hooks/payments'
import { Market } from '../types/booking'
import { getStripe } from '../utils/stripe'
import { useLocale } from '../hooks/locale'

interface PaymentFormOptions {
  market: Market
  redirectUri: string
  getIntent: (paymentMethodId: string) => Promise<PaymentIntent>
  billingDetails: {
    name: string
    email: string
  }
}

function PaymentForm ({ market, redirectUri, getIntent, billingDetails }: PaymentFormOptions): JSX.Element {
  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()
  const [checkoutReady, setCheckoutReady] = useState(false)
  const [currentMethod, setCurrentMethod] = useState('')

  const { mutateAsync, isLoading } = usePaymentIntent()

  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (stripe === null || elements === null) {
      return
    }
    void mutateAsync({
      elements,
      stripe,
      billingDetails,
      redirectUri,
      getIntent
    })
  }, [billingDetails, elements, getIntent, mutateAsync, redirectUri, stripe])

  const options = {
    layout: {
      type: 'tabs' as const,
      defaultCollapsed: false,
      // radios: false,
      spacedAccordionItems: false
    },
    defaultValues: {
      billingDetails: {
        name: billingDetails?.name,
        email: billingDetails?.email
      }
    },
    fields: {
      billingDetails: {
        name: 'never' as const
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {currentMethod === 'card' && market !== 'north_america' && (
        <p className='text-secondary-500 text-sm my-4'>{t('credit_card_extra_charge', 'For credit cards outside the EU a 3% transaction fee is added')}</p>
      )}
      <PaymentElement options={options} onReady={() => setCheckoutReady(true)} onChange={e => setCurrentMethod(e.value.type)} className='[&_iframe]:outline-none' />
      <div className='mt-8'>
        <Button disabled={isLoading || !checkoutReady} type='submit' tone='primary'>{t('checkout', 'Checkout')}</Button>
      </div>
      {isLoading && <Loader description={t('processing_payment', 'Processing payment')} />}
    </form>
  )
}

interface PaymentOptions {
  clientSecret?: string
  amount?: number
  redirectUri: string
  getIntent: (paymentMethodId: string) => Promise<PaymentIntent>
  market: Market
  billingDetails: {
    name: string
    email: string
  }
}

export default function Payment ({ market, amount, redirectUri, getIntent, billingDetails }: PaymentOptions): JSX.Element {
  const locale = useLocale()
  const elementOptions = useMemo(() => {
    return {
      locale,
      amount,
      currency: market === 'north_america' ? 'usd' : 'eur',
      mode: 'payment' as const,
      paymentMethodCreation: 'manual' as const
    }
  }, [amount, locale, market])
  const stripe = getStripe(market)
  return (
    <Elements stripe={stripe} options={elementOptions}>
      <PaymentForm redirectUri={redirectUri} getIntent={getIntent} billingDetails={billingDetails} market={market} />
    </Elements>
  )
}

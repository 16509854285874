import { JSX, useContext, useMemo } from 'react'
import { ProductsContext } from '../contexts/products'
import { useTranslation } from 'react-i18next'
import { Product } from '../services/departures'
import StepperInput from './stepper-input'
import { Field, FieldProps } from 'formik'
import { useCurrencyFormatter } from '../hooks/formatter'
import { DepartureContext } from '../contexts/departure'
import InformationIcon from './information-icon'
import { priceMultiplier } from '../utils/products'

function StepperFieldInput ({
  field,
  min, max
}: FieldProps<'input'> & { min: number, max: number }): JSX.Element {
  return (
    <StepperInput block min={min} max={max} {...field} />
  )
}

function ProductLine ({ numberOfPassengers, product, days }: { product: Product, numberOfPassengers: number, days: number }): JSX.Element {
  const formatter = useCurrencyFormatter()
  return (
    <>
      <div><Field name={`products.${product.id}`} component={StepperFieldInput} max={numberOfPassengers} min={0} /></div>
      <div className='flex-1'>

        <span className='inline-flex items-center'>
          {product.name}
          {product.description !== undefined && <InformationIcon description={product.description} />}
        </span>
      </div>
      <div>{formatter.format(product.unit_price * priceMultiplier(product.price_calculation, days))}</div>
    </>
  )
}

export default function AdditionalProducts ({ numberOfPassengers }: { numberOfPassengers: number }): JSX.Element {
  const { products } = useContext(ProductsContext)
  const { departure } = useContext(DepartureContext)
  const { t } = useTranslation()

  const additionalProducts = useMemo(() => {
    return products.other.filter(product => !product.passenger_product)
  }, [products.other])

  if (additionalProducts.length === 0) {
    return (<></>)
  }

  return (
    <>
      <h2 className='text-brand-primary text-lg font-bold mb-4'>{t('additional_products', 'Additional products')}</h2>
      <div className='bg-brand-well grid grid-cols-[auto_1fr_auto] gap-x-6 gap-y-10 p-6 text-xs mb-4'>
        <div className='font-bold text-brand-primary'>{t('number_of_products', 'No. of products')}</div>
        <div className='font-bold text-brand-primary'>{t('product_name', 'Product name')}</div>
        <div className='font-bold text-brand-primary'>{t('price', 'Price')}</div>
        {additionalProducts.map(product => (
          <ProductLine days={departure.duration} product={product} key={product.id} numberOfPassengers={numberOfPassengers} />))}
      </div>
    </>
  )
}

import { useEffect, useRef } from 'react'
import { useResizeObserver } from 'usehooks-ts'

export function useSyncFrameSize (): void {
  const ref = useRef<HTMLElement>(document.body)
  useResizeObserver({
    ref,
    box: 'border-box',
    onResize: ({ height }) => {
      if (window.parent !== window && height !== undefined) {
        window.parent.postMessage({ type: 'resize', height }, '*')
      }
    }
  })

  useEffect(() => {
    if (window.parent !== window) {
      window.parent.postMessage({ type: 'resize', height: document.body.scrollHeight }, '*')
    }
  }, [])
}

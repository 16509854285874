import { createContext, PropsWithChildren, useMemo } from 'react'
import { useStorageState } from '../hooks/storage-state'
import { AdditionalProducts } from '../types/products'

interface AdditionalProductsContextType {
  additionalProducts: AdditionalProducts | undefined
  setAdditionalProducts: (value: AdditionalProducts | undefined) => void
}

const nope = (): never => { throw new Error('You need a provider to use this context') }

const initial: AdditionalProductsContextType = {
  additionalProducts: undefined,
  setAdditionalProducts: nope
}
export const AdditionalProductsContext = createContext(initial)

export default function AdditionalProductsProvider ({ children }: PropsWithChildren<{}>): JSX.Element {
  const [additionalProducts, setAdditionalProducts] = useStorageState<AdditionalProducts>('additional-products')

  const value = useMemo(() => {
    return {
      additionalProducts, setAdditionalProducts
    }
  }, [additionalProducts, setAdditionalProducts])
  return <AdditionalProductsContext.Provider value={value}>{children}</AdditionalProductsContext.Provider>
}

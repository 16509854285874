import { QueryFunctionContext } from '@tanstack/react-query'
import DepartureService, { Voucher } from '../services/departures'
import { Query } from '../types/queries'

export const VoucherQueryKeys = {
  get: (departureId: string, voucherCode: string) => ['departures', departureId, 'voucher', voucherCode] as const
}

async function fetchVoucher ({ queryKey: [,departureId, , voucherCode] }: QueryFunctionContext<ReturnType<typeof VoucherQueryKeys.get>>): Promise<Voucher | null> {
  const departureService = new DepartureService()
  if (departureId === '') {
    return null
  }
  if (voucherCode === '') {
    return null
  }
  return await departureService.voucher(departureId, voucherCode)
}

const VoucherQuery = (departureId: string | undefined, voucherCode: string | undefined): Query<typeof VoucherQueryKeys.get, typeof fetchVoucher> => ({
  queryKey: VoucherQueryKeys.get(departureId ?? '', voucherCode ?? ''),
  queryFn: fetchVoucher,
  retry: false,
  staleTime: 60 * 1000 * 10,
  enabled: departureId !== undefined && voucherCode !== undefined
})

export const VoucherQueries = {
  get: VoucherQuery
}

import BookingProgress from '../components/booking-progress'
import TwoColumnLayout from '../layouts/columns'
import { useCallback, useContext, useMemo, useState } from 'react'
import Payment from '../components/payment'
import { BookingContext } from '../contexts/booking'
import { useCurrencyFormatter } from '../hooks/formatter'
import { useTranslation } from 'react-i18next'
import Button from '../components/button'
import { PaymentService } from '../services/payments'
import { Navigate } from 'react-router-dom'
import { useBrandBasePath } from '../hooks/brand'

type PaymentType = 'deposit' | 'full_payment'

export default function BookingPayment (): JSX.Element {
  const { booking } = useContext(BookingContext)
  const formatter = useCurrencyFormatter()
  const usdFormatter = useCurrencyFormatter('USD')
  const { t } = useTranslation()
  const brandBasePath = useBrandBasePath()
  const [paymentType, setPaymentType] = useState<PaymentType>(booking.deposit === null ? 'full_payment' : 'deposit')

  const swapType = useCallback(() => {
    setPaymentType(paymentType === 'deposit' ? 'full_payment' : 'deposit')
  }, [paymentType])

  const paymentAmount = useMemo(() =>
    (
      paymentType === 'deposit' ? (booking.deposit ?? booking.full_amount) : booking.full_amount
    ) +
      booking.insurance_amount
  , [booking.deposit, booking.full_amount, booking.insurance_amount, paymentType])
  const fullAmount = useMemo(() => booking.full_amount + booking.insurance_amount, [booking.full_amount, booking.insurance_amount])
  const redirectUri = useMemo(() => `${brandBasePath}/bookings/${booking.id}/confirmed`,
    [booking.id, brandBasePath])

  const getIntent = useCallback(async (paymentMethodId: string) => {
    const paymentService = new PaymentService()
    return await paymentService.createBookingPayment(booking.id, paymentMethodId, paymentType)
  }, [booking.id, paymentType])

  if (booking.status !== 'completed' || !booking.can_pay) {
    return <Navigate to={redirectUri} />
  }

  return (
    <>
      <BookingProgress currentStep={6} />
      <TwoColumnLayout>
        <div className='pb-8'>
          <h2 className='text-brand-primary font-bold my-8 text-lg'>{t('payment:title', 'Thank you for booking with us!')}</h2>
          <p className='my-3'>{t('payment:description', 'To confirm your booking, please proceed with the upfront payment.')}</p>
          <dl className='my-3 grid grid-cols-[1fr_auto] mb-8 gap-4 bg-brand-well max-w-xl p-4 font-bold rounded'>
            <dt>{t('payment:total_amount', 'Total booking amount')}:</dt>
            <dd className='text-brand-primary font-bold text-right'>{formatter.format(booking.amount)}</dd>
            <dt>{t('payment:local_costs', 'Local costs (to be paid at departure)')}:</dt>
            <dd className='text-brand-primary font-bold text-right'>{formatter.format(-booking.local_costs)}</dd>
            {booking.insurance_amount !== 0 && (
              <>
                <dt>{t('insurance', 'Insurance')}:</dt>
                <dd className='text-brand-primary font-bold text-right'>{formatter.format(booking.insurance_amount)}</dd>
              </>
            )}
            <hr className='col-span-2 border-brand-well-dark' />
            {booking.deposit !== null
              ? (
                <>
                  <dt>{paymentType === 'deposit' ? t('payment:deposit', 'Amount to pay now (deposit)') : t('payment:full_amount', 'Amount to pay now (full amount)')}:</dt>
                  <dd className='text-brand-primary font-bold text-right'>{formatter.format(paymentAmount)}</dd>
                  <dd className='text-right col-span-2'>
                    <Button outline onClick={swapType}>
                      {paymentType === 'deposit' ? t('payment:pay_full_amount', 'Pay full amount instead') : t('payment:pay_deposit', 'Pay deposit instead')}
                    </Button>
                  </dd>
                  {booking.market === 'north_america' && (
                    <>
                      <dt>{t('payment:estimated_amount_in_dollars', 'Estimated amount to pay in USD')}:</dt>
                      <dd className='text-brand-primary font-bold text-right'>{usdFormatter.format(paymentAmount * booking.exchange_rate)}</dd>
                    </>
                  )}
                </>
                )
              : (
                <>
                  <dt>{t('payment:full_amount', 'Amount to pay now (full amount)')}:</dt>
                  <dd className='text-brand-primary font-bold text-right'>{formatter.format(fullAmount)}</dd>
                  {booking.market === 'north_america' && (
                    <>
                      <dt>{t('payment:estimated_amount_in_dollars', 'Estimated amount to pay in USD')}:</dt>
                      <dd className='text-brand-primary font-bold text-right'>{usdFormatter.format(fullAmount * booking.exchange_rate)}</dd>
                    </>
                  )}
                </>
                )}
          </dl>
          <Payment billingDetails={{ name: booking.name, email: booking.email }} redirectUri={redirectUri} getIntent={getIntent} market={booking.market} amount={Math.round(100 * paymentAmount)} />
        </div>
      </TwoColumnLayout>
    </>
  )
}

import DOMPurify from 'dompurify'

export function cleanHTML (html: string): string {
  return DOMPurify.sanitize(html, { ALLOWED_TAGS: ['b', 'i', 'u', 'p', 'br', 'a', 'ul', 'ol', 'li'], ALLOWED_ATTR: ['href'] })
}

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if (node.tagName.toLowerCase() === 'a') {
    node.setAttribute('target', '_blank')
    node.setAttribute('rel', 'noreferer noopener')
    node.classList.add('text-brand-primary')
    node.classList.add('hover:text-brand-primary-hover')
    node.classList.add('underline')
  } else if (node.tagName.toLowerCase() === 'ul') {
    node.classList.add('list-disc')
    node.classList.add('list-inside')
  }
})

import BrandProvider, { Brand } from '../contexts/brand'
import { useState } from 'react'
import Header from '../components/header'
import { useTranslation } from 'react-i18next'

export function UnsupportedBrowser (): JSX.Element {
  const { t } = useTranslation()
  const [brand] = useState((): Brand => location.hostname.includes('inselhuepfen') || location.pathname.split('/').includes('inselhuepfen') ? 'inselhuepfen' : 'boatbiketours')

  return (
    <BrandProvider brand={brand}>
      <div className='grid' data-brand={brand}>
        <div className='flex flex-col accent-brand-primary brand-inselhuepfen:font-open-sans'>
          <Header />
          <main className='mb-16 lg:mb-8'>
            <div className='container mx-auto text-center'>
              <h1 className='text-3xl mt-8'>{t('unsupported_browser_title', 'Your browser is not supported.')}</h1>
              <p className='my-8'>{t('unsupported_browser_description', 'Please update your browser.')}</p>
            </div>
          </main>
        </div>
      </div>
    </BrandProvider>
  )
}

import { Context, createContext, PropsWithChildren } from 'react'

export type Brand = 'boatbiketours' | 'inselhuepfen'

interface BrandContextType {
  brand: Brand
}

export const BrandContext: Context<BrandContextType> = createContext<BrandContextType>({ brand: 'boatbiketours' })

export default function BrandProvider ({ brand, children }: PropsWithChildren<BrandContextType>): JSX.Element {
  return (<BrandContext.Provider value={{ brand }}>{children}</BrandContext.Provider>)
}

import { useTranslation } from 'react-i18next'
import { useDebugValue } from 'react'

const locales = ['en', 'de', 'nl'] as const
const currencies = ['EUR', 'USD'] as const

type Locale = typeof locales[number]
type Currency = typeof currencies[number]
type FormatterKey = `${Locale}-${Currency}`

const localeMap: Record<Locale, string> = {
  en: 'en-US',
  de: 'de-DE',
  nl: 'nl-NL'
}

const currencyFormatters = new Map<FormatterKey, Intl.NumberFormat>()

export function useCurrentLocale (): Locale {
  const { i18n } = useTranslation()
  return i18n.language as Locale
}

export function useCurrencyFormatter (currency: Currency = 'EUR'): Intl.NumberFormat {
  const resolvedLocale = useCurrentLocale()
  const formatterKey = `${resolvedLocale}-${currency}` as const
  let formatter = currencyFormatters.get(formatterKey)

  if (formatter == null) {
    formatter = new Intl.NumberFormat(localeMap[resolvedLocale], {
      style: 'currency',
      currency
    })
    currencyFormatters.set(formatterKey, formatter)
  }
  useDebugValue([localeMap[resolvedLocale], formatter])
  return formatter
}

import { useCallback, useContext, useState } from 'react'
import { DepartureContext } from '../contexts/departure'
import { useTranslation } from 'react-i18next'
import { VoucherContext } from '../contexts/voucher'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import DepartureService from '../services/departures'
import { VoucherCodeContext } from '../contexts/voucher-code'
import { VoucherQueryKeys } from '../queries/vouchers'

export default function VoucherEntry (): JSX.Element | undefined {
  const { departure } = useContext(DepartureContext)
  const { voucher } = useContext(VoucherContext)
  const { setVoucherCode } = useContext(VoucherCodeContext)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [isOpen, setIsOpen] = useState(false)
  const [code, setCode] = useState(voucher?.code ?? '')
  const [lastError, setLastError] = useState('')

  const { mutateAsync: applyCode, isLoading } = useMutation(async (code: string) => {
    setLastError('')
    if (code === '') {
      queryClient.setQueryData(VoucherQueryKeys.get(departure.id, code), null)
      return null
    }
    const departureService = new DepartureService()
    const voucher = await departureService.voucher(departure.id, code)
    if (departure.voucher?.discount_type === 'Discount Percentage' && voucher.discount_type === 'Discount Percentage') {
      throw new Error('Not allowed')
    }
    queryClient.setQueryData(VoucherQueryKeys.get(departure.id, code), voucher)
    return voucher
  }, {
    onSuccess: (voucher) => {
      if (voucher === null) {
        setVoucherCode('')
      } else {
        setVoucherCode(voucher.code)
      }
      setIsOpen(false)
    },
    onError: () => setLastError('Invalid voucher code')
  })

  const handleSubmit = useCallback(() => {
    void applyCode(code)
  }, [applyCode, code])

  if (isOpen) {
    return (
      <div>
        <div className='flex gap-8'>
          <div className='flex-1 text-sm'>
            <input className='w-full border-secondary-300 rounded' type='text' placeholder={t('voucher_placeholder', 'Coupon code')} onInput={(e) => setCode((e.target as HTMLInputElement).value)} value={code} />
          </div>
          <button disabled={isLoading} onClick={handleSubmit} type='button' className='text-sm text-brand-primary underline mr-4'>{t('apply', 'Apply')}</button>
        </div>
        {lastError !== '' && (
          <div>
            <span className='text-red-500 text-xs'>{t('invalid_voucher_code', 'Invalid voucher code')}</span>
          </div>)}
      </div>
    )
  }

  if (code !== '') {
    return (
      <div>
        <button
          onClick={() => {
            setCode('')
            void applyCode('')
          }} type='button' className='text-sm text-brand-primary underline'
        >{t('remove_coupon_code', 'Remove coupon code?')}
        </button>
      </div>
    )
  }

  return (
    <div>
      <button onClick={() => setIsOpen(true)} type='button' className='text-sm text-brand-primary underline'>{t('have_coupon_code', 'Coupon code?')}</button>
    </div>
  )
}

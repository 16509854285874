import TranslationsService, { BaseTranslation } from '../services/translations'
import { Query, QueryContext } from '../types/queries'

export const TranslationQueryKeys = {
  ships: () => ['translations', 'ships'] as const,
  ship: (shipId: string) => ['translations', 'ships', shipId] as const,
  cabins: (shipId: string) => ['translations', 'ships', shipId, 'cabins'] as const,
  products: () => ['translations', 'products'] as const
}

export async function fetchShipTranslations (): Promise<BaseTranslation[]> {
  const translationsService = new TranslationsService()
  return await translationsService.getShipTranslations()
}
export async function fetchProductTranslations (): Promise<BaseTranslation[]> {
  const translationsService = new TranslationsService()
  return await translationsService.getProductTranslations()
}

export async function fetchCabinTranslations ({ queryKey: [,,shipId] }: QueryContext<typeof TranslationQueryKeys.cabins>): Promise<BaseTranslation[]> {
  const translationsService = new TranslationsService()
  return await translationsService.getCabinTranslations(shipId)
}

export const ShipTranslationsQuery = (): Query<typeof TranslationQueryKeys.ships, typeof fetchShipTranslations> => ({
  queryKey: TranslationQueryKeys.ships(),
  queryFn: fetchShipTranslations,
  staleTime: 60 * 60 * 24
})

export const ProductTranslationsQuery = (): Query<typeof TranslationQueryKeys.products, typeof fetchProductTranslations> => ({
  queryKey: TranslationQueryKeys.products(),
  queryFn: fetchProductTranslations,
  staleTime: 60 * 60 * 24
})

export const CabinTranslationsQuery = (shipId: string): Query<typeof TranslationQueryKeys.cabins, typeof fetchCabinTranslations> => ({
  queryKey: TranslationQueryKeys.cabins(shipId),
  queryFn: fetchCabinTranslations,
  staleTime: 60 * 60 * 24,
  enabled: shipId !== ''
})

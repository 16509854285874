import { Brand } from '../contexts/brand'

export const WEBSITES: Record<Brand, Record<'nl' | 'en' | 'de', string>> = {
  boatbiketours: {
    nl: 'https://www.boatbiketours.nl/',
    de: 'https://www.boatbiketours.de/',
    en: 'https://www.boatbiketours.com/'
  },
  inselhuepfen: {
    nl: 'https://www.inselhuepfen.com/en',
    de: 'https://www.inselhuepfen.com/',
    en: 'https://www.inselhuepfen.com/en'
  }
}

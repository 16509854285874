import { createContext, PropsWithChildren } from 'react'
import { Departure } from '../services/departures'

export interface DepartureContextType {
  departure: Departure
}

const initialContext = {}

export const DepartureContext = createContext(initialContext as DepartureContextType)

export default function DepartureProvider ({ departure, children }: PropsWithChildren<DepartureContextType>): JSX.Element {
  return <DepartureContext.Provider value={{ departure }}>{children}</DepartureContext.Provider>
}

import { useTours } from '../hooks/tours'
import { Tour } from '../services/tours'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import BookingProgress from '../components/booking-progress'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../components/container'
import Loader from '../components/loader'
import { WEBSITES } from '../utils/website'
import { BrandContext } from '../contexts/brand'

function TourCard ({ tour }: { tour: Tour }): JSX.Element {
  const { t } = useTranslation()
  return (
    <div className='transition-colors bg-brand-well hover:bg-brand-primary hover:text-white border rounded p-4 relative'>
      <h1 className='text-xl'>
        <Link to={`./tours/${tour.id}`} className='after:absolute after:inset-0'>{tour.name}</Link>
      </h1>
      <h2>{t('x_days', '{{ count }} days', { count: tour.duration })}</h2>
    </div>
  )
}

export default function Home (): JSX.Element | undefined {
  const { tours, toursLoading } = useTours()
  const { t, i18n } = useTranslation()
  const [filter, setFilter] = useState('')
  const [searchParams] = useSearchParams()
  const { brand } = useContext(BrandContext)
  const lang = (i18n.resolvedLanguage ?? 'en') as ('en' | 'de' | 'nl')

  const filteredTours = useMemo(() => {
    const lowerFilters = filter.toLowerCase().split(/\s+/)
    return (tours ?? []).filter(tour => lowerFilters.every(lowerFilter => tour.name.toLowerCase().includes(lowerFilter)))
  }, [tours, filter])

  if (searchParams.has('tourId')) {
    const tourIds = searchParams.getAll('tourId')
    let url = `./tours/${tourIds.join(':')}`
    if (searchParams.has('lang')) {
      const params = new URLSearchParams()
      params.set('locale', searchParams.get('lang') ?? lang)
      url += '?' + params.toString()
    }
    return <Navigate to={url} />
  }

  if (!import.meta.env.DEV && !searchParams.has('all')) {
    window.location.href = WEBSITES[brand][lang]
  }

  if (toursLoading) {
    return (
      <>
        <BookingProgress currentStep={0} />
        <Container>
          <Loader />
        </Container>
      </>
    )
  }

  if (tours != null) {
    return (
      <>
        <BookingProgress currentStep={0} />
        <Container>
          <section className='p-8'>
            <form className='flex justify-center'>
              <input type='text' className='rounded block w-full md:w-1/2 focus:border-brand-primary focus:ring-brand-primary' placeholder={t('search_for_a_tour', 'Search for a tour')} value={filter} onChange={(e) => setFilter(e.target.value)} />
            </form>
          </section>
          <section className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 my-8'>
            {filteredTours.map(tour => (<TourCard key={tour.id} tour={tour} />))}
          </section>
        </Container>
      </>
    )
  }

  return undefined
}

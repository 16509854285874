import dayjs from '../libs/dayjs'
import { DepartureState, DepartureStates } from '../types/departures'
import DepartureService, {
  Cabin,
  Departure,
  DeparturePrice,
  ProductsResponse
} from '../services/departures'
import {
  QueryClient,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryResult
} from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { TourDetails } from '../services/tours'
import config from '../../settings/config.json'
import { DepartureQueries } from '../queries/departures'

export async function preloadDepartures (client: QueryClient, tourId: string, date: dayjs.Dayjs): Promise<void> {
  return await client.prefetchQuery(DepartureQueries.list(tourId, date))
}

export function usePreloadDepartures (tourId: string | undefined, date: dayjs.Dayjs | string | undefined): void {
  const client = useQueryClient()
  useEffect(() => {
    if (tourId !== undefined && date !== undefined) {
      void preloadDepartures(client, tourId, typeof date === 'string' ? dayjs(date) : date)
    }
  }, [client, date, tourId])
}

export function useDepartures (tourId: string | undefined, date: dayjs.Dayjs): UseQueryResult<Departure[]> {
  return useQuery(DepartureQueries.list(tourId, date))
}

export function useTwoMonthDepartures (tourId: string | undefined, date: dayjs.Dayjs): [UseQueryResult<Departure[]>, UseQueryResult<Departure[]>] {
  return useQueries({
    queries: [
      DepartureQueries.list(tourId, date),
      DepartureQueries.list(tourId, date.add(1, 'month'))
    ]
  })
}

export function useDeparture (departureId: string | undefined): UseQueryResult<Departure> {
  return useQuery(DepartureQueries.get(departureId))
}

export function useDeparturePrices (tourId: string | undefined): UseQueryResult<DeparturePrice[]> {
  return useQuery(['tour', tourId, 'prices'], async () => {
    const departureService = new DepartureService()
    return await departureService.tourPrices(tourId ?? '')
  }, {
    enabled: tourId !== undefined
  })
}

export function useCabins (departureId: string): UseQueryResult<Cabin[]> {
  return useQuery(DepartureQueries.cabins(departureId))
}

export function useProducts (departureId: string): UseQueryResult<ProductsResponse> {
  return useQuery(DepartureQueries.products(departureId))
}

export function useDepartureStates (departures: Departure[] | undefined, minimumDate: dayjs.Dayjs, partySize: number | null = null): DepartureStates {
  return useMemo(() => {
    const map = new Map<string, DepartureState>()
    if (departures !== undefined) {
      for (const departure of departures) {
        const state = map.get(departure.date) ?? { type: 'unavailable', discount: false }
        map.set(departure.date, state)
        if (dayjs(departure.date).isBefore(minimumDate)) {
          // Skip dates in the past
          continue
        }

        if (departure.availability != null && departure.availability.minimum_passengers <= (partySize ?? Infinity) && departure.availability.maximum_passengers >= (partySize ?? 1)) {
          if (departure.voucher != null) {
            state.discount = true
          }
          if (!departure.on_request && departure.availability.available_passengers >= (partySize ?? 1)) {
            state.type = 'available'
          } else if (state.type !== 'available') {
            state.type = 'on_request'
          }
        }
      }
    }
    return map
  }, [departures, minimumDate, partySize])
}

export function useMinMaxDate (tour: TourDetails, daysToBookInAdvance: number = config.DAYS_IN_ADVANCE): [dayjs.Dayjs, dayjs.Dayjs] {
  return useMemo(() => {
    const firstDeparture = tour.first_departure === null ? dayjs() : dayjs(tour.first_departure)
    const lastDeparture = tour.last_departure === null ? dayjs() : dayjs(tour.last_departure)
    const today = dayjs().startOf('day').add(1, 'day')
    const firstBookableDay = today.add(daysToBookInAdvance, 'day')
    return [
      firstBookableDay.isAfter(firstDeparture, 'day') ? firstBookableDay : firstDeparture,
      firstBookableDay.isAfter(lastDeparture, 'day') ? firstBookableDay : lastDeparture
    ]
  }, [daysToBookInAdvance, tour.first_departure, tour.last_departure])
}

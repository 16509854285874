import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import TourService, {
  Tour, TourDetails

} from '../services/tours'

const TourKeys = {
  all: () => ['tours', 'list'] as const,
  get: (id: string) => ['tours', id] as const
}

export function useTours (): {
  tours?: Tour[]
  toursLoading: boolean
  toursError?: unknown
} {
  const { data: tours = [], isLoading, error } = useQuery(TourKeys.all(), async () => {
    const tourService = new TourService()
    return await tourService.all()
  })
  return { tours, toursLoading: isLoading, toursError: error }
}

export function useTour (tourId?: string): UseQueryResult<TourDetails> {
  const queryClient = useQueryClient()
  return useQuery(TourKeys.get(tourId ?? 'none'), async () => {
    const tourService = new TourService()
    if (tourId !== undefined) {
      return await tourService.getTour(tourId)
    }
  }, {
    enabled: tourId !== undefined,
    initialData: () => {
      const tourData = queryClient.getQueryData<Tour[]>(TourKeys.all())
      if (tourData !== undefined) {
        const tour = tourData.find((tour) => tour.id === tourId)
        if (tour !== undefined) {
          return {
            first_departure: null,
            last_departure: null,
            first_available: null,
            id: tour.id,
            tours: [tour]
          }
        }
        return undefined
      }
    }
  })
}

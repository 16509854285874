import { CompletedBooking } from '../services/bookings'
import { createContext, PropsWithChildren } from 'react'

interface BookingContextType {
  booking: CompletedBooking
}

const initialContext = {}

export const BookingContext = createContext(initialContext as BookingContextType)

export default function BookingProvider ({ booking, children }: PropsWithChildren<{ booking: CompletedBooking }>): JSX.Element {
  return <BookingContext.Provider value={{ booking }}>{children}</BookingContext.Provider>
}

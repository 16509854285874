import { PropsWithChildren, useContext, useMemo } from 'react'
import { BookingSummaryProps } from './booking-summary'
import { DepartureContext, DepartureContextType } from '../contexts/departure'
import { TourContext } from '../contexts/tour'
import dayjs from '../libs/dayjs'
import { useCurrencyFormatter } from '../hooks/formatter'
import { useOptionalContext } from '../hooks/optional-context'
import { CabinsContext } from '../contexts/cabins'
import { VoucherContext } from '../contexts/voucher'
import { useCabinPricing, useFullCabinSelection, useNumberOfPassengers } from '../hooks/cabin-selection'
import { useAllVouchers, useDiscounts } from '../hooks/vouchers'
import { useAdditionalProducts, usePassengerProducts } from '../hooks/products'
import { ProductsContext } from '../contexts/products'
import { hasSGR } from '../utils/products'

function DepartureInfo ({ date }: Pick<BookingSummaryProps, 'date'>): JSX.Element | undefined {
  const { departure } = useContext(DepartureContext) as Partial<DepartureContextType>
  const { tour: { tours: [tour] } } = useContext(TourContext)

  const departureDate = useMemo(() => {
    if (departure === undefined) {
      if (date === undefined) {
        return undefined
      }

      const calculatedEnd = date.add(tour.duration - 1, 'days')
      return `${date.format('l')} - ${calculatedEnd.format('l')}`
    }
    const start = dayjs(departure.date)
    const end = dayjs(departure.end_date)
    return `${start.format('l')} - ${end.format('l')}`
  }, [departure, date, tour.duration])

  if (departureDate !== undefined) {
    return <div>{departureDate}</div>
  }
  return undefined
}

function PriceInfo ({ passengerInformation, additionalProducts }: Pick<BookingSummaryProps, 'additionalProducts' | 'passengerInformation'>): JSX.Element | undefined {
  const formatter = useCurrencyFormatter()
  const { cabinTypeIds = [] } = useOptionalContext(CabinsContext) ?? {}
  const { departure } = useOptionalContext(DepartureContext) ?? {}
  const { tour: { tours: [tour] } } = useContext(TourContext)
  const { voucher } = useContext(VoucherContext)
  const cabinSelection = useFullCabinSelection(cabinTypeIds)

  const { totalCabinPrice, totalPassengers } = useCabinPricing({ cabinSelection })
  const vouchers = useAllVouchers(departure, voucher)
  const { discount } = useDiscounts(totalCabinPrice, totalPassengers, vouchers)
  const { passengerProductsTotalPrice } = usePassengerProducts({
    passengerInformation: passengerInformation ?? { passengers: [] },
    bikes: true,
    diets: true,
    other: true,
    days: departure?.duration ?? tour.duration
  })
  const numberOfPassengers = useNumberOfPassengers(cabinSelection)
  const { products } = useOptionalContext(ProductsContext) ?? {}

  const sgr = products !== undefined && hasSGR(departure, passengerInformation)
  const sgrCost = sgr ? products.sgr.unit_price * numberOfPassengers : 0
  const { additionalProductsTotalPrice } = useAdditionalProducts({
    additionalProducts: additionalProducts ?? {},
    days: departure?.duration ?? tour.duration
  })

  const totalPrice = useMemo(() => sgrCost + passengerProductsTotalPrice + additionalProductsTotalPrice + totalCabinPrice - discount, [discount, passengerProductsTotalPrice, sgrCost, totalCabinPrice, additionalProductsTotalPrice])
  if (departure === undefined) {
    return undefined
  }
  if (totalPrice > 0) {
    return <div className='font-bold text-lg'>{formatter.format(totalPrice)}</div>
  }
}

export default function MobileBookingSummary ({ date, passengerInformation, additionalProducts, children }: PropsWithChildren<BookingSummaryProps>): JSX.Element {
  return (
    <div className='flex p-4 gap-4 bg-white border-t drop-shadow items-center'>
      <div className='flex-1'>
        <PriceInfo passengerInformation={passengerInformation} additionalProducts={additionalProducts} />
        <DepartureInfo date={date} />
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}

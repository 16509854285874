import { z } from 'zod'
import Service from './base'

const BaseTranslationSchema = z.object({
  id: z.string(),
  language: z.enum(['en', 'nl', 'de']),
  name: z.string().nullable(),
  description: z.string().nullable(),
  image_url: z.string().nullable().optional(),
  image_url1: z.string().nullable().optional(),
  image_url2: z.string().nullable().optional(),
  image_url3: z.string().nullable().optional()
})

const TranslationWithImageSchema = BaseTranslationSchema.extend({
  image_url: z.string().nullable(),
  image_url1: z.string().nullable(),
  image_url2: z.string().nullable(),
  image_url3: z.string().nullable()
})

export type BaseTranslation = z.infer<typeof BaseTranslationSchema>
export type TranslationWithImage = z.infer<typeof TranslationWithImageSchema>

const ArrayOfBaseTranslationSchema = z.array(BaseTranslationSchema)
const ArrayOfTranslationWithImageSchema = z.array(TranslationWithImageSchema)

export default class TranslationsService extends Service {
  async getShipTranslations (): Promise<TranslationWithImage[]> {
    const data = await this.get('/api/v1/translations/ships')
    return ArrayOfTranslationWithImageSchema.parse(data)
  }

  async getProductTranslations (): Promise<BaseTranslation[]> {
    const data = await this.get('/api/v1/translations/products')
    return ArrayOfBaseTranslationSchema.parse(data)
  }

  async getCabinTranslations (shipId: string): Promise<TranslationWithImage[]> {
    if (shipId === '') {
      return []
    }
    const data = await this.get(`/api/v1/translations/ships/${shipId}/cabins`)
    return ArrayOfTranslationWithImageSchema.parse(data)
  }
}

import { SerializedPassengerInformation } from '../../types/passenger-information'
import { useCurrencyFormatter } from '../../hooks/formatter'
import { useAdditionalProducts, usePassengerProducts } from '../../hooks/products'
import OverviewBlock from './block'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { DepartureContext } from '../../contexts/departure'
import { priceMultiplier } from '../../utils/products'

interface ProductsBlockProps {
  passengerInformation: SerializedPassengerInformation
  label: string
  bikes?: boolean
  diets?: boolean
  other?: boolean
  localCost?: boolean
  additionalProducts?: Record<string, number>
}

export function ProductsBlock ({ passengerInformation, label, localCost = undefined, bikes = false, diets = false, other = false, additionalProducts }: ProductsBlockProps): JSX.Element {
  const formatter = useCurrencyFormatter()
  const { departure } = useContext(DepartureContext)
  const { passengerProductEntries, passengerProductsTotalPrice } = usePassengerProducts({
    passengerInformation,
    bikes,
    diets,
    other,
    localCost,
    days: departure.duration
  })

  const { additionalProductEntries, additionalProductsTotalPrice } = useAdditionalProducts({
    additionalProducts: additionalProducts ?? {},
    days: departure.duration,
    localCost
  })

  return (
    <OverviewBlock className='flex-col gap-2'>
      <div className='flex justify-between gap-8'>
        <strong>{label}</strong>
        <strong>{formatter.format(passengerProductsTotalPrice + additionalProductsTotalPrice)}</strong>
      </div>

      {passengerProductEntries.map(([product, count]) => (
        <div className='flex justify-between gap-8 border-t pt-4' key={product.id}>
          <span>{count}&times; {product.base_name ?? product.name}</span>
          <span>{formatter.format(product.unit_price * priceMultiplier(product.price_calculation, departure.duration))}</span>
        </div>
      ))}

      {additionalProductEntries.map(([product, count]) => (
        <div className='flex justify-between gap-8 border-t pt-4' key={product.id}>
          <span>{count}&times; {product.base_name ?? product.name}</span>
          <span>{formatter.format(product.unit_price * priceMultiplier(product.price_calculation, departure.duration))}</span>
        </div>
      ))}

    </OverviewBlock>
  )
}
export default function ProductSection ({ passengerInformation, additionalProducts }: { passengerInformation: SerializedPassengerInformation, additionalProducts: Record<string, number> }): JSX.Element {
  const { t } = useTranslation()
  return (
    <>
      <ProductsBlock passengerInformation={passengerInformation} label={t('summary:bikes', 'Bikes')} bikes />
      <ProductsBlock passengerInformation={passengerInformation} label={t('summary:additional_products', 'Additional products')} localCost={false} diets other additionalProducts={additionalProducts} />
      <ProductsBlock passengerInformation={passengerInformation} label={t('summary:local_costs', 'Local costs')} localCost diets other additionalProducts={additionalProducts} />
    </>
  )
}

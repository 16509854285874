import { Product, ProductsResponse } from '../services/departures'
import { createContext, PropsWithChildren, useMemo } from 'react'

export interface ProductsContextType {
  products: ProductsResponse
  productMap: Map<string, Product>
}

const initialContext: ProductsContextType = {
  products: {
    bikes: [],
    diets: [],
    other: [],
    sgr: { unit_price: 0, id: '', name: 'SGR' },
    insurance_policies: []
  },
  productMap: new Map<string, Product>()
}

export const ProductsContext = createContext(initialContext)

export function useProductMap (products: ProductsResponse | undefined): Map<string, Product> {
  return useMemo(() => {
    const map = new Map<string, Product>()

    if (products !== undefined) {
      products.bikes.forEach(bike => map.set(bike.id, bike))
      products.diets.forEach(diet => map.set(diet.id, diet))
      products.other.forEach(other => map.set(other.id, other))
    }

    return map
  }, [products])
}

export default function ProductsProvider ({ products, children }: PropsWithChildren<Pick<ProductsContextType, 'products'>>): JSX.Element {
  const productMap = useProductMap(products)

  return <ProductsContext.Provider value={{ products, productMap }}>{children}</ProductsContext.Provider>
}

import { createContext, PropsWithChildren, useEffect } from 'react'

interface FrameDetectorData {
  frame: boolean
  forced: boolean
}

const initial: FrameDetectorData = { frame: window !== window.top, forced: false }

export const FrameDetectorContext = createContext(initial)

export default function FrameDetectorProvider ({ force, children }: PropsWithChildren<{ force?: boolean }>): JSX.Element {
  useEffect(() => {
    if (force === true) {
      document.documentElement.dataset.forceFrame = 'true'

      return () => {
        delete document.documentElement.dataset.forceFrame
      }
    }
  }, [force])

  return (
    <FrameDetectorContext.Provider value={{ frame: force ?? window !== window.top, forced: force !== undefined }}>
      {children}
    </FrameDetectorContext.Provider>
  )
}

import dayjs from '../libs/dayjs'
import { useMemo } from 'react'

interface UseCalendarOptions {
  /**
   * The index of the first day in a week. (Sunday: 0, Saturday: 6)
   */
  startDay: number
}

const defaultOptions: UseCalendarOptions = {
  startDay: 1
}

function amountOfDaysBetweenDayIndexes (base: number, comparison: number, backwards = true): number {
  const value = base - comparison
  return (backwards ? 7 + value : 6 - value) % 7
}

function cycle<T extends unknown[]> (array: T, offset: number): T {
  return array.slice(offset ?? 0).concat(array.slice(0, offset ?? 0)) as T
}

export function useWeekdays (options: Partial<UseCalendarOptions> = {}): dayjs.WeekdayNames {
  const parsedOptions: UseCalendarOptions = {
    ...defaultOptions,
    ...options
  }

  return useMemo(() => {
    const startDay = (parsedOptions.startDay ?? defaultOptions.startDay) % 7
    let short = dayjs.weekdaysMin()
    if (startDay !== 0) {
      short = cycle(short, startDay)
    }
    return short
  }, [parsedOptions.startDay])
}

/**
 * Passes in a date and returns an array of 7 day weeks
 * @param date
 * @param options
 */
export default function useCalendar (date: dayjs.Dayjs, options: Partial<UseCalendarOptions> = {}): dayjs.Dayjs[][] {
  const parsedOptions: UseCalendarOptions = {
    ...defaultOptions,
    ...options
  }

  return useMemo(() => {
    const daysInMonth = date.daysInMonth()

    const startDay = date.clone().date(1)
    const endDay = date.clone().date(daysInMonth)

    const startOfCalendarMonth = startDay
      .clone()
      .subtract(
        amountOfDaysBetweenDayIndexes(startDay.day(), parsedOptions.startDay, true),
        'day'
      )
    const endOfCalendarMonth = endDay
      .clone()
      .add(
        amountOfDaysBetweenDayIndexes(endDay.day(), parsedOptions.startDay, false),
        'day'
      )

    const weeks = []
    let week = []

    let workingSample = startOfCalendarMonth.clone()
    while (!workingSample.isAfter(endOfCalendarMonth)) {
      week.push(workingSample)
      workingSample = workingSample.clone().add(1, 'day')

      if (week.length === 7) {
        weeks.push(week)
        week = []
      }
    }

    return weeks
  }, [date, parsedOptions.startDay])
}

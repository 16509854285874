import { PropsWithChildren, ReactNode, useContext, useEffect } from 'react'
import BookingSummary, { BookingSummaryProps } from './booking-summary'
import Button, { ButtonProps } from './button'
import Questions from './questions'
import { useTranslation } from 'react-i18next'
import { NavigationContext } from '../contexts/navigation-context'
import MobileBookingSummary from './mobile-booking-summary'

interface SidebarProps {
  nextLabel?: ReactNode
  onNext?: () => void
  nextDisabled?: boolean
  nextProps?: Partial<ButtonProps>
  backLabel?: string
  onBack?: () => void
  backDisabled?: boolean
  backProps?: Partial<ButtonProps>
  hideSummary?: boolean
  bookingSummaryProps?: BookingSummaryProps
}

export default function Sidebar ({
  nextLabel,
  onNext,
  nextDisabled = false,
  nextProps = { tone: 'next' },
  backLabel,
  onBack,
  backDisabled = false,
  backProps = { outline: true },
  bookingSummaryProps,
  hideSummary = false,
  children
}: PropsWithChildren<SidebarProps>): JSX.Element {
  const { t } = useTranslation()
  const { setBackAction } = useContext(NavigationContext)

  useEffect(() => {
    setBackAction(() => onBack)

    return () => setBackAction(undefined)
  }, [onBack, setBackAction])

  return (
    <aside>
      {hideSummary ? <Questions /> : <BookingSummary {...bookingSummaryProps} />}
      <div className='sticky my-8 top-8'>
        {children}
        <div className='my-8'>
          <div className='hidden frame:flex lg:flex justify-between mb-8'>
            {onBack !== undefined
              ? (
                <Button
                  onClick={onBack}
                  disabled={backDisabled} {...backProps}
                >{backLabel ?? t('back', 'Back')}
                </Button>
                )
              : <span />}
            {onNext !== undefined
              ? (
                <Button
                  onClick={onNext}
                  disabled={nextDisabled} {...nextProps}
                >{nextLabel ?? t('next', 'Next')}
                </Button>)
              : <span />}
          </div>
          {!hideSummary && <Questions className='mt-8' />}
        </div>
      </div>
      <div className='fixed bottom-0 left-0 right-0 lg:hidden frame:hidden bg-white z-20'>
        {onNext !== undefined && (
          <MobileBookingSummary {...bookingSummaryProps}>
            <Button
              className='py-3 px-10 text-xl/5' onClick={onNext} {...nextProps}
              disabled={nextDisabled}
            >{nextLabel ?? t('next', 'Next')}
            </Button>
          </MobileBookingSummary>
        )}
      </div>
    </aside>
  )
}

import { Brand, BrandContext } from '../contexts/brand'
import { useContext, useMemo } from 'react'

export function useBrand (): Brand {
  const { brand } = useContext(BrandContext)
  return brand
}

export function useBrandBasePath (): '' | `/${string}` {
  const brand = useBrand()
  return useMemo(() => brand === 'inselhuepfen' ? '/inselhuepfen' : '', [brand])
}

import { useTranslation } from 'react-i18next'
import { defaultLocale, Locale, locales } from '../locales'
import { useMemo } from 'react'

export function useLocale (): Locale {
  const { i18n } = useTranslation()
  return useMemo(() => {
    const resolved = i18n.resolvedLanguage ?? 'en'
    if (locales.includes(resolved as Locale)) {
      return resolved as Locale
    }
    return defaultLocale
  }, [i18n.resolvedLanguage])
}

import { z } from 'zod'
import Service from './base'
import { SerializedPassengerInformation } from '../types/passenger-information'
import { TermsData } from '../types/booking'
import { Brand } from '../contexts/brand'
import { InsuranceOptions } from '../types/insurance'

const failedBookingSchema = z.object({
  status: z.literal('failed'),
  id: z.string().uuid(),
  error: z.string()
})
const pendingBookingSchema = z.object({
  status: z.literal('pending'),
  id: z.string().uuid()
})

const completedBookingSchema = z.object({
  id: z.string().uuid(),
  departure_date: z.coerce.date(),
  status: z.literal('completed'),
  can_pay: z.boolean(),
  on_request: z.boolean(),
  tour_name: z.string(),
  tour_id: z.string(),
  tour_external_id: z.string(),
  name: z.string(),
  email: z.string(),
  market: z.enum(['default', 'north_america']),
  exchange_rate: z.coerce.number(),
  state: z.string(),
  number: z.string(),
  amount: z.coerce.number(),
  local_costs: z.coerce.number(),
  full_amount: z.coerce.number(),
  insurance_amount: z.coerce.number(),
  deposit: z.coerce.number().nullable(),
  locale: z.string().optional()
})

const bookingSchema = z.discriminatedUnion('status', [
  pendingBookingSchema,
  failedBookingSchema,
  completedBookingSchema
])

export type Booking = z.infer<typeof bookingSchema>
export type CompletedBooking = z.infer<typeof completedBookingSchema>

const createBookingSchema = z.object({
  id: z.string().uuid(),
  status: z.string().optional()
})

export type CreateBooking = z.infer<typeof createBookingSchema>

export default class BookingService extends Service {
  async getBooking (id: string): Promise<Booking> {
    const booking = await this.get(`/api/v1/bookings/${id}`)
    return bookingSchema.parse(booking)
  }

  async createBooking ({
    departureId, passengerInformation, insurance, terms, locale, brand, voucherCode, products, extras, idempotencyKey
  }: {
    departureId: string
    passengerInformation: SerializedPassengerInformation
    terms: TermsData
    insurance: InsuranceOptions
    locale: string
    brand: Brand
    voucherCode?: string
    products?: Array<[string, number]>
    extras?: Record<string, string>
    idempotencyKey?: string
  }): Promise<CreateBooking> {
    const response = await this.post('/api/v1/bookings', {
      departureId,
      ...passengerInformation,
      insurance,
      terms,
      locale,
      brand,
      voucher: voucherCode ?? null,
      products: (products ?? []).map(([id, amount]) => ({ id, amount })),
      ...extras,
      idempotency_key: idempotencyKey
    })
    return createBookingSchema.parse(response)
  }
}

import { InsurancePolicy } from '../services/departures'
import { Fragment, useId, useMemo, useState } from 'react'
import { useCurrencyFormatter } from '../hooks/formatter'
import { Field, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { Dialog, Transition } from '@headlessui/react'
import InformationIcon from './information-icon'
import Button from './button'
import { cleanHTML } from '../utils/html'
import { OverviewValues } from '../types/overview'
import { calculatePolicyCost } from '../utils/insurance'

export default function InsuranceProduct ({ totalPrice, localCosts, policy }: { totalPrice: number, localCosts: number, policy: InsurancePolicy }): JSX.Element {
  const [cardOpen, setCardOpen] = useState(false)
  const formatter = useCurrencyFormatter()
  const { t } = useTranslation()
  const policyPrice = useMemo(() => calculatePolicyCost(policy, { totalPrice, localCosts }), [policy, localCosts, totalPrice])
  const id = useId()
  const { values, errors, touched, setFieldTouched } = useFormikContext<OverviewValues>()

  return (
    <>
      <label className='bg-brand-well flex flex-col gap-8 justify-between py-4 px-8 border-b last:border-b-0' htmlFor={id}>
        <div className='flex justify-between gap-8'>
          <span className='inline-flex gap-4 items-center'>
            <Field name={`insurance.${policy.insurance_type}`} type='checkbox' id={id} onInput={async () => await setFieldTouched(`terms.${policy.insurance_type}`, false, false)} />
            <strong>{t(policy.insurance_type, { keyPrefix: 'policy_types', ns: 'insurance' })}</strong>
            <InformationIcon onClick={(e) => { e.preventDefault(); setCardOpen(true) }} />
          </span>
          <strong>
            {formatter.format(policyPrice)}
          </strong>
        </div>
        <div className='flex flex-col gap-4' dangerouslySetInnerHTML={{ __html: cleanHTML(t(policy.insurance_type, { ns: 'insurance', keyPrefix: 'policy_summary' })) }} />
      </label>
      {values.insurance[policy.insurance_type] && (
        <>
          <label className='flex bg-brand-well-light gap-4 py-4 px-8 border-b' htmlFor={`${id}-terms`}>
            <Field name={`terms.${policy.insurance_type}`} type='checkbox' id={`${id}-terms`} />
            <div dangerouslySetInnerHTML={{ __html: cleanHTML(t(policy.insurance_type, { keyPrefix: 'terms', ns: 'insurance' })) }} />
          </label>
          {errors.terms?.[policy.insurance_type] != null && touched.terms?.[policy.insurance_type] === true && (
            <div className='bg-brand-well py-4 px-8 border-b has-error text-red-700 scroll-mt-20'>
              {errors.terms?.[policy.insurance_type]}
            </div>
          )}
        </>
      )}
      <Transition show={cardOpen} as={Fragment}>
        <Dialog open={cardOpen} onClose={() => setCardOpen(false)} className='relative z-50'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/30 backdrop-blur' aria-hidden='true' />
          </Transition.Child>

          {/* Full-screen scrollable container */}
          <div className='fixed inset-0 w-screen overflow-y-auto'>
            {/* Container to center the panel */}
            <div className='flex min-h-full items-center justify-center p-4'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                {/* The actual dialog panel  */}
                <Dialog.Panel className='mx-auto max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl rounded bg-white p-8 flex flex-col gap-4'>
                  <Dialog.Title className='text-lg font-bold flex justify-between items-center'>
                    {t(policy.insurance_type, { keyPrefix: 'policy_types', ns: 'insurance' })}
                  </Dialog.Title>

                  <Dialog.Description>
                    <div dangerouslySetInnerHTML={{
                      __html: cleanHTML(t(policy.insurance_type, { ns: 'insurance', keyPrefix: 'policy_description' }))
                    }}
                    />
                  </Dialog.Description>
                  <Button tone='primary' onClick={() => setCardOpen(false)}>{t('close', 'Close')}</Button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

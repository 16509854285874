import { createContext, PropsWithChildren, useMemo } from 'react'
import { useStorageState } from '../hooks/storage-state'

interface RecentBooking {
  id: string
  departureId: string
  bookedAt: number
}

interface RecentBookingContextType {
  recentBooking: RecentBooking | undefined
  setRecentBooking: (value: RecentBooking | undefined) => void
  removeRecentBooking: () => void
}

const nope = (): never => { throw new Error('You need a provider to use this context') }

const initial: RecentBookingContextType = {
  recentBooking: undefined,
  setRecentBooking: nope,
  removeRecentBooking: nope
}
export const RecentBookingContext = createContext(initial)

export default function RecentBookingProvider ({ children }: PropsWithChildren<{}>): JSX.Element {
  const [recentBooking, setRecentBooking, removeRecentBooking] = useStorageState<RecentBooking>('recent-booking')

  const value = useMemo(() => {
    return {
      recentBooking, setRecentBooking, removeRecentBooking
    }
  }, [recentBooking, setRecentBooking, removeRecentBooking])
  return <RecentBookingContext.Provider value={value}>{children}</RecentBookingContext.Provider>
}

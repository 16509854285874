import { ChangeEvent, createRef, useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

interface StepperInputProps {
  name?: string
  value: number | string
  min: number
  max: number
  onValueChange?: (value: number) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  block?: boolean
  disabled?: boolean
}

export default function StepperInput ({
  name,
  value,
  min,
  max,
  onValueChange,
  onChange,
  block = false,
  disabled = false
}: StepperInputProps): JSX.Element {
  const inputRef = createRef<HTMLInputElement>()
  const numericValue = useMemo(() => typeof value === 'string' ? parseInt(value ?? '0', 10) : value, [value])

  const setNewValue = useCallback((newValue: number) => {
    const input = inputRef.current
    if (input !== null) {
      input.value = String(newValue)
      const syntheticEvent = { target: input }
      onChange?.(syntheticEvent as ChangeEvent<HTMLInputElement>)
    }
    onValueChange?.(newValue)
  }, [inputRef, onChange, onValueChange])

  const handleDown = useCallback(() => {
    if (numericValue > min) {
      setNewValue(numericValue - 1)
    }
  }, [min, numericValue, setNewValue])
  const handleUp = useCallback(() => {
    if (numericValue < max) {
      setNewValue(numericValue + 1)
    }
  }, [max, numericValue, setNewValue])

  return (
    <div className={twMerge(classNames('bg-brand-light justify-between items-center rounded-full text-xs p-1 gap-2', {
      flex: block,
      'inline-flex': !block,
      'opacity-50 cursor-not-allowed': disabled
    }))}
    >
      <button className='w-4.5 h-4.5 bg-white rounded-full inline-flex items-center justify-center disabled:opacity-60 disabled:cursor-not-allowed' disabled={disabled || numericValue <= min} type='button' onClick={handleDown}>-</button>
      {value}
      <input type='hidden' name={name} value={value} ref={inputRef} />
      <button className='w-4.5 h-4.5 bg-white rounded-full inline-flex items-center justify-center disabled:opacity-60 disabled:cursor-not-allowed' disabled={disabled || numericValue >= max} type='button' onClick={handleUp}>+</button>
    </div>
  )
}

import { useEffect } from 'react'
import { loadTrengo } from '../utils/trengo'
import { useTranslation } from 'react-i18next'
import { useFrameDetector } from '../hooks/frame-detector'
import { useBrand } from '../hooks/brand'
import { useWindowSize } from 'usehooks-ts'

const TRENGO_MOBILE_OFFSET_Y = '5rem'

export default function TrengoLoader (): undefined {
  const { i18n } = useTranslation()
  const frame = useFrameDetector()
  const brand = useBrand()
  const { width } = useWindowSize()

  useEffect(() => {
    if (!frame && brand === 'boatbiketours') {
      loadTrengo(i18n.resolvedLanguage ?? 'en', width < 1024 ? TRENGO_MOBILE_OFFSET_Y : '0')
    }
  }, [frame, i18n.resolvedLanguage, brand, width])

  useEffect(() => {
    if (window.Trengo != null) {
      if (width < 1024) {
        window.Trengo.extraOffsetY = TRENGO_MOBILE_OFFSET_Y
      } else {
        window.Trengo.extraOffsetY = '0'
      }
    }
  }, [width])

  return undefined
}

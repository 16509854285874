import { createContext, PropsWithChildren } from 'react'
import {
  TourDetails
} from '../services/tours'

interface TourContextType {
  tour: TourDetails
}

const initialContext = {}

export const TourContext = createContext(initialContext as TourContextType)

export default function TourProvider ({ tour, children }: PropsWithChildren<TourContextType>): JSX.Element {
  return <TourContext.Provider value={{ tour }}>{children}</TourContext.Provider>
}

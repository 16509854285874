import { Outlet, useParams } from 'react-router-dom'
import { useBooking } from '../hooks/booking'
import Loader from '../components/loader'
import BookingProvider from '../contexts/booking'

export default function BookingPage (): JSX.Element {
  const { booking: bookingId } = useParams()
  const { data: booking, isLoading } = useBooking(bookingId)

  if (isLoading) {
    return (<Loader />)
  }

  if (booking === undefined) {
    return (<p>Failed to load booking</p>)
  }

  if (booking.status !== 'completed') {
    return (<p>This booking is not completed (yet).</p>)
  }

  return (
    <BookingProvider booking={booking}>
      <Outlet />
    </BookingProvider>
  )
}

import { GTMCartItem, gtmEvent } from '../utils/gtm'
import { useCallback, useContext } from 'react'
import { CabinsContext } from '../contexts/cabins'
import { CabinSelection } from '../types/cabin-selection'
import { SerializedPassengerInformation } from '../types/passenger-information'
import { ProductsContext } from '../contexts/products'
import { TourContext } from '../contexts/tour'
import { DepartureContext } from '../contexts/departure'
import { priceMultiplier } from '../utils/products'

interface PurchaseEventParams {
  id: string
  voucher?: string
  cabinSelection: CabinSelection
  passengerInformation: SerializedPassengerInformation
  products?: Array<[string, number]>
}

export function usePurchaseEvent (): (params: PurchaseEventParams) => void {
  const { cabinMap } = useContext(CabinsContext)
  const { productMap, products: { sgr } } = useContext(ProductsContext)
  const { tour: { tours: [tour] } } = useContext(TourContext)
  const { departure } = useContext(DepartureContext)
  return useCallback(({ id, voucher, cabinSelection, passengerInformation, products = [] }: PurchaseEventParams) => {
    const cabinItems = Object.entries(cabinSelection).flatMap(([id, occupation]): GTMCartItem[] => {
      const cabin = cabinMap[id]
      return occupation.map((passengers) => {
        const price = (passengers < 2 ? cabin.surcharge_price : (passengers > 2 ? cabin.discount_price : cabin.unit_price)) ?? cabin.unit_price
        return {
          item_name: cabin.name,
          item_id: `${id}:${passengers}`,
          item_category: 'Cabin',
          quantity: 1,
          price: price * passengers,
          currency: 'EUR'
        }
      })
    })

    const bikes = passengerInformation.passengers.map(passenger => passenger.bike)
    const diets = passengerInformation.passengers.map(passenger => passenger.diet).filter(diet => diet != null)
    const others = passengerInformation.passengers.flatMap(passenger => passenger.other).filter(other => other != null)

    const mapProduct = (category: string) => {
      return (productId: string, quantity: number = 1): GTMCartItem => {
        const product = productMap.get(productId)
        return {
          item_name: product?.name,
          item_id: productId,
          item_category: category,
          quantity: 1,
          price: priceMultiplier(product?.price_calculation ?? null, tour.duration) * (product?.unit_price ?? 0),
          currency: 'EUR'
        }
      }
    }

    const bikeItems = bikes.map(mapProduct('Bike'))
    const dietItems = diets.filter(diet => diet !== '').map(mapProduct('Diet'))
    const otherItems = others.map(mapProduct('Other'))
    const mapExtraProduct = mapProduct('Other')
    const productItems = products.map(([id, amount]) => mapExtraProduct(id, amount))

    const tourItem: GTMCartItem = {
      item_id: tour.id,
      item_name: tour.name,
      item_category: 'Tour',
      quantity: 1,
      currency: 'EUR'
    }
    // Move cabin costs to tour
    tourItem.price = cabinItems.map(item => (item.price ?? 0) * item.quantity).reduce((acc, price) => acc + price, 0)
    cabinItems.forEach(item => { item.price = 0 })

    const departureItem: GTMCartItem = {
      item_id: departure.id,
      item_name: departure.date + ': ' + departure.ship_name,
      item_category: 'Departure',
      quantity: 1
    }
    const mandatoryItem: GTMCartItem = {
      item_name: sgr.name,
      item_id: sgr.id,
      item_category: 'Mandatory',
      quantity: passengerInformation.passengers.length,
      price: sgr.unit_price,
      currency: 'EUR'
    }

    gtmEvent({
      event: 'add_to_cart',
      ecommerce: {
        items: [tourItem, departureItem, mandatoryItem]
      }
    })

    const allItems = [
      tourItem, departureItem, ...cabinItems, ...bikeItems, ...dietItems, ...otherItems, ...productItems, mandatoryItem
    ]

    const totalPrice = allItems.reduce((acc, item) => acc + ((item.price ?? 0) * item.quantity), 0)

    gtmEvent({
      event: 'purchase',
      ecommerce: {
        transaction_id: id,
        coupon: voucher,
        items: allItems,
        value: totalPrice,
        currency: 'EUR'
      }
    })
  }, [cabinMap, departure.date, departure.id, departure.ship_name, productMap, sgr.id, sgr.name, sgr.unit_price, tour.duration, tour.id, tour.name])
}

import { Outlet, useParams } from 'react-router-dom'
import { usePayment } from '../hooks/payments'
import Loader from '../components/loader'
import PaymentProvider from '../contexts/payment'
import NotFound from './not-found'

export default function PaymentPage (): JSX.Element {
  const { payment: paymentId = '' } = useParams()

  const { data: payment, isLoading, isSuccess } = usePayment(paymentId)

  if (isLoading) {
    return (<Loader />)
  }

  if (isSuccess) {
    if (payment === null) {
      return (<NotFound />)
    }
    return (<PaymentProvider payment={payment}><Outlet /></PaymentProvider>)
  }

  return (<p>Something went wrong.</p>)
}

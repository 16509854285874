import {
  createContext,
  PropsWithChildren,
  useMemo
} from 'react'
import { SerializedPassengerInformation } from '../types/passenger-information'
import { useStorageState } from '../hooks/storage-state'

export interface PassengersContextType<T = SerializedPassengerInformation> {
  unvalidated: T | undefined
  setUnvalidated: (value: T | undefined) => void
  validated: T | undefined
  setValidated: (value: T | undefined) => void
}
const nope = (): never => { throw new Error('You need a provider to use this context') }

const initialValue: PassengersContextType = {
  unvalidated: undefined,
  setUnvalidated: nope,
  validated: undefined,
  setValidated: nope
}

export const PassengersContext = createContext(initialValue)

export default function PassengersProvider ({ children }: PropsWithChildren<{}>): JSX.Element {
  const [unvalidated, setUnvalidated] = useStorageState<SerializedPassengerInformation>('unvalidated')
  const [validated, setValidated] = useStorageState<SerializedPassengerInformation>('validated')

  const value = useMemo(() => ({
    unvalidated,
    setUnvalidated,
    validated,
    setValidated
  }), [setUnvalidated, setValidated, unvalidated, validated])

  return <PassengersContext.Provider value={value}>{children}</PassengersContext.Provider>
}

import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group'
import { useRef } from 'react'
import './double-calendar.scss'
import classNames from 'classnames'
import Calendar, { CalendarProps } from './calendar'

export default function DoubleCalendar (props: CalendarProps): JSX.Element {
  const prevValue = useRef(0)
  const animationClass = useRef<'slide-left' | 'slide-right'>('slide-left')

  const _year: number = props.month.year()
  const _month: number = props.month.month()
  const value = _year * 100 + _month
  const secondMonth = props.month.add(1, 'month')

  if (prevValue.current < value) {
    animationClass.current = 'slide-left'
    prevValue.current = value
  } else if (prevValue.current > value) {
    animationClass.current = 'slide-right'
    prevValue.current = value
  }

  return (
    <TransitionGroup component='div' className={classNames('flex gap-8 grid double-calendar', animationClass.current)}>
      <CSSTransition classNames='slide' key={value} timeout={200}>
        <div className='grid gap-8 grid-cols-2 duration-[0.2s]'>
          <Calendar {...props} showNext={false} />
          <Calendar {...props} month={secondMonth} showPrevious={false} />
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
}

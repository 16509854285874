export default function HomeInselhuepfenIcon ({ className }: { className?: string }): JSX.Element {
  return (
    <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' className={className}>
      <g id='Ebene_2' data-name='Ebene 2'>
        <path
          fill='currentColor'
          d='M37.15,22.5s0,0,0,0A17.11,17.11,0,0,0,23.57,5.93,3.69,3.69,0,0,0,24,4.29a3.87,3.87,0,0,0-7.74,0,3.7,3.7,0,0,0,.39,1.64A17.09,17.09,0,0,0,3,22.46s0,0,0,0v.14a17.07,17.07,0,0,0,34.13,0s0-.07,0-.11S37.15,22.51,37.15,22.5ZM17.5,5.61A2.9,2.9,0,1,1,23,4.29a2.92,2.92,0,0,1-.32,1.32.48.48,0,0,0-.05.17,17.64,17.64,0,0,0-2.54-.21,17.62,17.62,0,0,0-2.53.21A.84.84,0,0,0,17.5,5.61Zm3.07,33.1V35.3a.49.49,0,0,0-1,0v3.41A16.09,16.09,0,0,1,4,23H7.39a.48.48,0,0,0,.48-.48A.49.49,0,0,0,7.39,22H4A16.11,16.11,0,0,1,19.6,6.56V10a.49.49,0,0,0,1,0V6.56A16.09,16.09,0,0,1,36.15,22H32.7a.48.48,0,0,0-.48.48.47.47,0,0,0,.48.48h3.46A16.09,16.09,0,0,1,20.57,38.71Z'
        />
        <path
          fill='currentColor'
          d='M29.31,13.41a.47.47,0,0,0-.56-.09l-11.68,6.1,0,0-.08.07a.34.34,0,0,0-.08.09l0,0L10.76,31.3a.5.5,0,0,0,.09.57.49.49,0,0,0,.34.14.64.64,0,0,0,.23,0l11.68-6.1,0,0,.08-.07a.27.27,0,0,0,.07-.08s0,0,0,0L29.4,14A.47.47,0,0,0,29.31,13.41Zm-17,17,5.08-9.74,4.66,4.66Zm10.42-5.77L18.1,20l9.74-5.08Z'
        />
      </g>
    </svg>
  )
}

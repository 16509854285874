import { useTranslation } from 'react-i18next'
import { Facebook, MessageCircle, Phone, Send } from 'react-feather'
import { twMerge } from 'tailwind-merge'
import { PropsWithChildren, SyntheticEvent, useCallback, useMemo } from 'react'
import { cleanHTML } from '../utils/html'
import { InselhuepfenFAQ, InselhuepfenPhone, InselhuepfenSendMessage } from '../icons/inselhuepfen'

function QuestionsBlock ({ children }: PropsWithChildren<{}>): JSX.Element {
  return (
    <aside className='bg-brand-well p-8'>
      {children}
    </aside>
  )
}

function QuestionsTitle ({ children }: PropsWithChildren<{}>): JSX.Element {
  return (<h2 className='text-brand-primary text-lg font-bold mb-4'>{children}</h2>)
}

function QuestionsDescription ({ children, html }: PropsWithChildren<{ html?: string }>): JSX.Element {
  if (html !== undefined) {
    return (
      <p
        className='mb-4'
        dangerouslySetInnerHTML={{ __html: cleanHTML(html) }}
      />
    )
  }
  return <p className='mb-4'>{children}</p>
}

function QuestionsList ({ children }: PropsWithChildren<{}>): JSX.Element {
  return (<ul className='text-brand-primary font-normal font-roboto brand-inselhuepfen:font-open-sans flex flex-col gap-2'>{children}</ul>)
}

function QuestionsListItem ({ className, children }: PropsWithChildren<{ className?: string }>): JSX.Element {
  return (<li>{children}</li>)
}

function extractPhoneNumber (text: string): string {
  return text.replaceAll(/\(\d+\)/g, '').replaceAll(/[^+0-9]/g, '')
}

function BBTQuestions ({ className }: { className?: string }): JSX.Element {
  const { t } = useTranslation()

  const handleChatStart = useCallback((e: SyntheticEvent) => {
    e.preventDefault()
    window.Trengo?.Api?.Widget.open('chat')
  }, [])

  const phoneNumbers = useMemo(() => {
    const numbers = t('questions:phone_numbers').split(';')
    return numbers.map(number => [extractPhoneNumber(number), number])
  }, [t])

  return (
    <div className={twMerge('frame:hidden', className, 'brand-inselhuepfen:hidden')}>
      <QuestionsTitle>{t('questions:title', 'Questions')}</QuestionsTitle>
      <QuestionsBlock>
        <QuestionsDescription html={t('questions:availability', 'Monday to Friday from 8:30 to 17:00')} />
        <QuestionsList>
          {phoneNumbers.map(([number, label]) => <QuestionsListItem key={number}><a href={`tel:${number}`} target='_blank' className='flex gap-4' rel='noreferrer'><Phone className='stroke-1' /> {label}</a></QuestionsListItem>)}
          <QuestionsListItem><a href='https://www.facebook.com/boatbiketours/' target='_blank' className='flex gap-4' rel='noreferrer'><Facebook className='stroke-1' /> {t('questions:facebook_messenger', 'Facebook Messenger')}</a></QuestionsListItem>
          <QuestionsListItem><a href='#' onClick={handleChatStart} className='flex gap-4'><MessageCircle className='stroke-1' /> {t('questions:start_a_live_chat', 'Start a live chat')}</a></QuestionsListItem>
          <QuestionsListItem><a href={t('questions:send_us_a_message_url', 'https://www.boatbiketours.com/contact/#contact_form')} target='_blank' className='flex gap-4' rel='noreferrer'><Send className='stroke-1' /> {t('questions:send_us_a_message', 'Send us a message')}</a></QuestionsListItem>
        </QuestionsList>
      </QuestionsBlock>
    </div>
  )
}

function InselhuepfenQuestions ({ className }: { className?: string }): JSX.Element {
  const { t } = useTranslation()

  const phoneNumbers = useMemo(() => {
    const numbers = t('questions:inselhuepfen.phone_numbers').split(';')
    return numbers.map(number => [extractPhoneNumber(number), number])
  }, [t])

  return (
    <div className={twMerge('frame:hidden', className, 'hidden brand-inselhuepfen:block')}>
      <QuestionsTitle>{t('questions:inselhuepfen.title', 'Any questions?')}</QuestionsTitle>
      <QuestionsBlock>
        <QuestionsDescription html={t('questions:inselhuepfen.availability', 'We are available from Monday to Friday 9am to 12pm and 2pm to 5pm')} />
        <QuestionsList>
          {phoneNumbers.map(([number, label]) => <QuestionsListItem key={number}><a href={`tel:${number}`} target='_blank' className='flex gap-4' rel='noreferrer'><InselhuepfenPhone />{label}</a></QuestionsListItem>)}
          <QuestionsListItem><a href={t('questions:inselhuepfen.send_us_a_message_url', 'https://www.inselhuepfen.com/en/contact')} target='_blank' className='flex gap-4' rel='noreferrer'><InselhuepfenSendMessage /> {t('questions:send_us_a_message', 'Send us a message')}</a></QuestionsListItem>
          <QuestionsListItem><a href={t('questions:inselhuepfen.faqs_url', 'https://www.inselhuepfen.com/en/good-to-know')} target='_blank' className='flex gap-4' rel='noreferrer'><InselhuepfenFAQ /> {t('questions:inselhuepfen.faqs', 'Our FAQs')}</a></QuestionsListItem>
        </QuestionsList>
      </QuestionsBlock>
    </div>
  )
}

export default function Questions ({ className }: { className?: string }): JSX.Element {
  return (
    <>
      <BBTQuestions className={className} />
      <InselhuepfenQuestions className={className} />
    </>
  )
}

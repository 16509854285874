import Service from './base'
import { z } from 'zod'

const TourSchema = z.object({
  id: z.string(),
  name: z.string(),
  duration: z.number()
})

export type Tour = z.infer<typeof TourSchema>

const TourDetailsSchema = z.object({
  id: z.string(),
  tours: z.array(TourSchema).min(1),
  first_departure: z.string().nullable(),
  last_departure: z.string().nullable(),
  first_available: z.string().nullable()
})

export type TourDetails = z.infer<typeof TourDetailsSchema>

export default class TourService extends Service {
  async all (): Promise<Tour[]> {
    const data = await this.get('/api/v1/tours')
    return z.object({ data: z.array(TourSchema) }).parse(data).data
  }

  async getTour (tourId: string): Promise<TourDetails> {
    const tour = await this.get(`/api/v1/tours/${tourId}`)
    return TourDetailsSchema.parse(tour)
  }
}

import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/de'
import 'dayjs/locale/nl'
import LocaleData from 'dayjs/plugin/localeData'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(LocaleData)
dayjs.extend(LocalizedFormat)

export default dayjs

import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import I18NextHttpBackend from 'i18next-http-backend'
import dayjs from './libs/dayjs'
import { defaultLocale, locales, translations } from './locales'

function uppercaseStrings<T extends Record<string, unknown>> (obj: T): T {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => {
    if (typeof value === 'string') {
      return [key, value.toUpperCase().replace(/\{\{ *[A-Z]+ *\}\}/g, (arg) => arg.toLowerCase())]
    }
    if (typeof value === 'object') {
      return [key, uppercaseStrings(value as Record<string, unknown>)]
    }
    return [key, value]
  })) as T
}

const debug = import.meta.env.VITE_DEBUG_TRANSLATIONS === 'true'
const options: InitOptions = {
  fallbackLng: defaultLocale,
  supportedLngs: locales,
  defaultNS: 'common',
  debug,
  resources: debug ? uppercaseStrings(translations) : translations,
  detection: {
    lookupQuerystring: 'locale'
  },
  interpolation: {
    escapeValue: false
  }
}

if (import.meta.env.VITE_SAVE_MISSING_TRANSLATIONS === '1') {
  options.backend = {
    loadPath: '/api/locales/{{lng}}/{{ns}}',
    addPath: '/api/locales/{{lng}}/{{ns}}'
  }
  options.saveMissing = true
  i18n.use(I18NextHttpBackend)
}

i18n.on('languageChanged', (lng) => {
  dayjs.locale(lng)
})

void i18n.use(LanguageDetector).use(initReactI18next).init(options)

export default i18n

import { Listbox, Transition } from '@headlessui/react'
import { ChevronDown } from 'react-feather'
import classNames from 'classnames'
import {
  ComponentType, Fragment,
  Key, PropsWithChildren,
  ReactNode
} from 'react'
import { twMerge } from 'tailwind-merge'

export interface SelectOption<V extends Key> {
  name: string | ReactNode
  value: V
}

type ButtonComponentProps<T> = PropsWithChildren<{
  value?: T
}>

type OptionComponentProps<T> = PropsWithChildren<{
  option: T
  selected: boolean
  active: boolean
}>

export type SelectVariant = 'white' | 'primary' | 'primary-condensed'

interface SelectProps<V extends Key, T extends SelectOption<V>> {
  className?: string
  variant?: SelectVariant
  options: T[]
  value?: T
  disabled?: boolean
  onChange?: (value: T) => void
  label?: string | ReactNode
  placeholder?: string | ReactNode
  optionComponent?: ComponentType<OptionComponentProps<T>>
  buttonComponent?: ComponentType<ButtonComponentProps<T>>
}

const buttonClasses: Record<SelectVariant, string> = {
  white: 'bg-white hover:bg-secondary-100',
  primary: 'bg-brand-light hover:bg-brand-light-hover',
  'primary-condensed': 'bg-brand-light hover:bg-brand-light-hover p-2 pl-3 gap-3 text-xs/4'
}

export default function Select<V extends Key, T extends SelectOption<V>> ({
  className,
  options,
  value,
  onChange,
  label,
  placeholder,
  variant = 'primary',
  disabled = false,
  buttonComponent: ButtonComponent,
  optionComponent: OptionComponent
}: SelectProps<V, T>): JSX.Element {
  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <>
          {label !== undefined && (<Listbox.Label className='text-brand-primary font-medium block mb-4'>{label}</Listbox.Label>)}
          <div className={twMerge('relative', className)}>
            <Listbox.Button
              className={twMerge('text-left rounded w-full px-4 py-3 text-sm/4 leading-4 cursor-default flex justify-between gap-2 disabled:opacity-50', buttonClasses[variant])}
            >
              {
                ButtonComponent !== undefined ? (<ButtonComponent value={value}>{value?.name ?? placeholder}</ButtonComponent>) : (<span>{value?.name ?? placeholder}</span>)
              }
              <ChevronDown
                className={classNames('text-brand-primary w-4 h-4 transition-transform', { 'rotate-180': open })}
              />
            </Listbox.Button>
            <Transition
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
              as={Fragment}
            >
              <Listbox.Options
                className='absolute text-sm/4 z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white border border-brand-primary ring-1 ring-brand-primary ring-opacity-5 focus:outline-none'
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    value={option}
                    className='py-3 px-4 select-none whitespace-nowrap cursor-default ui-selected:font-bold ui-selected:ui-not-active:text-white ui-selected:ui-not-active:bg-brand-primary ui-active:text-brand-primary ui-active:bg-brand-light ui-active:ui-selected:bg-brand-primary/80 ui-active:ui-selected:text-white'
                  >
                    {OptionComponent !== undefined
                      ? ({ selected, active }) => (
                        <OptionComponent
                          option={option}
                          active={active}
                          selected={selected}
                        >{option.name}
                        </OptionComponent>
                        )
                      : (option.name)}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

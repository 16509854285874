import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState
} from 'react'

interface CabinSelectionState {
  cabinSelection: Record<string, number[]>
}

interface CabinSelectionMutations {
  setCabinSelection: (cabins: Record<string, number[]>) => void
  setCabinTypeSelection: (cabinTypeId: string, passengers: number[]) => void
}

const initialState: CabinSelectionState & CabinSelectionMutations = {
  cabinSelection: {},
  setCabinSelection: (): never => { throw new Error('not implemented') },
  setCabinTypeSelection: (): never => { throw new Error('not implemented') }
}

export const CabinSelectionContext = createContext(initialState)

export default function CabinSelectionProvider ({ children }: PropsWithChildren<{}>): JSX.Element {
  const [cabinSelection, setCabinSelection] = useState(initialState.cabinSelection)

  const setCabinTypeSelection = useCallback((cabinTypeId: string, passengers: number[]) => {
    setCabinSelection(cabinSelection => ({
      ...cabinSelection,
      [cabinTypeId]: passengers
    }))
  }, [])

  const state = useMemo(() => ({
    cabinSelection,
    setCabinSelection,
    setCabinTypeSelection
  }), [cabinSelection, setCabinTypeSelection])
  return <CabinSelectionContext.Provider value={state}>{children}</CabinSelectionContext.Provider>
}

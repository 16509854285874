import { Payment } from '../services/payments'
import { createContext, PropsWithChildren } from 'react'

interface PaymentContextType {
  payment: Payment
}

const initialState: PaymentContextType = {
  payment: {
    id: '',
    status: '',
    amount: 0,
    exchange_rate: 1,
    market: 'default',
    name: null,
    description: null,
    type: '',
    booking_number: '',
    full_name: '',
    email: ''
  }
}

export const PaymentContext = createContext(initialState)

export default function PaymentProvider ({ payment, children }: PropsWithChildren<{ payment: Payment }>): JSX.Element {
  return <PaymentContext.Provider value={{ payment }}>{children}</PaymentContext.Provider>
}

import { usePaymentResult } from '../hooks/payments'
import { useCallback, useContext, useMemo } from 'react'
import { PaymentContext } from '../contexts/payment'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Container from '../components/container'
import { useQuery } from '@tanstack/react-query'
import { PaymentIntentQuery } from '../queries/payments'
import PaymentDetails from '../components/payment-details'
import Button from '../components/button'
import { useBrandBasePath } from '../hooks/brand'

export default function PaymentCompleted (): JSX.Element {
  const { payment } = useContext(PaymentContext)
  const { t } = useTranslation()
  const { status, paymentIntent, clientSecret } = usePaymentResult()
  const { data } = useQuery(PaymentIntentQuery(payment.market, paymentIntent, clientSecret))
  const navigate = useNavigate()
  const basePath = useBrandBasePath()

  const paymentStatus = useMemo(() => data?.status ?? status, [data?.status, status])

  const handleRetry = useCallback(() => {
    navigate(`${basePath}/payments/${payment.id}`)
  }, [basePath, navigate, payment.id])

  return (
    <Container>
      <div className='pb-8'>
        <h2 className='text-brand-primary text-lg font-bold my-8'>{t('payment:payment_title', 'Fulfill your outstanding payment')}</h2>
        <p className='text-sm my-3'>{t('payment:payment_description', 'Please proceed with the payment for your booking.')}</p>
        <PaymentDetails payment={payment} paymentIntent={data ?? undefined}>
          {paymentStatus !== 'succeeded' && (
            <>
              <dd className='hidden' />
              <dt className='col-span-2 text-right'>
                <Button onClick={handleRetry}>Retry</Button>
              </dt>
            </>
          )}
        </PaymentDetails>
      </div>
    </Container>
  )
}

import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import Select, { SelectOption, SelectVariant } from './select'

export interface NumberSelectProps {
  min: number
  max: number
  value?: number
  variant?: SelectVariant
  onChange?: (value: number) => void
  label?: string
  translation?: string
  single?: string
  plural?: string
  className?: string
  disabled?: boolean
}

export default function NumberSelect ({ min, max, value, onChange, label, translation, single, plural, variant, disabled = false, className }: NumberSelectProps): JSX.Element {
  const { t } = useTranslation()
  const options = useMemo(() => {
    return Array.from(Array(max + 1 - min), (_, offset) => ({
      value: min + offset,
      name: translation !== undefined ? t(translation, `${min + offset} ${((min + offset) === 1 ? single : plural) ?? ''}`, { count: min + offset }) : `${min + offset} ${((min + offset) === 1 ? single : plural) ?? ''}`
    }))
  }, [max, min, plural, single, t, translation])

  const selectedValue = useMemo(() => {
    const target = value
    if (target !== undefined) {
      return options.find(option => option.value === target)
    }
    return options[0]
  }, [options, value])

  const setSelectedValue = useCallback((option: SelectOption<number>) => {
    onChange?.(option.value)
  }, [onChange])

  return (
    <Select disabled={disabled} options={options} value={selectedValue} label={label} onChange={setSelectedValue} variant={variant} className={className} />
  )
}

import { Outlet, useParams } from 'react-router-dom'
import { useTour } from '../hooks/tours'
import { useCabins, useDeparture, useProducts } from '../hooks/departures'
import Loader from '../components/loader'
import TourProvider from '../contexts/tour'
import DepartureProvider from '../contexts/departure'
import CabinsProvider from '../contexts/cabins'
import ProductsProvider from '../contexts/products'
import { useVoucher } from '../hooks/vouchers'
import { useContext } from 'react'
import { VoucherCodeContext } from '../contexts/voucher-code'
import VoucherProvider from '../contexts/voucher'
import {
  useCabinTranslations,
  useProductTranslations,
  useTranslatedCabins, useTranslatedProducts
} from '../hooks/translations'

export default function DeparturePage (): JSX.Element {
  const { departure: departureId } = useParams()
  const { data: departure, isLoading } = useDeparture(departureId)
  const { data: tour, isLoading: tourLoading } = useTour(departure?.tour_id)
  const { data: cabins, isLoading: cabinsLoading } = useCabins(departureId ?? '')
  const cabinTranslations = useCabinTranslations(departure?.ship_id ?? '')
  const productTranslations = useProductTranslations()
  const { data: products, isLoading: productsLoading } = useProducts(departureId ?? '')
  const { voucherCode } = useContext(VoucherCodeContext)
  const { data: voucher, isLoading: voucherLoading } = useVoucher(departureId ?? '', voucherCode ?? '')

  const translatedCabins = useTranslatedCabins(cabins, cabinTranslations)
  const translatedProducts = useTranslatedProducts(products, productTranslations)

  if (isLoading || tourLoading || cabinsLoading || productsLoading || voucherLoading) {
    return (
      <Loader />
    )
  }

  if (departure === undefined) {
    return (<div>No departure information</div>)
  }

  if (tour === undefined) {
    return (<div>No tour information</div>)
  }

  if (translatedCabins === undefined) {
    return (<div>No cabin information</div>)
  }

  if (translatedProducts === undefined) {
    return (<div>No product information</div>)
  }

  return (
    <TourProvider tour={tour}>
      <DepartureProvider departure={departure}>
        <CabinsProvider cabins={translatedCabins}>
          <ProductsProvider products={translatedProducts}>
            <VoucherProvider voucher={voucher}>
              <Outlet />
            </VoucherProvider>
          </ProductsProvider>
        </CabinsProvider>
      </DepartureProvider>
    </TourProvider>
  )
}

import { ForwardedRef, forwardRef, useId } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, useFormikContext } from 'formik'
import { cleanHTML } from '../utils/html'
import { Market } from '../types/booking'
import { OverviewValues } from '../types/overview'

export default forwardRef(function Terms ({ market }: { market: Market }, ref: ForwardedRef<HTMLElement>): JSX.Element {
  const id = useId()
  const { t } = useTranslation()
  const { errors } = useFormikContext<OverviewValues>()
  const terms = market === 'north_america' ? ['general_north_america', 'payment_north_america'] as const : ['general', 'consumer', 'payment'] as const
  const termErrors: Record<typeof terms[number], string> | undefined = errors?.terms as Record<typeof terms[number], string> | undefined
  const termsErrors = Array.from(new Set(terms.map(term => termErrors?.[term]).filter(term => term != null)))

  return (
    <section className='bg-brand-well px-8 py-10 flex flex-col gap-4 select-none' ref={ref}>
      {termsErrors.map(error => (
        <div className='text-red-700 has-error scroll-mt-4' key={error}>
          {error}
        </div>
      ))}
      {terms.map(term => (
        <div className='flex gap-4' key={term}>
          <div>
            <Field type='checkbox' name={`terms.${term}`} id={`${id}-${term}`} className='text-brand-primary focus:ring-brand-primary' />
          </div>
          <label htmlFor={`${id}-${term}`}>
            <strong>{t(`terms:${term}.title`)}</strong>
            <div dangerouslySetInnerHTML={{ __html: cleanHTML(t(`terms:${term}.description`) ?? '') }} />
          </label>
        </div>
      ))}
    </section>
  )
})

import { Component, PropsWithChildren, ReactNode } from 'react'

type ErrorBoundaryProps = PropsWithChildren<{}>
interface ErrorBoundaryState {
  hasError: boolean
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    hasError: false
  }

  static getDerivedStateFromError (error: unknown): ErrorBoundaryState {
    console.log(error)
    return { hasError: true }
  }

  componentDidCatch (error: unknown, errorInfo: unknown): void {
    console.log(error, errorInfo)
  }

  render (): ReactNode {
    if (this.state.hasError) {
      return <h1>Something went wrong</h1>
    }
    return this.props.children
  }
}

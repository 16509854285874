import { Outlet, useParams } from 'react-router-dom'
import { useTour } from '../hooks/tours'
import TourProvider from '../contexts/tour'
import Loader from '../components/loader'

export default function TourPage (): JSX.Element {
  const { tour: tourId } = useParams()
  const { data: tour, isLoading } = useTour(tourId)

  if (isLoading) {
    return <Loader />
  }

  if (tour != null) {
    return (
      <TourProvider tour={tour}>
        <Outlet />
      </TourProvider>
    )
  }

  return (<div>Failed loading tour data</div>)
}

import SummaryBlock from './block'
import { useCurrencyFormatter } from '../../hooks/formatter'
import { Disclosure } from '@headlessui/react'
import { ChevronDown, ChevronRight } from 'react-feather'
import { useCabinPricing } from '../../hooks/cabin-selection'
import { useTranslation } from 'react-i18next'

export default function CabinPriceBlock ({ cabinSelection }: { cabinSelection: Record<string, number[]> }): JSX.Element {
  const { selectedCabins, totalCabinPrice } = useCabinPricing({ cabinSelection })
  const formatter = useCurrencyFormatter()
  const { t } = useTranslation()

  return (
    <SummaryBlock className='flex-col gap-2'>
      <Disclosure>
        <Disclosure.Button className='flex gap-8 justify-between text-left'>
          {({ open }) => (
            <>
              <span className='inline-flex gap-1'>
                {open ? <ChevronDown className='text-brand-primary' /> : <ChevronRight className='text-brand-primary' />}
                <strong>{t('summary:cabins', 'Cabins')}</strong>
              </span>
              <strong className='text-brand-primary'>{formatter.format(totalCabinPrice)}</strong>
            </>
          )}
        </Disclosure.Button>
        <Disclosure.Panel>
          <ul className='flex flex-col gap-4 my-4'>
            {selectedCabins.map((selectedCabin, index) => (
              <li key={`${selectedCabin.cabin.id}-${index}`} className='flex gap-8 justify-between'>
                <span>{selectedCabin.cabin.name}</span>
                <span className='text-brand-primary'>{selectedCabin.amount}&times;&nbsp;{formatter.format(selectedCabin.unit_price)}</span>
              </li>
            ))}
          </ul>
        </Disclosure.Panel>
      </Disclosure>
    </SummaryBlock>
  )
}

import { ComponentProps, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

export interface ButtonProps {
  tone: 'primary' | 'danger' | 'success' | 'main' | 'next' | 'action'
  outline: boolean
}

const colorClasses: Record<ButtonProps['tone'], string> = {
  primary: 'bg-brand-primary text-white border-brand-primary border hover:bg-brand-primary-hover',
  success: 'bg-brand-success text-white border-brand-success border hover:bg-brand-success-hover',
  next: 'bg-brand-next text-white border-brand-next border hover:bg-brand-next-hover',
  main: 'bg-brand-main text-white border-brand-main border hover:bg-brand-main-hover',
  action: 'bg-brand-action text-white border-brand-action border hover:bg-brand-action-hover',
  danger: 'bg-red-500 text-white border-red-500 border'
}

const outlineColorClasses: Record<ButtonProps['tone'], string> = {
  primary: 'text-brand-primary bg-white hover:bg-neutral-50 border-brand-primary border',
  success: 'text-success bg-white hover:bg-neutral-50 border-success border',
  main: 'text-brand-main bg-white hover:bg-neutral-50 border-brand-main border',
  action: 'text-brand-action bg-white hover:bg-neutral-50 border-brand-action border',
  next: 'text-brand-next bg-white hover:bg-neutral-50 border-brand-next border',
  danger: 'text-red-500 bg-white border-red-500 border'
}

export default function Button ({ tone = 'primary', outline = false, children, className = undefined, type = 'button', ...buttonProps }: PropsWithChildren<Partial<ButtonProps> & ComponentProps<'button'>>): JSX.Element {
  return (
    <button
      className={twMerge(classNames('active:translate-y-0.5 disabled:cursor-not-allowed disabled:opacity-50 font-bold rounded px-6 py-2 text-sm/4', outline ? outlineColorClasses[tone] : colorClasses[tone], className))} type={type}
      {...buttonProps}
    >{children}
    </button>
  )
}

import { Resource } from 'i18next'
import en from './en'
import de from './de'
import nl from './nl'

export const locales = ['en', 'nl', 'de'] as const

export type Locale = typeof locales[number]
export const defaultLocale: Locale = 'en'

export const translations: Record<Locale, Resource> = {
  en,
  de,
  nl
}

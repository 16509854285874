import { SerializedPassengerInformation } from '../types/passenger-information'
import { Departure } from '../services/departures'
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  UseQueryResult
} from '@tanstack/react-query'
import BookingService, { Booking, CreateBooking } from '../services/bookings'
import { TermsData } from '../types/booking'
import { Brand } from '../contexts/brand'
import { InsuranceOptions } from '../types/insurance'

interface CreateBookingOptions {
  departure: Departure
  passengerInformation: SerializedPassengerInformation
  terms: TermsData
  insurance: InsuranceOptions
  locale: string
  brand: Brand
  idempotencyKey?: string
  voucherCode?: string
  products?: Array<[string, number]>
}
interface CreateBookingReturn {
  createBooking: UseMutateAsyncFunction<CreateBooking, unknown, CreateBookingOptions>
  isLoading: boolean
}

async function delay (ms: number): Promise<void> {
  return await new Promise(resolve => setTimeout(resolve, ms))
}

async function waitForBookingCompletion (bookingService: BookingService, bookingId: string): Promise<Booking> {
  let booking: Booking
  let waitTime = 300
  do {
    booking = await bookingService.getBooking(bookingId)
    await delay(waitTime)
    if (waitTime < 3000) {
      waitTime = waitTime * 2
    }
  } while (booking.status === 'pending')
  return booking
}

export function useCreateBooking (): CreateBookingReturn {
  const { mutateAsync, isLoading } = useMutation(async ({ departure, brand, locale, passengerInformation, insurance, terms, voucherCode, products, idempotencyKey }: CreateBookingOptions): Promise<CreateBooking> => {
    const bookingService = new BookingService()
    const extras: Record<string, string> = {}
    const cid = localStorage.getItem('cid')
    if (cid !== null) {
      extras.cid = cid
    }
    const response = await bookingService.createBooking({
      idempotencyKey,
      departureId: departure.id,
      passengerInformation,
      insurance,
      terms,
      locale,
      brand,
      voucherCode,
      products,
      extras
    })
    if (response.status === 'pending') {
      return await waitForBookingCompletion(bookingService, response.id)
    }
    return response
  }, { onSuccess: () => localStorage.removeItem('cid') })

  return {
    createBooking: mutateAsync,
    isLoading
  }
}

export function useBooking (bookingId?: string): UseQueryResult<Booking> {
  return useQuery(['booking', bookingId ?? 'none'], async () => {
    if (bookingId !== undefined) {
      const bookingService = new BookingService()
      return await bookingService.getBooking(bookingId)
    }
    throw new Error('No booking')
  })
}

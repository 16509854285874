import { Voucher } from '../services/departures'
import { createContext, PropsWithChildren } from 'react'

interface VoucherContextType {
  voucher: undefined | null | Voucher
}

const initialState: VoucherContextType = { voucher: null }

export const VoucherContext = createContext(initialState)

export default function VoucherProvider ({ voucher, children }: PropsWithChildren<VoucherContextType>): JSX.Element {
  return <VoucherContext.Provider value={{ voucher }}>{children}</VoucherContext.Provider>
}

import { Market } from '../types/booking'
import { loadStripe, Stripe } from '@stripe/stripe-js'

const STRIPE_KEYS = {
  default: import.meta.env.VITE_STRIPE_KEY,
  north_america: import.meta.env.VITE_STRIPE_NA_KEY
} satisfies Record<Market, string>
const STRIPES = new Map<Market, Promise<Stripe | null>>()

async function loadStripePromise (market: Market): Promise<Stripe | null> {
  return await loadStripe(STRIPE_KEYS[market])
}

export const getStripe = async (market: Market): Promise<Stripe | null> => {
  let stripe = STRIPES.get(market)
  if (stripe === undefined) {
    stripe = loadStripePromise(market)
    STRIPES.set(market, stripe)
  }
  return await stripe
}

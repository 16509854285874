import { Children, cloneElement, PropsWithChildren } from 'react'
import Container from '../components/container'
import { twMerge } from 'tailwind-merge'
import classNames from 'classnames'

/**
 * A component that renders a two-column layout.
 *
 * @param {PropsWithChildren<{}>} props - The component's props.
 * @returns {JSX.Element} - The rendered two-column layout.
 */
export default function TwoColumnLayout ({ columnClassName, className, children }: PropsWithChildren<{ className?: string, columnClassName?: string }>): JSX.Element {
  const columnClass = columnClassName ?? ''

  return (
    <Container>
      <div className={twMerge(classNames('grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-10', className))}>
        {Children.map(children, (child, index) => {
        /* Add the columnClasses to the child nodes */
          if ((child != null && typeof child === 'object' && 'props' in child)) {
            const original = child.props?.className as string | undefined
            return cloneElement(child, {
              className: twMerge(original !== undefined ? `${original} ${columnClass}` : columnClass)
            })
          }
          return child
        })}
      </div>
    </Container>
  )
}

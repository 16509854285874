import { SelectableCabin } from '../hooks/cabin-selection'
import { ForwardedRef, forwardRef, useContext, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { PassengerForm } from './passenger-form'
import * as yup from 'yup'
import { PassengersContext } from '../contexts/passengers'
import {
  Passenger,
  SerializedPassenger,
  SerializedPassengerInformation
} from '../types/passenger-information'
import { AutoSaveValues } from './auto-save-values'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { ProductsContext } from '../contexts/products'
import dayjs from '../libs/dayjs'
import PassengerProductTagManager from './passenger-product-tag-manager'
import AutoScrollToError from './auto-scroll-to-error'
import { isValidState } from '../libs/states'

function makeSchema ({ numberOfPassengers, selectableCabins, t }: {
  numberOfPassengers: number
  selectableCabins: SelectableCabin[]
  t: TFunction<'translation', undefined>
}): yup.Schema {
  const mainSchema = yup.object({
    phoneNumber: yup.string().required(t('field:phone_number_required', 'Phone number is required')),
    emailAddress: yup.string().email(t('field:email_address_email', 'Email address has to be an email address')).required(t('field:email_address_required', 'Email address is required')),
    streetAddress: yup.string().required(t('field:street_address_required', 'Address is required')),
    zipCode: yup.string().required(t('field:zip_code_required', 'Zip code is required')),
    city: yup.string().required(t('field:city_required', 'City is required')),
    country: yup.string().required(t('field:country_required', 'Country is required')),
    state: yup.string().test('required', t('field:state_required', 'State/Province is required'), (value, testContext) => {
      const country = testContext.parent.country
      if (country === 'US' || country === 'CA') {
        return value !== undefined && value !== ''
      }
      return true
    }).test('is-invalid', t('field:state_invalid', 'State/Province is invalid'), (value, testContext) => {
      const country = testContext.parent.country
      if (country === 'US' || country === 'CA') {
        return value !== undefined && isValidState(value, country)
      }
      return value === '' || value === undefined
    }),
    customerRemarks: yup.string(),
    groupName: yup.string()
  })
  const passengerSchema = yup.object({
    firstName: yup.string().required(t('field:first_name_required', 'First name is required')),
    lastName: yup.string().required(t('field:last_name_required', 'Last name is required')),
    dateOfBirth: yup.date().required(t('field:date_of_birth_required', 'Date of birth is required'))
      .max(dayjs().format('YYYY-MM-DD'), t('field:date_of_birth_in_past', 'Date of birth should be in the past'))
      .min('1900-01-01', t('field:date_of_birth_too_far_in_the_past', 'Date of birth is too far in the past'))
      .test('is-invalid', t('field:date_of_birth_invalid', 'Date of birth is not a valid date'), (value, testContext) => {
        return testContext.originalValue === dayjs(value).format('YYYY-MM-DD')
      }),
    gender: yup.string().oneOf(['', 'Male', 'Female'], t('field:gender_choice', 'Gender is required to be male or female')),
    bodyHeight: yup.number().required(t('field:body_height_required', 'Body height is required')).positive(t('field:body_height_positive', 'Body height should be a positive number')).integer(t('field:body_height_integer', 'Body height should be a whole number of centimers')).lessThan(300, t('field:body_height_too_high', 'Body height should be less than 300 centimeters')).min(100, t('field:body_height_too_low', 'Body height should be at least 100 centimeters')),
    bike: yup.string().required(t('field:bicycle_required', 'Bicycle choice is required')),
    diet: yup.string(),
    other: yup.array(yup.string()),
    cabin: yup.string().required(t('field:cabin_required', 'Cabin assignment is required')).test('is-available', t('field:cabin_full', 'Cabin has too many occupants'), (value, testContext) => {
      const [passengerForm, form] = testContext.from ?? []
      if (form === undefined || passengerForm === undefined) {
        return false
      }
      const cabin = selectableCabins.find(cabin => cabin.key === value)
      if (cabin === undefined) {
        return true
      }
      const { passengers } = form.value

      if (import.meta.env.VITE_CABIN_ERROR !== 'all') {
        // Option #1
        const index = passengers.indexOf(passengerForm.value)
        const previousPassengers = passengers.slice(0, index)
        const sameCabinPassengers = previousPassengers.filter((passenger: Passenger) => passenger.cabin === value)
        return sameCabinPassengers.length < cabin.spaces
      } else {
        // Option #2
        const sameCabinPassengers = passengers.filter((passenger: Passenger) => passenger.cabin === value)
        return sameCabinPassengers.length <= cabin.spaces
      }
    })
  })
  return yup.object({
    main: mainSchema.required(),
    passengers: yup.array(passengerSchema).length(numberOfPassengers).required()
  })
}

export default forwardRef(function PassengerForms ({
  selectableCabins,
  numberOfPassengers,
  formId,
  onSubmit
}: {
  selectableCabins: SelectableCabin[]
  numberOfPassengers: number
  formId: string
  onSubmit: (values: any) => Promise<void> | void
}, ref: ForwardedRef<HTMLFormElement>): JSX.Element {
  const { unvalidated, setUnvalidated } = useContext(PassengersContext)
  const { products } = useContext(ProductsContext)

  const initialValues = useMemo(() => {
    const bikeProductIds = products.bikes.map(bike => bike.id)
    const dietProductIds = products.diets.map(diet => diet.id)
    const otherProductIds = products.other.map(other => other.id)
    const mandatoryProducts = products.other.filter(other => other.mandatory)
    const mandatoryProductIds = mandatoryProducts.map(other => other.id)
    const cabinKeys = selectableCabins.map(cabin => cabin.key)
    const cleanPassenger = (passenger: SerializedPassenger): SerializedPassenger => {
      const { bike, cabin, diet, other, ...rest } = passenger
      return {
        cabin: cabinKeys.length === 1 ? cabinKeys[0] : cabinKeys.includes(cabin) ? cabin : '',
        bike: bikeProductIds.includes(bike) ? bike : '',
        diet: dietProductIds.includes(diet) ? diet : '',
        other: other.filter(product => otherProductIds.includes(product) && !mandatoryProductIds.includes(product)).concat(mandatoryProductIds),
        ...rest
      }
    }
    const { main: savedMain = {}, passengers: savedPassengers = [] } = unvalidated ?? {}
    const main = {
      phoneNumber: '',
      emailAddress: '',
      streetAddress: '',
      zipCode: '',
      city: '',
      country: '',
      state: '',
      customerRemarks: '',
      groupName: '',
      ...savedMain
    }
    return {
      main,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      passengers: Array(numberOfPassengers).fill(undefined).map((_, index) => cleanPassenger({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        gender: '',
        bodyHeight: '',
        cabin: '',
        diet: '',
        bike: '',
        other: [],
        ...((savedPassengers[index] ?? {}) as Partial<SerializedPassenger>)
      } as SerializedPassenger))
    }
  }, [selectableCabins, numberOfPassengers, unvalidated, products])

  const { t } = useTranslation()

  const schema = useMemo(() => makeSchema({
    selectableCabins,
    numberOfPassengers,
    t
  }), [numberOfPassengers, selectableCabins, t])

  if (numberOfPassengers === 0) {
    return (<></>)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values, isSubmitting }) => (
        <>
          <AutoScrollToError />
          <AutoSaveValues
            values={values as SerializedPassengerInformation}
            save={(values: SerializedPassengerInformation) => setUnvalidated(values)}
          />
          <PassengerProductTagManager />
          <Form id={formId} ref={ref}>
            {initialValues.passengers.map((_, index) => (
              <PassengerForm
                key={index} index={index} errors={errors}
                selectableCabins={selectableCabins} touched={touched}
                main={index === 0}
              />))}
          </Form>
        </>
      )}
    </Formik>
  )
})

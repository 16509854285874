import BookingProgress from '../components/booking-progress'
import TwoColumnLayout from '../layouts/columns'
import { useSelectableCabins } from '../hooks/cabin-selection'
import { createRef, useCallback, useContext, useId, useMemo } from 'react'
import PassengerForms from '../components/passenger-forms'
import { PassengersContext } from '../contexts/passengers'
import { SerializedPassengerInformation } from '../types/passenger-information'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DepartureContext } from '../contexts/departure'
import VoucherEntry from '../components/voucher-entry'
import Sidebar from '../components/sidebar'
import { useBrandBasePath } from '../hooks/brand'

export default function PassengerInformationPage (): JSX.Element {
  const formId = useId()
  const formRef = createRef<HTMLFormElement>()
  const selectableCabins = useSelectableCabins()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const numberOfPassengers = useMemo(() => selectableCabins.map(cabin => cabin.spaces).reduce((a, b) => a + b, 0), [selectableCabins])

  const { departure } = useContext(DepartureContext)
  const { unvalidated, setValidated } = useContext(PassengersContext)
  const basePath = useBrandBasePath()

  const handleSubmit = useCallback((values: SerializedPassengerInformation) => {
    setValidated(values)
    navigate(`${basePath}/departures/${departure.id}/confirmation?${searchParams.toString()}`)
  }, [basePath, departure.id, navigate, searchParams, setValidated])

  const handleBack = useCallback(() => {
    navigate(`${basePath}/departures/${departure.id}/cabins`)
  }, [basePath, departure.id, navigate])

  const handleNext = useCallback(() => {
    formRef.current?.requestSubmit()
  }, [formRef])

  // numberOfPassengers is used as the key for the forms component to reset the Formik component inside
  // Without this trick, the validations won't kick in properly until the user has touched all passengers

  return (
    <>
      <BookingProgress currentStep={4} />
      <TwoColumnLayout>
        <section>
          <PassengerForms onSubmit={handleSubmit} ref={formRef} formId={formId} selectableCabins={selectableCabins} numberOfPassengers={numberOfPassengers} key={numberOfPassengers} />
        </section>
        <Sidebar
          bookingSummaryProps={{ passengerInformation: unvalidated }}
          onBack={handleBack}
          onNext={handleNext}
        >
          <VoucherEntry />
        </Sidebar>
      </TwoColumnLayout>
    </>
  )
}

import BBTLogo from '../assets/bbt-logo.svg'
import InselhuepfenLogo from '../assets/inselhuepfen-logo.svg'
import IslandHoppingLogo from '../assets/islandhopping-logo.svg'
import { NavigationContext } from '../contexts/navigation-context'
import { useContext } from 'react'
import { ChevronLeft } from 'react-feather'
import { useTranslation } from 'react-i18next'

export default function Header (): JSX.Element {
  const { i18n } = useTranslation()

  const { backAction } = useContext(NavigationContext)
  return (
    <header className='frame:hidden flex justify-between items-center px-4 shadow-[0px_1px_4px_rgba(0,0,0,0.12)] relative overflow-hidden'>
      <span>
        {backAction !== undefined && (
          <button onClick={backAction} type='button' className='text-brand-primary leading-10 md:hidden'>
            <ChevronLeft size={40} />
          </button>
        )}
      </span>
      <span>
        <span className='absolute left-0 right-1/2 top-0 bottom-0 bg-ih-left bg-right bg-[length:1027px_2051px] hidden brand-inselhuepfen:block' />
        <span className='absolute left-1/2 right-0 top-0 bottom-0 bg-ih-right bg-left bg-[length:1200px_2284px] hidden brand-inselhuepfen:block' />
        <img alt='Boat Bike Tours Logo' src={BBTLogo} className='w-[110px] m-2.5 brand-inselhuepfen:hidden' />
        <img alt={i18n.resolvedLanguage === 'de' ? 'Inselhüpfen Logo' : 'Islandhopping Logo'} src={i18n.resolvedLanguage === 'de' ? InselhuepfenLogo : IslandHoppingLogo} className='hidden brand-inselhuepfen:inline-block w-[160px] m-2.5' />
      </span>
      <span>
        {import.meta.env.DEV && (
          <span className='opacity-[30%] hover:opacity-100 absolute right-2 top-4 flex gap-4'>
            <select
              className='border-brand-primary rounded-lg text-brand-primary accent-brand-primary font-medium'
              onChange={(e) => {
                void i18n.changeLanguage(e.target.value)
              }}
              value={i18n.resolvedLanguage}
            >
              {['en', 'nl', 'de'].map(lang => (<option key={lang} value={lang}>{lang.toUpperCase()}</option>))}
            </select>
          </span>
        )}
      </span>
    </header>
  )
}
